import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import AmountController from "../../../form-fields/AmountController";
import PercentageController from "../../../form-fields/PercentageController";
import SelectController from "../../../form-fields/SelectController";

interface PreTaxAccountProps {
  client: ClientType;
  contributionMethodTSP: string;
}

const PreTaxAccount: React.FC<PreTaxAccountProps> = ({
  client,
  contributionMethodTSP,
}) => {
  return (
    <div>
      <AmountController
        name="pretax_currentBalance"
        label="Current Balance"
        placeholder="$0"
        defaultValue={client?.pretax_currentBalance || 0.0}
        infoMessage="Enter the current balance for the Pre-Tax account."
      />
      {contributionMethodTSP === "percent_of_pay" && (
        <PercentageController
          name="pretax_contributionPerPaycheck"
          label="Contribution Per Paycheck"
          placeholder="0.0000"
          defaultValue={client?.pretax_contributionPerPaycheck || 0.0}
          infoMessage="Enter the contribution per paycheck for the Pre-Tax account."
        />
      )}
      {contributionMethodTSP === "dollars_per_deposit" && (
        <AmountController
          name="pretax_contributionPerPaycheck"
          label="Contribution Per Paycheck"
          placeholder="0.0000"
          defaultValue={client?.pretax_contributionPerPaycheck || 0.0}
          infoMessage="Enter the contribution per paycheck for the Pre-Tax account."
        />
      )}
      <PercentageController
        name="pretax_preRetireRateOfReturn"
        label="Pre-Retire Rate of Return"
        placeholder="0.0000"
        defaultValue={client?.pretax_preRetireRateOfReturn || 0.0}
        infoMessage="Enter the pre-retire rate of return for the Pre-Tax account."
      />
      <SelectController
        name="pretax_futureContributionsMethod"
        label="Future Contributions Method"
        options={[
          { value: "none", label: "None" },
          { value: "percent_of_pay", label: "Percent of Pay" },
          { value: "dollar_per_deposit", label: "Dollar per Deposit" },
          { value: "maximum", label: "Maximum" },
        ]}
        defaultValue={client?.pretax_futureContributionsMethod || "none"}
        infoMessage="Select the method for future contributions to the Pre-Tax account."
      />
      <SelectController
        name="pretax_annualIncreaseMethod"
        label="Annual Increase Method"
        options={[
          { value: "no_increase", label: "No Increase" },
          { value: "increase_in_value", label: "Increase in Value" },
          { value: "percentage_increase", label: "Percentage Increase" },
        ]}
        defaultValue={client?.pretax_annualIncreaseMethod || "no_increase"}
        infoMessage="Select the method for annual increase of the Pre-Tax account."
      />
      <SelectController
        name="pretax_distributionMethod"
        label="Distribution Method"
        options={[
          { value: "use_funds_as_needed", label: "Use Funds as Needed" },
          { value: "annuitize", label: "Annuitize" },
          { value: "retain_principal", label: "Retain Principal" },
          { value: "amortize_no_inflation", label: "Amortize (No Inflation)" },
          {
            value: "amortize_with_inflation",
            label: "Amortize (With Inflation)",
          },
          { value: "percent_of_account", label: "Percent of Account" },
          { value: "rmd_only", label: "RMD Only" },
          { value: "tsp_annuity", label: "TSP Annuity" },
          {
            value: "dollar_value_no_inflation",
            label: "Dollar Value (No Inflation)",
          },
          {
            value: "dollar_value_with_inflation",
            label: "Dollar Value (With Inflation)",
          },
        ]}
        defaultValue={
          client?.pretax_distributionMethod || "use_funds_as_needed"
        }
        infoMessage="Select the distribution method for the Pre-Tax account."
      />
      <PercentageController
        name="pretax_rateOfReturn"
        label="Rate of Return"
        placeholder="0.0000"
        defaultValue={client?.pretax_rateOfReturn || 0.0}
        infoMessage="Enter the rate of return for the Pre-Tax account."
      />
      <SelectController
        name="pretax_distributionStartMethod"
        label="Distribution Start Method"
        options={[
          { value: "at_retirement", label: "At Retirement" },
          { value: "specific_date", label: "Specific Date" },
          { value: "owners_age", label: "Owner's Age" },
          { value: "years_after_retirement", label: "Years After Retirement" },
          { value: "59_and_a_half", label: "59 1/2 Years" },
        ]}
        defaultValue={client?.pretax_distributionStartMethod || "at_retirement"}
        infoMessage="Select the distribution start method for the Pre-Tax account."
      />
    </div>
  );
};

export default PreTaxAccount;
