import * as React from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import XanoAPI from "../../../lib/XanoAPI";
import { Button } from "../../ui/button";
import { useToast } from "../../ui/use-toast";
import { Icons } from "../../../utils/icons";
import moment from "moment";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../ui/card";
import { useAuth } from "../../../lib/AuthContext";
// custom components
import ItemsComponent from "../../form-fields/ItemsComponent";
import CurrentIncome from "../components/current-income";
import FederalTSP from "../components/federal-tsp/federal-tsp";
import FederalSupplement from "../components/federal-supplement";
import FEGLIClient from "../components/fegli-client";
import SocialSecurity from "../components/social-security";
import FederalRetirementPlan from "../components/federal-retirement-plan";
import FederalSavingsPlan from "../components/federal-savings-plan";
import RetirementIncomeNeeded from "../components/retirement-income-needed";
import UnusedSickLeave from "../components/unused-sick-leave";
import FederalInformationTable from "../components/federal-information-table";

const formSchema = z.object({
  id: z.union([z.number(), z.string()]).optional(), // id can be number or string
  user_id: z.number(), // user_id is required
  grossIncome: z.number().optional(),
  payPeriodsAnnual: z.number().optional(),
  expectedAnnualRaise: z.number().optional(),

  startPayments: z
    .enum([
      "retirement_or_62",
      "age_no_penalty_occurs",
      "do_not_calculate",
      "specified_age",
    ])
    .optional(),
  calculationMethod: z
    .enum(["calculate", "value_at_retirement", "todays_value"])
    .optional(),
  yearsIncomeSsi: z.number().optional(),
  maximumRetirementCola: z.number().optional(),
  retirementPlan: z.enum(["fers", "csrs", "csrs_offset"]).optional(),
  fersPlan: z.string().optional(),
  serviceComputationDate: z.string().nullable().optional(),
  startRetirementDistAt: z
    .enum(["no_penalty_age", "specific_age", "earliest_possible"])
    .optional(),
  ageToStartDistributionsAt: z.number().optional(),
  determineSocialSecurityMethod: z
    .enum(["calculate_value", "enter_age_62_value"])
    .optional(),
  monthlyIncomeAt62: z.number().optional(),
  supplementHasAnnualCola: z.boolean().optional(),
  includeAnnualLeaveBenefit: z.boolean().optional(),
  includeMilitaryService: z.boolean().optional(),

  contributionMethod: z
    .enum(["percent_of_pay", "dollar_amount", "maximum"])
    .optional(),
  pretaxCurrentBalance: z.number().optional(),
  contributionPerPay: z.number().optional(),
  preretireRateOfReturn: z.number().optional(),

  distributionMethod: z.enum(["use_funds_as_needed"]).optional(),
  distributionStartMethod: z.enum(["at_retirement", "at_age"]).optional(),
  distributionStartMethodAge: z.number().optional(),
  incomeModel: z.enum(["percent_of_income_at_retirement"]).optional(),

  neededAsPercentage: z.number().optional(),
  annualizedColaAdjustment: z.number().optional(),
  deductions: z.string().optional(),
  additionalIncome: z.string().optional(),
  allocationMethod: z
    .enum([
      "l_2065",
      "custom_gfcsi",
      "mixed",
      "l_2025",
      "l_2030",
      "l_2035",
      "l_2040",
      "l_2045",
      "l_2050",
      "l_2055",
      "l_2060",
    ])
    .optional(),
  includeRothAccount: z.boolean().optional(),
  allocations: z
    .array(
      z.object({
        fund: z.string().optional(),
        rateOfReturn: z.number().optional(),
        percentAllocated: z.number().optional(),
        currentBalance: z.number().optional(),
      })
    )
    .optional(),
  life_cycle_funds_allocations: z.string().optional(),
  // Fields from Federal TSP
  pretax_currentBalance: z.number().optional(),
  pretax_contributionPerPaycheck: z.number().optional(),
  pretax_preRetireRateOfReturn: z.number().optional(),
  pretax_futureContributionsMethod: z
    .enum(["none", "percent_of_pay", "dollars_per_deposit", "maximum"])
    .optional(),
  pretax_annualIncreaseMethod: z
    .enum(["no_increase", "increase_in_value", "percentage_increase"])
    .optional(),
  pretax_distributionMethod: z
    .enum([
      "use_funds_as_needed",
      "annuitize",
      "retain_principal",
      "amortize_no_inflation",
      "amortize_with_inflation",
      "percent_of_account",
      "rmd_only",
      "tsp_annuity",
      "dollar_value_no_inflation",
      "dollar_value_with_inflation",
    ])
    .optional(),
  pretax_rateOfReturn: z.number().optional(),
  pretax_distributionStartMethod: z
    .enum([
      "at_retirement",
      "specific_date",
      "owners_age",
      "years_after_retirement",
      "59_and_a_half",
    ])
    .optional(),

  roth_currentBalance: z.number().optional(),
  roth_contributionPerPaycheck: z.number().optional(),
  roth_preRetireRateOfReturn: z.number().optional(),
  roth_futureContributionsMethod: z
    .enum(["none", "percent_of_pay", "dollars_per_deposit", "maximum"])
    .optional(),
  roth_annualIncreaseMethod: z
    .enum(["no_increase", "increase_in_value", "percentage_increase"])
    .optional(),
  roth_distributionMethod: z
    .enum([
      "use_funds_as_needed",
      "annuitize",
      "retain_principal",
      "amortize_no_inflation",
      "amortize_with_inflation",
      "percent_of_account",
      "rmd_only",
      "tsp_annuity",
      "dollar_value_no_inflation",
      "dollar_value_with_inflation",
    ])
    .optional(),
  roth_rateOfReturn: z.number().optional(),
  roth_distributionStartMethod: z
    .enum([
      "at_retirement",
      "specific_date",
      "owners_age",
      "years_after_retirement",
      "59_and_a_half",
    ])
    .optional(),

  createdAt: z.string().optional(), // Timestamp as string or undefined
  federal_tsp_allocations: z.string().optional(),
  unused_sick_leave: z.string().optional(),
  // FEGLI fields
  fegli_basicCoverage: z.boolean().optional(),
  fegli_postalEmployee: z.boolean().optional(),
  fegli_reductionAtAge65: z.string().optional(),
  fegli_optionA: z.boolean().optional(),
  fegli_optionB: z.boolean().optional(),
  fegli_optionBMultiplier: z.string().optional(),
  fegli_optionBAge65Reduction: z.string().optional(),
  fegli_optionC: z.boolean().optional(),
  fegli_optionCMultiplier: z.string().optional(),
  fegli_optionCIncludeSpouse: z.boolean().optional(),
  fegli_optionCAge65Reduction: z.string().optional(),
});

type FormData = z.infer<typeof formSchema>;

export default function FinancialInformation({
  client,
  onUpdate,
  handleTabChange,
  setFormsDirty,
  fetchClientData,
  resetFormsDirtyState,
  formsDirty,
}: {
  client: ClientType;
  onUpdate: Function;
  handleTabChange: Function;
  setFormsDirty: Function;
  fetchClientData: Function;
  resetFormsDirtyState: Function;
  formsDirty: any;
}) {
  React.useEffect(() => {
    onUpdate();
  }, []);
  const { toast } = useToast();
  const auth = useAuth();

  const [yearsIncomeSsiDiff, setYearsIncomeSsiDiff] = React.useState(0);
  const api = XanoAPI.getInstance();
  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      user_id: client?.user_id || api.getUserId(),
      grossIncome:
        client?.grossIncome ??
        auth?.currentUser?.user_preferences?.annual_raise, // Corrected based on number type
      payPeriodsAnnual: client?.payPeriodsAnnual ?? 0,
      expectedAnnualRaise:
        client?.expectedAnnualRaise ||
        auth.currentUser?.user_preferences?.annual_raise,

      startPayments: client?.startPayments || "retirement_or_62", // Adjusted for enums to have an empty string as default
      calculationMethod: client?.calculationMethod ?? "calculate",
      yearsIncomeSsi: client?.yearsIncomeSsi ?? 0,
      maximumRetirementCola: client?.maximumRetirementCola ?? 0, // Assuming number, adjusted to 0

      retirementPlan: client?.retirementPlan ?? "fers",
      serviceComputationDate: client?.serviceComputationDate ?? "",
      startRetirementDistAt: client?.startRetirementDistAt ?? "no_penalty_age",
      includeAnnualLeaveBenefit: client?.includeAnnualLeaveBenefit ?? false,
      includeMilitaryService: client?.includeMilitaryService ?? false,
      ageToStartDistributionsAt: client?.ageToStartDistributionsAt ?? 0,
      // federal supplement
      determineSocialSecurityMethod:
        client?.determineSocialSecurityMethod ?? "calculate_value",
      monthlyIncomeAt62: client?.monthlyIncomeAt62 ?? 0.0,
      supplementHasAnnualCola: client?.supplementHasAnnualCola ?? false,

      contributionMethod: client?.contributionMethod ?? "percent_of_pay",
      pretaxCurrentBalance: client?.pretaxCurrentBalance ?? 0, // Corrected based on number type
      contributionPerPay: client?.contributionPerPay ?? 0, // Corrected based on number type
      preretireRateOfReturn: client?.preretireRateOfReturn ?? 0, // Corrected based on number type
      distributionMethod: client?.distributionMethod ?? "use_funds_as_needed",
      distributionStartMethod:
        client?.distributionStartMethod ?? "at_retirement",
      incomeModel: client?.incomeModel ?? "percent_of_income_at_retirement",
      neededAsPercentage: client?.neededAsPercentage ?? 0, // Corrected based on number type
      annualizedColaAdjustment: client?.annualizedColaAdjustment ?? 0, // Corrected based on number type
      deductions: client?.deductions,
      additionalIncome: client?.additionalIncome,
      allocationMethod: client?.allocationMethod || "l_2065",
      includeRothAccount: client?.includeRothAccount || false,
      allocations: client?.allocations,
      life_cycle_funds_allocations: client?.life_cycle_funds_allocations || "",
      unused_sick_leave:
        client?.unused_sick_leave ||
        JSON.stringify({
          averageHoursSavedBiweekly: 0,
          numberOfTwoWeekPeriods: 0,
          currentAccumulatedSickLeave: 0,
          hoursToBeCredited: 0,
          estimatedSickLeaveHoursAtRetirement: 0,
          hoursPerYear: 0,
          additionalYearsOfServiceCredited: "None",
        }),
      // pretax
      pretax_currentBalance: client?.pretax_currentBalance || 0.0,
      pretax_contributionPerPaycheck:
        client?.pretax_contributionPerPaycheck || 0.0,
      pretax_preRetireRateOfReturn: client?.pretax_preRetireRateOfReturn || 0.0,
      pretax_futureContributionsMethod:
        client?.pretax_futureContributionsMethod || "none",
      pretax_annualIncreaseMethod:
        client?.pretax_annualIncreaseMethod || "no_increase",
      pretax_distributionMethod:
        client?.pretax_distributionMethod || "use_funds_as_needed",
      pretax_rateOfReturn: client?.pretax_rateOfReturn || 0.0,
      pretax_distributionStartMethod:
        client?.pretax_distributionStartMethod || "at_retirement",

      roth_currentBalance: client?.roth_currentBalance || 0.0,
      roth_contributionPerPaycheck: client?.roth_contributionPerPaycheck || 0.0,
      roth_preRetireRateOfReturn: client?.roth_preRetireRateOfReturn || 0.0,
      roth_futureContributionsMethod:
        client?.roth_futureContributionsMethod || "none",
      roth_annualIncreaseMethod:
        client?.roth_annualIncreaseMethod || "no_increase",
      roth_distributionMethod:
        client?.roth_distributionMethod || "use_funds_as_needed",
      roth_rateOfReturn: client?.roth_rateOfReturn || 0.0,
      roth_distributionStartMethod:
        client?.roth_distributionStartMethod || "at_retirement",
      federal_tsp_allocations: client?.federal_tsp_allocations,
      // FEGLI fields
      fegli_basicCoverage: client?.fegli_basicCoverage ?? false,
      fegli_postalEmployee: client?.fegli_postalEmployee ?? false,
      fegli_reductionAtAge65: client?.fegli_reductionAtAge65 ?? "no_reduction",
      fegli_optionA: client?.fegli_optionA ?? false,
      fegli_optionB: client?.fegli_optionB ?? false,
      fegli_optionBMultiplier: client?.fegli_optionBMultiplier ?? "1",
      fegli_optionBAge65Reduction:
        client?.fegli_optionBAge65Reduction ?? "none",
      fegli_optionC: client?.fegli_optionC ?? false,
      fegli_optionCMultiplier: client?.fegli_optionCMultiplier ?? "1",
      fegli_optionCIncludeSpouse: client?.fegli_optionCIncludeSpouse ?? false,
      fegli_optionCAge65Reduction:
        client?.fegli_optionCAge65Reduction ?? "none",
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, touchedFields, dirtyFields, isValid },
    watch,
    setValue,
    getValues,
  } = form;

  const watchAtAge = watch("startRetirementDistAt");
  const watchContributionMethod = watch("contributionMethod");
  const watchDeductions = watch("deductions");
  const watchAdditionalIncome = watch("additionalIncome");
  const watchUnusedSickLeave = watch("unused_sick_leave");
  const watchRetirementPlan = watch("retirementPlan");
  const watchDistributionStartMethod = watch("distributionStartMethod");
  const watchServiceComputationDate = watch("serviceComputationDate");
  const watchYearsIncomeSsi = watch("yearsIncomeSsi");

  React.useEffect(() => {
    if (watchServiceComputationDate) {
      const value = moment().diff(moment(watchServiceComputationDate), "years");
      setYearsIncomeSsiDiff(value);
    }
  }, [watchServiceComputationDate, yearsIncomeSsiDiff]);

  const onSubmit = async (data: FormData) => {
    if (!client || !client.id) {
      console.error("Client ID is missing.");
      return;
    }

    const processedData = {
      ...client,
      ...data,
    };

    try {
      const updateFinancialInformation = await api.updateClient(
        client.id,
        processedData
      );

      console.log(
        "Client financial information updated",
        updateFinancialInformation
      );
      // onUpdate();
      fetchClientData(client.id, 2000);
      resetFormsDirtyState();

      toast({
        title: "Financial Information Updated!",
        description: "Your client's financial information has been updated.",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onSave = (items: string, field: any): void => {
    if (items) setValue(field, JSON.stringify({ values: items }));
  };

  return (
    <div className="py-6 mb-8">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit, (errors) => {
            console.log("Submit was attempted", errors);
          })}
        >
          <CurrentIncome client={client} />
          <Card className="mb-8" id="additional-income">
            <CardHeader>
              <h4 className="text-lg font-semibold text-gray-700">
                Additional Income
              </h4>
            </CardHeader>
            <CardContent>
              <ItemsComponent
                json={watchAdditionalIncome}
                field="additionalIncome"
                onSave={onSave}
              />
            </CardContent>
          </Card>

          <Card className="mb-8" id="deductions">
            <CardHeader>
              <h4 className="text-lg font-semibold text-gray-700">
                Deductions
              </h4>
            </CardHeader>
            <CardContent>
              <ItemsComponent
                json={watchDeductions}
                field="deductions"
                onSave={onSave}
              />
            </CardContent>
          </Card>
          <FederalRetirementPlan
            client={client}
            watchAtAge={watchAtAge}
            watchRetirementPlan={watchRetirementPlan}
          />
          <SocialSecurity
            client={client}
            yearsIncomeSsiDiff={yearsIncomeSsiDiff}
          />

          <FederalInformationTable client={client} />
          <FederalSupplement client={client} />
          <RetirementIncomeNeeded client={client} />
          <FEGLIClient client={client} />
          <UnusedSickLeave formData={watchUnusedSickLeave} />
          <FederalTSP client={client} />

          <div className="fixed bottom-0 right-0 m-3 z-50">
            <Button
              type="submit"
              className="mt-5 p-3 bg-accent"
              disabled={!isValid}
            >
              <Icons.save className="mr-2" />
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
