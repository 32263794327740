import React, { useState, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";

interface PhoneNumberControllerProps {
  name: string;
  label: string;
  placeholder: string;
  defaultValue?: string;
  validation?: {
    required?: string;
    min?: { value: number; message: string };
    max?: { value: number; message: string };
    pattern?: { value: RegExp; message: string };
  };
  infoMessage?: string;
  onChange?: (value: any) => void;
}

const PhoneNumberController: React.FC<PhoneNumberControllerProps> = ({
  name,
  label,
  placeholder,
  defaultValue = "",
  validation,
  infoMessage,
  onChange,
}) => {
  const { control, setValue } = useFormContext();
  const [internalValue, setInternalValue] = useState<string>(defaultValue);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setInternalValue(formatPhoneNumber(defaultValue));
      setValue(name, defaultValue); // Set initial form value
    }
  }, [defaultValue, setValue, name]);

  const formatPhoneNumber = (value: string): string => {
    const phoneNumber = value.replace(/[^\d]/g, ""); // Remove all non-numeric characters
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const formattedValue = formatPhoneNumber(rawValue);
    setInternalValue(formattedValue);
  };

  const handleInputBlur = () => {
    if (onChange) {
      onChange(internalValue);
    }
    setValue(name, internalValue); // Update form value
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      render={({
        field: { onChange: fieldOnChange, value, ...fieldProps },
        fieldState: { error },
      }) => (
        <FormItem className="mb-6">
          <FormLabel htmlFor={name}>{label}</FormLabel>
          <FormControl>
            <div className="flex">
              <span className="flex justify-center items-center w-10 h-10 bg-gray-300 text-white rounded mr-1">
                P
              </span>
              <Input
                {...fieldProps}
                id={name}
                type="text"
                placeholder={placeholder}
                value={internalValue}
                onChange={(e) => {
                  handleInputChange(e);
                  fieldOnChange(e.target.value);
                }}
                onBlur={handleInputBlur}
              />
            </div>
          </FormControl>
          <FormMessage
            className={
              error ? "text-red-500" : "text-gray-500 text-xs font-normal"
            }
          >
            {error ? error.message : infoMessage}
          </FormMessage>
        </FormItem>
      )}
    />
  );
};

export default PhoneNumberController;
