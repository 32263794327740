import React, { createContext, useState, useEffect, useContext } from "react";
import XanoAPI from "../lib/XanoAPI";

interface AuthContextProviderProps {
  children: React.ReactNode;
}

const initialContextState: AuthContextState = {
  isLoading: true,
  isAuthenticated: false,
  currentUser: null,
  token: "",
  clients: [],
  roles: [],
};

export const AuthContext = createContext<AuthContextState>(initialContextState);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<AuthContextProviderProps> = ({
  children,
}) => {
  const [state, setState] = useState<AuthContextState>(initialContextState);
  const api = XanoAPI.getInstance();
  useEffect(() => {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    const checkAuth = async () => {
      try {
        const token = api.getToken();
        const isAuthenticated = api.isAuthenticated();
        const currentUser = isAuthenticated ? await api.getCurrentUser() : null;
        const clients = isAuthenticated ? await api.getAllClients() : [];

        const roles =
          isAuthenticated && currentUser?.agency_id
            ? await api.getRoles(currentUser?.agency_id)
            : [];

        if (currentUser?.id && currentUser.id !== api.getUserId()) {
          api.setUserId(currentUser.id);
        }

        const userState = {
          token,
          isLoading: false,
          isAuthenticated,
          currentUser,
          clients,
          roles,
        };

        setState(userState);
      } catch (error) {
        setState({
          token: "",
          isLoading: true,
          isAuthenticated: false,
          currentUser: null,
          clients: [],
          roles: [],
        });
        console.error(error);
      }
    };

    checkAuth();
  }, [api]);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
