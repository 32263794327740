import React, { useEffect, useReducer } from "react";
import { useFormContext } from "react-hook-form";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import TextController from "../../form-fields/TextController";
import IntegerController from "../../form-fields/IntegerController";

interface UnusedSickLeaveProps {
  formData?: string;
}

interface State {
  averageHoursSavedBiweekly: number;
  numberOfTwoWeekPeriods: number;
  currentAccumulatedSickLeave: number;
  hoursToBeCredited: number;
  estimatedSickLeaveHoursAtRetirement: number;
  hoursPerYear: number;
  additionalYearsOfServiceCredited: string;
}

const initialState: State = {
  averageHoursSavedBiweekly: 0,
  numberOfTwoWeekPeriods: 0,
  currentAccumulatedSickLeave: 0,
  hoursToBeCredited: 0,
  estimatedSickLeaveHoursAtRetirement: 0,
  hoursPerYear: 0,
  additionalYearsOfServiceCredited: "None",
};

type Action =
  | { type: "SET_FIELD"; fieldName: keyof State; value: number }
  | { type: "CALCULATE" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.fieldName]: action.value,
      };
    case "CALCULATE":
      const hoursToBeCredited =
        state.averageHoursSavedBiweekly * state.numberOfTwoWeekPeriods;
      const estimatedSickLeaveHoursAtRetirement =
        hoursToBeCredited + state.currentAccumulatedSickLeave;
      const years = Math.floor(estimatedSickLeaveHoursAtRetirement / 2087);
      const days = Math.floor(
        ((estimatedSickLeaveHoursAtRetirement % 2087) / 2087) * 365
      );
      const additionalYearsOfServiceCredited =
        years > 0 || days > 0 ? `${years}y ${days}d` : "None";
      return {
        ...state,
        hoursToBeCredited,
        estimatedSickLeaveHoursAtRetirement,
        hoursPerYear: 2087,
        additionalYearsOfServiceCredited,
      };
    default:
      return state;
  }
};

const parseFormData = (formData: string | undefined) => {
  if (!formData) return initialState;
  try {
    const parsedData = JSON.parse(formData);
    return { ...initialState, ...parsedData };
  } catch (error) {
    return initialState;
  }
};

const UnusedSickLeave: React.FC<UnusedSickLeaveProps> = ({ formData }) => {
  const initialParsedState = parseFormData(formData);
  const [state, dispatch] = useReducer(reducer, initialParsedState);

  const { setValue } = useFormContext();

  useEffect(() => {
    dispatch({ type: "CALCULATE" });
  }, [
    state.averageHoursSavedBiweekly,
    state.numberOfTwoWeekPeriods,
    state.currentAccumulatedSickLeave,
  ]);

  useEffect(() => {
    setValue("unused_sick_leave", JSON.stringify(state));
  }, [state, setValue]);

  const handleFieldChange = (fieldName: keyof State, value: number) => {
    dispatch({ type: "SET_FIELD", fieldName, value });
  };

  return (
    <Card className="mb-8" id="unused-sick-leave">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">
          Unused Sick Leave at Retirement
        </h4>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <div className="flex-1">
              <IntegerController
                name="averageHoursSavedBiweekly"
                label="Average hours saved biweekly until retirement"
                placeholder="0"
                defaultValue={state.averageHoursSavedBiweekly}
                onChange={(value: number) =>
                  handleFieldChange("averageHoursSavedBiweekly", value)
                }
              />
            </div>
            <span className="text-xl font-bold">&times;</span>
            <div className="flex-1">
              <IntegerController
                name="numberOfTwoWeekPeriods"
                label="Number of two-week periods until retirement"
                placeholder="0"
                defaultValue={state.numberOfTwoWeekPeriods}
                onChange={(value: number) =>
                  handleFieldChange("numberOfTwoWeekPeriods", value)
                }
              />
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex-1">
              <IntegerController
                name="hoursToBeCredited"
                label="Hours to be Credited until retirement"
                placeholder="0"
                defaultValue={state.hoursToBeCredited}
                onChange={(value: number) =>
                  handleFieldChange("hoursToBeCredited", value)
                }
              />
            </div>
            <span className="text-xl font-bold">+</span>
            <div className="flex-1">
              <IntegerController
                name="currentAccumulatedSickLeave"
                label="Current accumulated sick leave hours saved"
                placeholder="0"
                defaultValue={state.currentAccumulatedSickLeave}
                onChange={(value: number) =>
                  handleFieldChange("currentAccumulatedSickLeave", value)
                }
              />
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex-1">
              <IntegerController
                name="estimatedSickLeaveHoursAtRetirement"
                label="Estimated sick leave hours at retirement"
                placeholder="0"
                defaultValue={state.estimatedSickLeaveHoursAtRetirement}
              />
            </div>
            <span className="text-xl font-bold">&divide;</span>
            <div className="flex-1">
              <IntegerController
                name="hoursPerYear"
                label="Hours per year (2087)"
                placeholder="0"
                defaultValue={2087}
              />
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex-1">
              <TextController
                name="additionalYearsOfServiceCredited"
                label="Additional years of service credited at retirement"
                placeholder="None"
                defaultValue={state.additionalYearsOfServiceCredited}
                disabled
              />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default UnusedSickLeave;
