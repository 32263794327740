import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../ui/dialog";
import TextController from "../form-fields/TextController";

const inviteSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, "Name is required.")
    .min(2, "Name must be at least 2 characters."),
  email: z.string().email("Invalid email address"),
});

type InviteValues = z.infer<typeof inviteSchema>;

interface InviteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInvite: (data: InviteValues) => void;
}

const InviteModal: React.FC<InviteModalProps> = ({
  isOpen,
  onClose,
  onInvite,
}) => {
  const form = useForm<InviteValues>({
    resolver: zodResolver(inviteSchema),
    defaultValues: {
      name: "",
      email: "",
    },
  });

  const onSubmit = (data: InviteValues) => {
    onInvite(data);
    form.reset();
    onClose(); // Ensure the modal is closed after submission
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Invite an Agent</DialogTitle>
          <DialogDescription>
            Specify the agent's name and email to send an invite.
          </DialogDescription>
        </DialogHeader>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <TextController
              name="name"
              label="Name"
              placeholder="Enter agent's name"
            />
            <TextController
              name="email"
              label="Email"
              placeholder="Enter agent's email"
              type="email"
            />
            <DialogFooter>
              <Button type="submit">Send Invite</Button>
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>
            </DialogFooter>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default InviteModal;
