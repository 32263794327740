import React from "react";
import { useFormContext } from "react-hook-form";
import { Card, CardContent, CardHeader } from "../../../../components/ui/card";
import SelectController from "../../../form-fields/SelectController";
import AmountController from "../../../form-fields/AmountController";
import PercentageController from "../../../form-fields/PercentageController";
import SwitchController from "../../../form-fields/SwitchController";
import IntegerController from "../../../form-fields/IntegerController";

const SectionFields401k = ({ client }: ClientType) => {
  const { watch } = useFormContext();

  return (
    <Card className="mb-8">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">401k</h4>
      </CardHeader>
      <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4">
        {/* Type (Traditional vs Roth) */}
        <SelectController
          name="t_401k_type"
          label="401k Type"
          options={[
            { value: "Traditional", label: "Traditional" },
            { value: "Roth", label: "Roth" },
          ]}
          defaultValue={client?.t_401k_type ?? "Traditional"}
          infoMessage="Choose between a Traditional or Roth 401k."
        />

        {/* Current Salary */}
        <AmountController
          name="t_401k_currentSalary"
          label="Current Salary (Annual $)"
          placeholder="Enter salary"
          infoMessage="Specify your current annual salary."
          defaultValue={client?.t_401k_currentSalary ?? 0.0}
        />

        {/* Pay Periods per Year */}
        <IntegerController
          name="t_401k_payPeriodsPerYear"
          label="Pay Periods per Year"
          placeholder="Enter number of pay periods"
          infoMessage="The number of pay periods you have per year."
          defaultValue={client?.t_401k_payPeriodsPerYear ?? 0}
        />
        <AmountController
          name="t_401k_existing401kValue"
          label="Existing 401k Value ($)"
          placeholder="Enter amount"
          infoMessage="Existing Value of your cleint's 401k account."
          defaultValue={client?.t_401k_existing401kValue ?? 0.0}
        />

        {/* Monthly % of Income to Contribute */}
        <PercentageController
          name="t_401k_monthlyContributionPercent"
          label="Monthly % of Income to Contribute"
          placeholder="Enter percentage"
          infoMessage="Percentage of your monthly income to contribute to your 401k."
          defaultValue={client?.t_401k_monthlyContributionPercent ?? 0.0}
        />

        {/* Employer's Monthly % Match */}
        <PercentageController
          name="t_401k_employerMatchPercent"
          label="Employer's Monthly % Match"
          placeholder="Enter percentage"
          infoMessage="The percentage your employer matches on your contributions."
          defaultValue={client?.t_401k_employerMatchPercent ?? 0.0}
        />

        {/* Salary Limit for Employer Match */}
        <PercentageController
          name="t_401k_salaryLimitForEmployerMatch"
          label="Salary Limit for Employer Match (%)"
          placeholder="Enter percentage"
          infoMessage="The salary percentage limit for the employer match."
          defaultValue={client?.t_401k_salaryLimitForEmployerMatch ?? 0.0}
        />

        {/* Annual Management Fee */}
        <PercentageController
          name="t_401k_annualManagementFee"
          label="Annual Management Fee (%)"
          placeholder="Enter percentage"
          infoMessage="The annual management fee percentage for your 401k."
          defaultValue={client?.t_401k_annualManagementFee ?? 0.0}
        />

        {/* Self-Directed (Yes/No) */}
        <SwitchController
          name="t_401k_selfDirected"
          label="Self-Directed"
          defaultValue={client?.t_401k_selfDirected ?? false}
          infoMessage="Toggle if this is a self-directed 401k."
        />

        {/* Estimated Early Withdrawals */}
        <AmountController
          name="t_401k_estimatedEarlyWithdrawals"
          label="Estimated Early Withdrawals ($)"
          placeholder="Enter amount"
          infoMessage="Estimated amount for early withdrawals from your 401k."
          defaultValue={client?.t_401k_estimatedEarlyWithdrawals ?? 0.0}
        />

        {/* Early Withdrawals Subject to Penalty */}
        <SwitchController
          name="t_401k_earlyWithdrawalPenalty"
          label="Early Withdrawals Subject to Penalty"
          //   defaultValue={false}
          infoMessage="Toggle if early withdrawals are subject to penalty."
          defaultValue={client?.t_401k_earlyWithdrawalPenalty ?? false}
        />
      </CardContent>
    </Card>
  );
};

export default SectionFields401k;
