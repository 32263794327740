import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Icons } from "../../utils/icons";

export default function VideoSection() {
  return (
    <Card className="w-2/3 mr-2">
      <CardHeader>
        <CardTitle className="text-accent flex items-center gap-2">
          <Icons.monitorPlay className="w-12 h-12 text-accent" />
          Introductory Video
        </CardTitle>
        <CardDescription>
          Please watch the video if you need help navigating the platform.
        </CardDescription>
      </CardHeader>
      <CardContent>
        {/* <iframe
          width="100%"
          height="500"
          src="https://www.youtube.com/embed/wNDOErA8WTY?si=a1w-5vqCVPwPVLlf"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe> */}
        <iframe
          width="100%"
          height="500"
          className="elementor-video-iframe"
          allowFullScreen
          allow="clipboard-write"
          title="vimeo Video Player"
          src="https://player.vimeo.com/video/952091051?autoplay=1&amp;playsinline=1&amp;color&amp;autopause=0&amp;loop=0&amp;muted=1&amp;title=1&amp;portrait=1&amp;byline=1#t="
          id="iFrameResizer0"
        ></iframe>
      </CardContent>
      {/* <CardFooter>
        <p>Card Footer</p>
      </CardFooter> */}
    </Card>
  );
}
