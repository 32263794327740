import React from "react";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import SelectController from "../../form-fields/SelectController";
import PercentageController from "../../form-fields/PercentageController";

interface RetirementIncomeNeededProps {
  client: ClientType;
}

const RetirementIncomeNeeded: React.FC<RetirementIncomeNeededProps> = ({
  client,
}) => {
  return (
    <Card className="mb-8" id="retirement-income-needed">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">
          Retirement Income Needed
        </h4>
      </CardHeader>

      <CardContent className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
        <SelectController
          name="incomeModel"
          label="Retirement Income Model"
          options={[
            {
              value: "percent_of_income_at_retirement",
              label: "Percent of Income at Retirement",
            },
          ]}
          defaultValue={
            client?.incomeModel || "percent_of_income_at_retirement"
          }
          infoMessage="Choose how you want your retirement income to be calculated. Typically based on a percentage of your income at retirement."
        />

        <PercentageController
          name="neededAsPercentage"
          label="Needed as a Percentage"
          placeholder="Enter the percentage needed (e.g., 80 for 80%)"
          defaultValue={client?.neededAsPercentage || 0.0}
          infoMessage="Example: Enter 80 for 80% of income needed at retirement."
        />

        <PercentageController
          name="annualizedColaAdjustment"
          label="Annualized Cost of Living/Inflation Adjustment"
          placeholder="Enter the annualized COLA percentage (e.g., 3 for 3%)"
          defaultValue={client?.annualizedColaAdjustment || 0.0}
          infoMessage="Example: Enter 3 for an annual adjustment of 3%."
        />
      </CardContent>
    </Card>
  );
};

export default RetirementIncomeNeeded;
