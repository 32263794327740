import React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import XanoAPI from "../../lib/XanoAPI";
import { Button } from "../../components/ui/button";

interface MassDeleteProps {
  selectedIds: string[]; // Accepts an array of selected client IDs
}

export function MassDelete({ selectedIds }: MassDeleteProps) {
  const api = XanoAPI.getInstance();

  const handleDelete = async () => {
    try {
      for (const id of selectedIds) {
        await api.deleteClient(id);
      }
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete clients:", error);
    }
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button className="bg-accent hover:bg-rose-600">Mass Delete</Button>
      </AlertDialogTrigger>
      {selectedIds.length > 0 && (
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the
              selected clients ({selectedIds?.length}) and remove their data
              from our servers.
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>
              Yes, Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      )}

      {selectedIds.length === 0 && (
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Please select clients to delete</AlertDialogTitle>
            <AlertDialogDescription>
              Please select the clients you would like to delete first.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            {/* <AlertDialogCancel>Cancel</AlertDialogCancel> */}
            <AlertDialogAction>OK!</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      )}
    </AlertDialog>
  );
}
