import React from "react";
import { useFormContext } from "react-hook-form";
import PercentageController from "../../form-fields/PercentageController";
import SwitchController from "../../form-fields/SwitchController";
import {
  Card,
  CardContent,
  CardHeader,
  CardFooter,
} from "../../../components/ui/card";
import { Button } from "../../../components/ui/button";
import { Icons } from "../../../utils/icons";

export default function SavingsInformationComponent({
  client,
}: {
  client: ClientType;
}) {
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useFormContext();

  return (
    <Card className="mb-8 shadow" id="savings-information">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">
          Side Fund/Additional Savings
        </h4>
      </CardHeader>
      <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-x-8">
        <div className="my-2">
          <PercentageController
            name="interestRatePriorToRetirement"
            label="Interest Rate Prior to Retirement"
            placeholder="Interest Rate Prior to Retirement"
            defaultValue={client?.interestRatePriorToRetirement || 0.0}
            infoMessage="Enter the annual interest rate expected before retirement."
          />
        </div>
        <div className="my-2">
          <PercentageController
            name="interestRateInRetirement"
            label="Interest Rate in Retirement"
            placeholder="Enter interest rate in retirement"
            defaultValue={client?.interestRateInRetirement || 0.0}
            infoMessage="Enter the expected interest rate for retirement, e.g., 5 for 5%."
          />
        </div>
        <div className="my-2">
          <SwitchController
            name="annuallyIncreaseSavingsWithRaises"
            label="Annually Increase Savings with Raises"
            infoMessage="Select if you want to annually increase savings with raises."
            defaultValue={client?.annuallyIncreaseSavingsWithRaises || false}
          />
        </div>
      </CardContent>
      {/* <CardFooter className="border-t flex justify-end">
        <Button type="submit" className="mt-5 p-3" disabled={!isValid}>
          <Icons.save className="mr-2" />
          Save
        </Button>
      </CardFooter> */}
    </Card>
  );
}
