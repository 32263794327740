import React, { useState, useEffect } from "react";
import { Button } from "../../ui/button";
import { Card, CardHeader, CardContent, CardFooter } from "../../ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import { PlanModal } from "../../modals/plan-modal";
import AddCardModalWrapper from "../../modals/add-card-modal";
import Loader from "../../../components/loader/loader";
import { Banknote, CreditCard, Trash } from "lucide-react";
import XanoAPI from "../../../lib/XanoAPI"; // Assuming this is the path to XanoAPI
import {
  Toast,
  ToastTitle,
  ToastDescription,
  ToastProvider,
  ToastViewport,
} from "../../ui/toast"; // Import toast components
import { useAuth } from "../../../lib/AuthContext";

const AGENT_COST = 50.0; // Fixed cost per agent

export function Billing() {
  const [loading, setLoading] = useState<boolean>(false);
  const [plans, setPlans] = useState<PlanType[]>([]);
  const [cards, setCards] = useState<CardType[]>([]);
  const [invoices, setInvoices] = useState<InvoiceType[]>([]);
  const [billingPeriod, setBillingPeriod] = useState<string>("");

  const [isPlanModalOpen, setIsPlanModalOpen] = useState<boolean>(false);
  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState<boolean>(false);
  const [isPaymentReady, setIsPaymentReady] = useState<boolean>(false); // Flag for payment readiness

  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastError, setToastError] = useState<boolean>(false);

  const auth = useAuth();
  const agencyId = auth?.currentUser?.agency_id || 1;

  useEffect(() => {
    const fetchBillingData = async () => {
      setLoading(true);
      try {
        const api = XanoAPI.getInstance();

        // Fetch cards and invoices from the API
        const [cardsResponse, invoicesResponse, usersResponse] =
          await Promise.all([
            api.getBillingCards(),
            api.getBillingInvoices(),
            api.getUsersByAgencyId(agencyId),
          ]);

        console.log("Fetched cards:", cardsResponse);
        console.log("Fetched invoices:", invoicesResponse);
        console.log("Fetched users: ", usersResponse[0]);

        const cardArray = Array.isArray(cardsResponse)
          ? cardsResponse
          : [cardsResponse];

        const date = new Date();
        const currentMonth = date.toLocaleString("default", { month: "long" }); // Gets the full name of the month, e.g., "August"
        const currentYear = date.getFullYear();

        // Extract users from the response
        const users = usersResponse[0]?.members || [];

        // Filter users based on module flags
        const federalPlanUsers = users.filter(
          (user: PlatformUser) => user.module_federal_plan
        );
        const plan401kUsers = users.filter(
          (user: PlatformUser) => user.module_401k_plan
        );

        const mockPlans = [
          {
            planName: "Federal Plan",
            agents: federalPlanUsers.length,
          },
          {
            planName: "401k Plan",
            agents: plan401kUsers.length,
          },
        ];

        const billingPeriod = `${currentMonth} ${currentYear}`; // Example billing period

        setPlans(mockPlans);
        setCards(cardArray);
        setInvoices(invoicesResponse);
        setBillingPeriod(billingPeriod);
      } catch (error) {
        console.error("Failed to fetch billing data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBillingData();
  }, []);

  const handlePayment = async (pay_online_url: string) => {
    try {
      // Redirect the user to the payment URL
      window.location.href = pay_online_url;
    } catch (error) {
      console.error("Failed to initiate payment:", error);
    }
  };

  const makeDefault = async (cardId: string) => {
    try {
      const api = XanoAPI.getInstance();
      await api.addBillingCard(cardId);
      setToastMessage(`Card ${cardId} is now the default payment method.`);
      setToastError(false);
    } catch (error) {
      console.error("Error setting default card:", error);
      setToastMessage("Failed to set card as default. Please try again.");
      setToastError(true);
    } finally {
      setToastOpen(true);
    }
  };

  const deleteCard = async (cardId: string) => {
    try {
      const api = XanoAPI.getInstance();
      await api.deleteBillingCard(cardId);
      setToastMessage(`Card ${cardId} has been deleted successfully.`);
      setToastError(false);
      setCards((prevCards) => prevCards.filter((card) => card.id !== cardId));
    } catch (error) {
      console.error("Error deleting card:", error);
      setToastMessage("Failed to delete card. Please try again.");
      setToastError(true);
    } finally {
      setToastOpen(true);
    }
  };

  if (loading) return <Loader />;

  const subtotal = plans.reduce(
    (acc, plan) => acc + plan.agents * AGENT_COST,
    0
  );

  function handleCardAdded(paymentMethod: any): void {
    console.log("Card added successfully:", paymentMethod);
  }

  return (
    <ToastProvider swipeDirection="right">
      <div className="space-y-6">
        <h2 className="text-2xl font-semibold text-accent mb-4">Billing</h2>

        {/* Plans Table */}
        <Card>
          <CardHeader>
            <h3 className="text-lg font-semibold">Plans</h3>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Plan</TableHead>
                  <TableHead>Number of Agents</TableHead>
                  <TableHead>Total Cost</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {plans.map((plan) => {
                  const totalCost = plan.agents * AGENT_COST;
                  return (
                    <TableRow key={plan.planName}>
                      <TableCell>{plan.planName}</TableCell>
                      <TableCell>{plan.agents}</TableCell>
                      <TableCell>${totalCost.toFixed(2)}</TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell className="font-semibold">
                    Billing Period
                  </TableCell>
                  <TableCell />
                  <TableCell className="font-semibold">
                    {billingPeriod}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-semibold">Subtotal</TableCell>
                  <TableCell />
                  <TableCell className="font-semibold">
                    ${subtotal.toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>

        {/* Cards Management Table */}
        <Card>
          <CardHeader>
            <h3 className="text-lg font-semibold">Payment Methods</h3>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Card Number</TableHead>
                  <TableHead>Cardholder Name</TableHead>
                  <TableHead>Expiry Date</TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {cards && cards.length > 0 ? (
                  cards.map((card) => (
                    <TableRow key={card.id}>
                      <TableCell>**** **** **** {card.last4}</TableCell>
                      <TableCell>{card.brand.toUpperCase()}</TableCell>
                      <TableCell>{`${card.exp_month}/${card.exp_year}`}</TableCell>
                      <TableCell>
                        <Button
                          className="bg-accent"
                          onClick={() => makeDefault(card.id)}
                        >
                          Make Default
                        </Button>
                        <Button
                          className="ml-4 bg-red-500 text-white"
                          onClick={() => deleteCard(card.id)}
                        >
                          <Trash className="w-4 h-4" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>No saved cards available.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardContent>
          <CardFooter className="flex justify-end border-t pt-3 bg-gray-50">
            <Button
              onClick={() => setIsAddCardModalOpen(true)}
              className="bg-accent"
            >
              <CreditCard className="w-5 h-5 mr-2" />
              Add New Card
            </Button>

            <AddCardModalWrapper
              isOpen={isAddCardModalOpen}
              onClose={() => setIsAddCardModalOpen(false)}
              onCardAdded={handleCardAdded}
            />
          </CardFooter>
        </Card>

        {/* Invoices Table */}
        <Card>
          <CardHeader>
            <h3 className="text-lg font-semibold">Recent Invoices</h3>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Status</TableHead>
                  <TableHead>View/Pay</TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {invoices.map((invoice) => (
                  <TableRow key={invoice.id}>
                    <TableCell>
                      <span
                        className={`px-2 py-1 rounded ${
                          invoice.paid
                            ? "bg-green-100 text-green-700"
                            : "bg-red-100 text-red-700"
                        }`}
                      >
                        {invoice.paid ? "Paid" : "Unpaid"}
                      </span>
                    </TableCell>
                    <TableCell>
                      <a
                        href={invoice.pay_online_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        Pay/View Invoice
                      </a>
                    </TableCell>
                    <TableCell>
                      {!invoice.paid && (
                        <Button
                          className="bg-accent"
                          onClick={() => handlePayment(invoice.pay_online_url)}
                        >
                          <Banknote className="mr-2 w-6 h-6" />
                          Pay Now
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>

        {/* Modals */}
        <PlanModal
          isOpen={isPlanModalOpen}
          onClose={() => setIsPlanModalOpen(false)}
        />
      </div>

      {/* Toast Implementation */}
      <Toast open={toastOpen} onOpenChange={setToastOpen}>
        <ToastTitle>{toastError ? "Error" : "Success"}</ToastTitle>
        <ToastDescription>{toastMessage}</ToastDescription>
      </Toast>
      <ToastViewport />
    </ToastProvider>
  );
}
