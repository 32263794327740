// import React, { useState, useEffect } from "react";
// import { useFormContext, Controller } from "react-hook-form";
// import { Input } from "../../components/ui/input";
// import {
//   FormItem,
//   FormLabel,
//   FormControl,
//   FormMessage,
// } from "../../components/ui/form";

// interface IntegerControllerProps {
//   name: string;
//   label: string;
//   placeholder: string;
//   defaultValue?: string | number;
//   validation?: {
//     required?: string;
//     min?: { value: number; message: string };
//     max?: { value: number; message: string };
//   };
//   infoMessage?: string;
//   onChange?: (value: any) => void;
//   increments?: boolean; // New parameter for increments
//   calculatedValue?: number;
// }

// const IntegerController: React.FC<IntegerControllerProps> = ({
//   name,
//   label,
//   placeholder,
//   defaultValue,
//   validation = {},
//   infoMessage,
//   onChange,
//   increments = false, // Default value for increments
//   calculatedValue,
// }) => {
//   const { control, setValue, setError, clearErrors } = useFormContext();
//   const [internalValue, setInternalValue] = useState<string>("");
//   const [localError, setLocalError] = useState<string | null>(null);

//   // useEffect(() => {
//   //   if (defaultValue !== undefined) {
//   //     setInternalValue(defaultValue.toString());
//   //     setValue(name, defaultValue);
//   //   }
//   // }, [defaultValue, setValue, name]);

//   const handleValidation = (value: string): boolean => {
//     let parsedValue = parseInt(value, 10);

//     if (isNaN(parsedValue)) {
//       parsedValue = 0;
//       setInternalValue(parsedValue.toString());
//       setValue(name, parsedValue);
//     }

//     if (isNaN(parsedValue)) {
//       setLocalError("Value must be an integer.");
//       setError(name, { type: "manual", message: "Value must be an integer." });
//       return false;
//     }

//     if (validation.min && parsedValue < validation.min.value) {
//       setLocalError(validation.min.message);
//       setError(name, { type: "manual", message: validation.min.message });
//       return false;
//     }

//     if (validation.max && parsedValue > validation.max.value) {
//       setLocalError(validation.max.message);
//       setError(name, { type: "manual", message: validation.max.message });
//       return false;
//     }

//     clearErrors(name);
//     setLocalError(null);
//     return true;
//   };

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const rawValue = e.target.value;
//     setInternalValue(rawValue);
//     handleValidation(rawValue);
//   };

//   const handleInputBlur = () => {
//     const parsedValue = parseInt(internalValue, 10);
//     const finalValue = isNaN(parsedValue) ? 0 : parsedValue;
//     setInternalValue(finalValue.toString());
//     if (handleValidation(finalValue.toString()) && onChange) {
//       onChange(finalValue);
//     }
//     setValue(name, finalValue); // Update form value
//   };

//   const handleIncrement = () => {
//     const parsedValue = parseInt(internalValue, 10);
//     const finalValue = isNaN(parsedValue) ? 5 : parsedValue + 5;
//     setInternalValue(finalValue.toString());
//     if (handleValidation(finalValue.toString()) && onChange) {
//       onChange(finalValue);
//     }
//     setValue(name, finalValue);
//   };

//   const handleDecrement = () => {
//     const parsedValue = parseInt(internalValue, 10);
//     const finalValue = isNaN(parsedValue) ? -5 : parsedValue - 5;
//     setInternalValue(finalValue.toString());
//     if (handleValidation(finalValue.toString()) && onChange) {
//       onChange(finalValue);
//     }
//     setValue(name, finalValue);
//   };

//   return (
//     <Controller
//       name={name}
//       control={control}
//       rules={validation}
//       render={({
//         field: { onChange: fieldOnChange, value, ...fieldProps },
//         fieldState: { error: fieldError },
//       }) => (
//         <FormItem className="mb-6">
//           <FormLabel htmlFor={name}>{label}</FormLabel>
//           <FormControl>
//             <div className="flex items-center">
//               <span className="flex justify-center items-center w-10 h-10 bg-gray-300 text-white rounded mr-1">
//                 #
//               </span>
//               <Input
//                 {...fieldProps}
//                 id={name}
//                 type="text"
//                 placeholder={placeholder}
//                 value={internalValue}
//                 onChange={(e) => {
//                   handleInputChange(e);
//                   const parsedValue = parseInt(e.target.value, 10);
//                   const finalValue = isNaN(parsedValue) ? 0 : parsedValue;
//                   fieldOnChange(finalValue);
//                 }}
//                 onBlur={handleInputBlur}
//                 className="mr-1"
//               />
//               {increments && (
//                 <div className="flex ml-1">
//                   <button
//                     type="button"
//                     onClick={handleDecrement}
//                     className="px-3 py-1 bg-gray-300 rounded mr-1 text-white hover:bg-accent"
//                   >
//                     -
//                   </button>
//                   <button
//                     type="button"
//                     onClick={handleIncrement}
//                     className="px-3 py-1 bg-gray-300 rounded text-white hover:bg-accent"
//                   >
//                     +
//                   </button>
//                 </div>
//               )}
//             </div>
//           </FormControl>
//           <FormMessage
//             className={
//               fieldError || localError
//                 ? "text-red-500"
//                 : "text-gray-500 text-xs font-normal"
//             }
//           >
//             {fieldError
//               ? fieldError.message
//               : localError
//               ? localError
//               : infoMessage}
//           </FormMessage>
//         </FormItem>
//       )}
//     />
//   );
// };

// export default IntegerController;

import React, { useState, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Input } from "../../components/ui/input";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "../../components/ui/form";

import { Icons } from "../../utils/icons";

interface IntegerControllerProps {
  name: string;
  label: string;
  placeholder: string;
  defaultValue?: string | number;
  validation?: {
    required?: string;
    min?: { value: number; message: string };
    max?: { value: number; message: string };
  };
  infoMessage?: string;
  onChange?: (value: any) => void;
  increments?: boolean; // New parameter for increments
  calculatedValue?: number;
}

const IntegerController: React.FC<IntegerControllerProps> = ({
  name,
  label,
  placeholder,
  defaultValue,
  validation = {},
  infoMessage,
  onChange,
  increments = false, // Default value for increments
  calculatedValue,
}) => {
  const { control, setValue, setError, clearErrors } = useFormContext();
  const [internalValue, setInternalValue] = useState<string>("");
  const [localError, setLocalError] = useState<string | null>(null);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setInternalValue(defaultValue.toString());
      setValue(name, defaultValue);
    }
  }, [defaultValue, setValue, name]);

  const handleValidation = (value: string): boolean => {
    let parsedValue = parseInt(value, 10);

    if (isNaN(parsedValue)) {
      parsedValue = 0;
      setInternalValue(parsedValue.toString());
      setValue(name, parsedValue);
    }

    if (isNaN(parsedValue)) {
      setLocalError("Value must be an integer.");
      setError(name, { type: "manual", message: "Value must be an integer." });
      return false;
    }

    if (validation.min && parsedValue < validation.min.value) {
      setLocalError(validation.min.message);
      setError(name, { type: "manual", message: validation.min.message });
      return false;
    }

    if (validation.max && parsedValue > validation.max.value) {
      setLocalError(validation.max.message);
      setError(name, { type: "manual", message: validation.max.message });
      return false;
    }

    clearErrors(name);
    setLocalError(null);
    return true;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    setInternalValue(rawValue);
    handleValidation(rawValue);
  };

  const handleInputBlur = () => {
    const parsedValue = parseInt(internalValue, 10);
    const finalValue = isNaN(parsedValue) ? 0 : parsedValue;
    setInternalValue(finalValue.toString());
    if (handleValidation(finalValue.toString()) && onChange) {
      onChange(finalValue);
    }
    setValue(name, finalValue); // Update form value
  };

  const handleIncrement = () => {
    const parsedValue = parseInt(internalValue, 10);
    const finalValue = isNaN(parsedValue) ? 5 : parsedValue + 5;
    setInternalValue(finalValue.toString());
    if (handleValidation(finalValue.toString()) && onChange) {
      onChange(finalValue);
    }
    setValue(name, finalValue);
  };

  const handleDecrement = () => {
    const parsedValue = parseInt(internalValue, 10);
    const finalValue = isNaN(parsedValue) ? -5 : parsedValue - 5;
    setInternalValue(finalValue.toString());
    if (handleValidation(finalValue.toString()) && onChange) {
      onChange(finalValue);
    }
    setValue(name, finalValue);
  };

  const handleUseCalculatedValue = () => {
    if (calculatedValue !== undefined) {
      const finalValue = calculatedValue.toString();
      setInternalValue(finalValue);
      if (handleValidation(finalValue) && onChange) {
        onChange(calculatedValue);
      }
      setValue(name, calculatedValue);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      render={({
        field: { onChange: fieldOnChange, value, ...fieldProps },
        fieldState: { error: fieldError },
      }) => (
        <FormItem className="mb-6">
          <FormLabel htmlFor={name}>{label}</FormLabel>
          <FormControl>
            <div className="flex items-center">
              {calculatedValue !== undefined && (
                <a
                  type="button"
                  onClick={handleUseCalculatedValue}
                  className="px-4 py-2 bg-accent rounded text-white hover:bg-accent mr-2 cursor-pointer"
                >
                  <Icons.calculator className="w-5 h-5" />
                </a>
              )}
              {!calculatedValue && (
                <span className="flex justify-center items-center w-10 h-10 bg-gray-300 text-white rounded mr-1">
                  #
                </span>
              )}
              <Input
                {...fieldProps}
                id={name}
                type="text"
                placeholder={placeholder}
                value={internalValue}
                onChange={(e) => {
                  handleInputChange(e);
                  const parsedValue = parseInt(e.target.value, 10);
                  const finalValue = isNaN(parsedValue) ? 0 : parsedValue;
                  fieldOnChange(finalValue);
                }}
                onBlur={handleInputBlur}
                className="mr-1"
              />
              {increments && (
                <div className="flex ml-1">
                  <button
                    type="button"
                    onClick={handleDecrement}
                    className="px-3 py-1 bg-gray-300 rounded mr-1 text-white hover:bg-accent"
                  >
                    -
                  </button>
                  <button
                    type="button"
                    onClick={handleIncrement}
                    className="px-3 py-1 bg-gray-300 rounded text-white hover:bg-accent"
                  >
                    +
                  </button>
                </div>
              )}
            </div>
          </FormControl>
          <FormMessage
            className={
              fieldError || localError
                ? "text-red-500"
                : "text-gray-500 text-xs font-normal"
            }
          >
            {fieldError
              ? fieldError.message
              : localError
              ? localError
              : infoMessage}
          </FormMessage>
        </FormItem>
      )}
    />
  );
};

export default IntegerController;
