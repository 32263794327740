import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider } from "react-hook-form";
import * as z from "zod";
import React from "react";
import { useAuth } from "../../../lib/AuthContext";
import XanoAPI from "../../../lib/XanoAPI";
import { Button } from "../../ui/button";
import { toast } from "../../../components/ui/use-toast";
import TextController from "../../form-fields/TextController";

const changePasswordSchema = z
  .object({
    currentPassword: z.string({
      required_error: "Current password is required.",
    }),
    newPassword: z
      .string()
      .min(6, "New password must be at least 6 characters long."),
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"], // This ensures the error is attached to the confirmPassword field
  });

type ChangePasswordValues = z.infer<typeof changePasswordSchema>;

export function ChangePassword() {
  const api = XanoAPI.getInstance();
  const auth = useAuth();
  const form = useForm<ChangePasswordValues>({
    resolver: zodResolver(changePasswordSchema),
    defaultValues: {
      currentPassword: "", // Ensure a default empty string, not `undefined`
      newPassword: "",
      confirmPassword: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data: ChangePasswordValues) => {
    if (!auth.currentUser || !auth.currentUser.id) {
      toast({
        title: "Error",
        description: "User ID is not available. Cannot update profile.",
      });
      return;
    }

    try {
      // Call the resetPassword method
      await api.resetPassword(auth.currentUser?.id, data.newPassword);
      toast({
        title: "Password Change Successful",
        description: "Your password has been updated successfully.",
      });
    } catch (error) {
      toast({
        title: "Password Change Failed",
        description:
          "An error occurred while updating your password. Please try again.",
      });
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <TextController
          name="currentPassword"
          label="Current Password"
          placeholder="Enter your current password"
          type="password" // Set the input type to password
          defaultValue=""
          validation={{
            required: "Current password is required.",
          }}
          infoMessage="Enter your current password."
        />

        <TextController
          name="newPassword"
          label="New Password"
          placeholder="Enter your new password"
          type="password" // Set the input type to password
          defaultValue=""
          validation={{
            required: "New password is required.",
            minLength: {
              value: 6,
              message: "New password must be at least 6 characters long.",
            },
          }}
          infoMessage="Enter a new password with at least 6 characters."
        />

        <TextController
          name="confirmPassword"
          label="Confirm New Password"
          placeholder="Confirm your new password"
          type="password" // Set the input type to password
          defaultValue=""
          validation={{
            required: "Please confirm your new password.",
            validate: (value) =>
              value === form.getValues("newPassword") ||
              "Passwords do not match.",
          }}
          infoMessage="Re-enter your new password to confirm."
        />

        <Button type="submit" className="bg-accent">
          Change Password
        </Button>
      </form>
    </FormProvider>
  );
}
