import { Separator } from "../components/ui/separator";
import { SidebarNav } from "../components/account/components/sidebar-nav";
import Header from "../components/header/header";
import { ProfileForm } from "../components/account/components/profile-form";
import { TwoFactorAuthForm } from "../components/account/components/two-factor-authentication";
import { ChangePassword } from "../components/account/components/change-password";
import { UserPreferencesForm } from "../components/account/components/preferences";
import MyTeam from "../components/account/components/team";
import Reports from "../components/account/components/reports";
import { Billing } from "../components/account/components/billing";
import AuditLog from "../components/account/components/audit-log";
import { isProduction } from "../lib/utils";
import { useAuth } from "../lib/AuthContext";
import config from "../config";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(config.STRIPE_KEY);

export default function Account() {
  const options = {
    // passing the client secret obtained from the server
    // clientSecret: "{{CLIENT_SECRET}}",
    // mode: "setup",
    // currency: "usd",
    // Fully customizable with appearance API.
    // appearance: {
    //   /*...*/
    // },
  };

  const auth = useAuth();
  const permissions = auth.currentUser?.user_permissions[0];
  permissions.can_manage_billing = true;

  const sidebarNavItems = [
    {
      title: "Profile",
      href: "/account/",
    },
    {
      title: "Two Factor Authentication",
      href: "/account/two-factor-authentication/",
    },
    {
      title: "Change Password",
      href: "/account/password/",
    },
  ];

  if (permissions.can_edit_preferences) {
    sidebarNavItems.push({
      title: "Preferences",
      href: "/account/preferences/",
    });
  }

  if (!isProduction() && permissions.can_manage_users) {
    sidebarNavItems.push({
      title: "Team",
      href: "/account/team/",
    });
  }

  if (!isProduction() && permissions.can_view_reports) {
    sidebarNavItems.push({
      title: "Reports",
      href: "/account/reports/",
    });
  }

  if (permissions.can_manage_billing) {
    sidebarNavItems.push({
      title: "Billing",
      href: "/account/billing/",
    });
  }

  sidebarNavItems.push({
    title: "Audit Log",
    href: "/account/audit-log/",
  });

  return (
    <>
      <Header />
      <div className="hidden space-y-6 p-10 pb-16 md:block">
        <div className="space-y-0.5 mt-12">
          <h2 className="text-2xl font-bold tracking-tight">My Account</h2>
          <p className="text-muted-foreground">
            Manage your account settings and set e-mail preferences.
          </p>
        </div>
        <Separator className="my-6" />
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
          <aside className="-mx-4 lg:w-1/5">
            <SidebarNav items={sidebarNavItems} />
          </aside>
          <div className="flex-1 lg:max-w-3xl">
            {window.location.pathname === "/account/" && <ProfileForm />}
            {window.location.pathname === "/account/password/" && (
              <ChangePassword />
            )}
            {window.location.pathname === "/account/preferences/" && (
              <UserPreferencesForm />
            )}
            {window.location.pathname === "/account/team/" && <MyTeam />}
            {window.location.pathname === "/account/reports/" && <Reports />}
            {window.location.pathname === "/account/billing/" && (
              <Elements stripe={stripePromise} options={options}>
                <Billing />
              </Elements>
            )}
            {window.location.pathname ===
              "/account/two-factor-authentication/" && <TwoFactorAuthForm />}
            {window.location.pathname === "/account/audit-log/" && <AuditLog />}
          </div>
        </div>
      </div>
    </>
  );
}
