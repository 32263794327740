import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";

interface Comparison {
  id: number;
  comparisonName: string;
  rateOfReturn: number;
  expectedIncomePercentage: number;
  yearsUntilIncome: number;
}

interface BalanceOverTime401kProps {
  client: ClientType;
}

const defaultItem: Comparison = {
  id: 0,
  comparisonName: "",
  rateOfReturn: 0,
  expectedIncomePercentage: 0,
  yearsUntilIncome: 0,
};

const emptyCollection = { values: [defaultItem] };

const parseJsonSafely = (json: string | undefined): Comparison[] => {
  if (!json) return emptyCollection.values;
  try {
    const values = JSON.parse(json).values;
    return values;
  } catch (error) {
    console.error("Failed to parse JSON", error);
    return emptyCollection.values;
  }
};

const BalanceOverTime401k: React.FC<BalanceOverTime401kProps> = ({
  client,
}) => {
  const [chartData, setChartData] = useState<any[]>([]);
  const [comparisons, setComparisons] = useState<Comparison[]>([]); // Manage comparisons in state

  useEffect(() => {
    if (
      !client?.calculated_401k_value ||
      client.calculated_401k_value.length === 0
    ) {
      return;
    }

    // Parse comparisons safely
    const parsedComparisons = parseJsonSafely(client.comparisons);
    setComparisons(parsedComparisons);

    const transformedData = client.calculated_401k_value.map(
      (item: any, index: number) => {
        const baseBalance = item.balance;
        const rowData: any = {
          year: item.year,
          balance401k: baseBalance,
        };

        // Calculate annuity balances for each comparison based on its rate of return
        parsedComparisons.forEach((comparison, compIndex) => {
          const balanceKey = `annuityBalance${compIndex + 1}`; // e.g., annuityBalance1, annuityBalance2, etc.
          const annuityBalance =
            baseBalance * (1 + (index * comparison.rateOfReturn) / 100);
          rowData[balanceKey] = annuityBalance;
        });

        return rowData;
      }
    );

    setChartData(transformedData);
  }, [client]);

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-accent">401k Balance Over Time</CardTitle>
        <CardDescription>Comparing 401k and annuity balances</CardDescription>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width="100%" height={400} className="p-2">
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis tickFormatter={(value) => `$${value.toLocaleString()}`} />
            <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />

            {/* Line for 401k balance */}
            <Line
              type="monotone"
              dataKey="balance401k"
              stroke="hsl(var(--chart-1))"
              strokeWidth={2}
              dot={{ fill: "hsl(var(--chart-1))" }}
              activeDot={{ r: 6 }}
            />

            {/* Lines for each comparison's annuity balance */}
            {comparisons.map((comparison, compIndex) => (
              <Line
                key={`annuityBalance${compIndex + 1}`}
                type="monotone"
                dataKey={`annuityBalance${compIndex + 1}`}
                stroke={`hsl(var(--chart-${compIndex + 2}))`} // Use different color for each comparison
                strokeWidth={2}
                dot={{ fill: `hsl(var(--chart-${compIndex + 2}))` }}
                activeDot={{ r: 6 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
      <CardFooter className="text-sm text-muted-foreground">
        Showing 401k balance and annuity balance over time
      </CardFooter>
    </Card>
  );
};

export default BalanceOverTime401k;
