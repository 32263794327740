// FeedbackForm.jsx
"use client";
import React from "react";
import XanoAPI from "../../lib/XanoAPI";
import { useToast } from "../../components/ui/use-toast";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "../../components/ui/button";
import { Textarea } from "../../components/ui/textarea";

const api = XanoAPI.getInstance();

const formSchema = z.object({
  user_id: z.number(),
  feedback_text: z.string().min(1, "Feedback is required"), // Validation schema for feedback
});

type FormData = z.infer<typeof formSchema>;

export default function FeedbackForm() {
  const { toast } = useToast();

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      user_id: api.getUserId(),
      feedback_text: "", // Initialize with empty feedback
    },
  });

  const { handleSubmit, control } = form;

  const onSubmit = async (data: FormData) => {
    // Process the feedback submission
    console.log(data);
    // Send data to your API or handle it accordingly
    try {
      await api.addFeedback(data);
      toast({
        title: "Feedback sent!",
        description: "Your feedback has been added.",
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex justify-center py-6 mb-8">
      <div className="w-full">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <Controller
              name="feedback_text"
              control={control}
              rules={{ required: "Feedback is required" }} // Define your validation rule
              render={({
                field,
                fieldState: { error }, // Destructure error from fieldState
              }) => (
                <>
                  <Textarea
                    {...field}
                    placeholder="Type your feedback here."
                    className={`w-full rounded-md border ${
                      error ? "border-red-500" : "border-gray-300"
                    } shadow-sm`} // Change border color if there is an error
                  />
                  {error && (
                    <p className="text-sm text-red-500">{error.message}</p> // Display the error message
                  )}
                </>
              )}
            />
            <Button type="submit">Send Feedback</Button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
