// import React, { useEffect, useState } from "react";
// import moment from "moment";
// import { Card, CardContent, CardHeader } from "../../../components/ui/card";
// import {
//   Table,
//   TableHeader,
//   TableBody,
//   TableRow,
//   TableCell,
//   TableHead,
// } from "../../../components/ui/table";
// import pensionLookupTable from "../../../utils/pensionLookupTable"; // Import the lookup table

// interface FederalInformationTableProps {
//   client: {
//     targetRetirementAge?: number | null;
//     targetRetirementMonth?: string | null;
//     retirementPlan?: string | null;
//     serviceComputationDate?: string | null;
//     ageToStartDistributionsAt?: number | null;
//     monthlyIncomeAt62?: number | null;
//     dateOfBirth?: string | null;
//     [key: string]: any;
//   };
// }

// const getPensionValue = (income: number, yearsOfService: number): number => {
//   const incomeLevels = Object.keys(pensionLookupTable).map(Number);
//   const closestIncome = incomeLevels.reduce((prev, curr) =>
//     Math.abs(curr - income) < Math.abs(prev - income) ? curr : prev
//   );

//   if (pensionLookupTable[closestIncome]) {
//     const pensionValue = pensionLookupTable[closestIncome][yearsOfService];
//     return typeof pensionValue === "number" ? pensionValue : 0;
//   }
//   return 0;
// };

// const FederalInformationTable: React.FC<FederalInformationTableProps> = ({
//   client,
// }) => {
//   const keys = [
//     "retirementAge",
//     "retirementDate",
//     "qualifyingYearsOfService",
//     "creditingYearsOfService",
//     "distributionStartAge",
//     "distributionStartDate",
//     "valueOfPrimaryOption",
//     "monthlyIncomeFromPension",
//   ] as const;

//   const calculateRetirementDate = (age: number, dateOfBirth: string) => {
//     const birthDate = moment(dateOfBirth);
//     const retirementDate = birthDate.clone().add(age, "years");
//     return retirementDate.format("MM/DD/YYYY");
//   };

//   const calculateYearsOfService = (serviceComputationDate: string) => {
//     const startDate = moment(serviceComputationDate);
//     const endDate = moment();
//     const years = endDate.diff(startDate, "years");
//     startDate.add(years, "years");
//     const months = endDate.diff(startDate, "months");
//     startDate.add(months, "months");
//     const days = endDate.diff(startDate, "days");
//     return `${years}y ${months}m ${days}d`;
//   };

//   const formatCurrency = (value: number) => {
//     if (isNaN(value)) return "N/A";
//     return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
//   };

//   const lookupMonthlyIncomeFromPension = (
//     income: number | null | undefined,
//     yearsOfService: string
//   ) => {
//     if (income === null || income === undefined) return "N/A";
//     const years = parseInt(yearsOfService.split("y")[0]);
//     return formatCurrency(getPensionValue(income, years));
//   };

//   const [data, setData] = useState({
//     currentSelection: {
//       retirementAge: client.targetRetirementAge?.toString() ?? "N/A",
//       retirementDate:
//         client.targetRetirementAge && client.dateOfBirth
//           ? calculateRetirementDate(
//               client.targetRetirementAge,
//               client.dateOfBirth
//             )
//           : "N/A",
//       qualifyingYearsOfService: client.serviceComputationDate
//         ? calculateYearsOfService(client.serviceComputationDate)
//         : "N/A",
//       creditingYearsOfService: client.serviceComputationDate
//         ? calculateYearsOfService(client.serviceComputationDate)
//         : "N/A",
//       distributionStartAge:
//         client.ageToStartDistributionsAt?.toString() ?? "N/A",
//       distributionStartDate:
//         client.ageToStartDistributionsAt && client.dateOfBirth
//           ? calculateRetirementDate(
//               client.ageToStartDistributionsAt,
//               client.dateOfBirth
//             )
//           : "N/A",
//       valueOfPrimaryOption: client.monthlyIncomeAt62
//         ? formatCurrency(client.monthlyIncomeAt62)
//         : "N/A",
//       monthlyIncomeFromPension: lookupMonthlyIncomeFromPension(
//         client.monthlyIncomeAt62,
//         client.serviceComputationDate
//           ? calculateYearsOfService(client.serviceComputationDate)
//           : "N/A"
//       ),
//     },
//     earliestWithIncomeAndNoPenalty: {
//       retirementAge: "62", // Assuming 62 is the age with no penalty
//       retirementDate: client.dateOfBirth
//         ? calculateRetirementDate(62, client.dateOfBirth)
//         : "N/A",
//       qualifyingYearsOfService: client.serviceComputationDate
//         ? calculateYearsOfService(client.serviceComputationDate)
//         : "N/A",
//       creditingYearsOfService: client.serviceComputationDate
//         ? calculateYearsOfService(client.serviceComputationDate)
//         : "N/A",
//       distributionStartAge: "62",
//       distributionStartDate: client.dateOfBirth
//         ? calculateRetirementDate(62, client.dateOfBirth)
//         : "N/A",
//       valueOfPrimaryOption: client.monthlyIncomeAt62
//         ? formatCurrency(client.monthlyIncomeAt62 * 0.8)
//         : "N/A", // Example calculation
//       monthlyIncomeFromPension: lookupMonthlyIncomeFromPension(
//         client.monthlyIncomeAt62,
//         client.serviceComputationDate
//           ? calculateYearsOfService(client.serviceComputationDate)
//           : "N/A"
//       ),
//     },
//     earliestPossible: {
//       retirementAge: "57", // Assuming 57 is the earliest possible retirement age
//       retirementDate: client.dateOfBirth
//         ? calculateRetirementDate(57, client.dateOfBirth)
//         : "N/A",
//       qualifyingYearsOfService: client.serviceComputationDate
//         ? calculateYearsOfService(client.serviceComputationDate)
//         : "N/A",
//       creditingYearsOfService: client.serviceComputationDate
//         ? calculateYearsOfService(client.serviceComputationDate)
//         : "N/A",
//       distributionStartAge: "57",
//       distributionStartDate: client.dateOfBirth
//         ? calculateRetirementDate(57, client.dateOfBirth)
//         : "N/A",
//       valueOfPrimaryOption: client.monthlyIncomeAt62
//         ? formatCurrency(client.monthlyIncomeAt62 * 0.5)
//         : "N/A", // Example calculation
//       monthlyIncomeFromPension: lookupMonthlyIncomeFromPension(
//         client.monthlyIncomeAt62,
//         client.serviceComputationDate
//           ? calculateYearsOfService(client.serviceComputationDate)
//           : "N/A"
//       ),
//     },
//   });

//   useEffect(() => {
//     const updatedData = {
//       currentSelection: {
//         retirementAge: client.targetRetirementAge?.toString() ?? "N/A",
//         retirementDate:
//           client.targetRetirementAge && client.dateOfBirth
//             ? calculateRetirementDate(
//                 client.targetRetirementAge,
//                 client.dateOfBirth
//               )
//             : "N/A",
//         qualifyingYearsOfService: client.serviceComputationDate
//           ? calculateYearsOfService(client.serviceComputationDate)
//           : "N/A",
//         creditingYearsOfService: client.serviceComputationDate
//           ? calculateYearsOfService(client.serviceComputationDate)
//           : "N/A",
//         distributionStartAge:
//           client.ageToStartDistributionsAt?.toString() ?? "N/A",
//         distributionStartDate:
//           client.ageToStartDistributionsAt && client.dateOfBirth
//             ? calculateRetirementDate(
//                 client.ageToStartDistributionsAt,
//                 client.dateOfBirth
//               )
//             : "N/A",
//         valueOfPrimaryOption: client.monthlyIncomeAt62
//           ? formatCurrency(client.monthlyIncomeAt62)
//           : "N/A",
//         monthlyIncomeFromPension: lookupMonthlyIncomeFromPension(
//           client.monthlyIncomeAt62,
//           client.serviceComputationDate
//             ? calculateYearsOfService(client.serviceComputationDate)
//             : "N/A"
//         ),
//       },
//       earliestWithIncomeAndNoPenalty: {
//         retirementAge: "62", // Assuming 62 is the age with no penalty
//         retirementDate: client.dateOfBirth
//           ? calculateRetirementDate(62, client.dateOfBirth)
//           : "N/A",
//         qualifyingYearsOfService: client.serviceComputationDate
//           ? calculateYearsOfService(client.serviceComputationDate)
//           : "N/A",
//         creditingYearsOfService: client.serviceComputationDate
//           ? calculateYearsOfService(client.serviceComputationDate)
//           : "N/A",
//         distributionStartAge: "62",
//         distributionStartDate: client.dateOfBirth
//           ? calculateRetirementDate(62, client.dateOfBirth)
//           : "N/A",
//         valueOfPrimaryOption: client.monthlyIncomeAt62
//           ? formatCurrency(client.monthlyIncomeAt62 * 0.8)
//           : "N/A", // Example calculation
//         monthlyIncomeFromPension: lookupMonthlyIncomeFromPension(
//           client.monthlyIncomeAt62,
//           client.serviceComputationDate
//             ? calculateYearsOfService(client.serviceComputationDate)
//             : "N/A"
//         ),
//       },
//       earliestPossible: {
//         retirementAge: "57", // Assuming 57 is the earliest possible retirement age
//         retirementDate: client.dateOfBirth
//           ? calculateRetirementDate(57, client.dateOfBirth)
//           : "N/A",
//         qualifyingYearsOfService: client.serviceComputationDate
//           ? calculateYearsOfService(client.serviceComputationDate)
//           : "N/A",
//         creditingYearsOfService: client.serviceComputationDate
//           ? calculateYearsOfService(client.serviceComputationDate)
//           : "N/A",
//         distributionStartAge: "57",
//         distributionStartDate: client.dateOfBirth
//           ? calculateRetirementDate(57, client.dateOfBirth)
//           : "N/A",
//         valueOfPrimaryOption: client.monthlyIncomeAt62
//           ? formatCurrency(client.monthlyIncomeAt62 * 0.5)
//           : "N/A", // Example calculation
//         monthlyIncomeFromPension: lookupMonthlyIncomeFromPension(
//           client.monthlyIncomeAt62,
//           client.serviceComputationDate
//             ? calculateYearsOfService(client.serviceComputationDate)
//             : "N/A"
//         ),
//       },
//     };
//     setData(updatedData);
//   }, [client]);

//   return (
//     <Card className="mb-8">
//       <CardHeader>
//         <h4 className="text-lg font-semibold text-gray-700">
//           Federal Information
//         </h4>
//       </CardHeader>
//       <CardContent>
//         <div className="overflow-x-auto">
//           <Table>
//             <TableHeader>
//               <TableRow className="bg-gray-100">
//                 <TableHead> </TableHead>
//                 <TableHead>Current Selection</TableHead>
//                 <TableHead>Earliest with Income & No Penalty</TableHead>
//                 <TableHead>Earliest Possible</TableHead>
//               </TableRow>
//             </TableHeader>
//             <TableBody>
//               {keys.map((key) => (
//                 <TableRow key={key}>
//                   <TableCell className="capitalize">
//                     {key.replace(/([A-Z])/g, " $1")}
//                   </TableCell>
//                   <TableCell>{data.currentSelection[key]}</TableCell>
//                   <TableCell>
//                     {data.earliestWithIncomeAndNoPenalty[key]}
//                   </TableCell>
//                   <TableCell>{data.earliestPossible[key]}</TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </div>
//       </CardContent>
//     </Card>
//   );
// };

// export default FederalInformationTable;

import React, { useEffect, useState } from "react";
import moment from "moment";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "../../../components/ui/table";
import pensionLookupTable from "../../../utils/pensionLookupTable"; // Import the lookup table

interface FederalInformationTableProps {
  client: {
    targetRetirementAge?: number | null;
    targetRetirementMonth?: string | null;
    retirementPlan?: string | null;
    serviceComputationDate?: string | null;
    ageToStartDistributionsAt?: number | null;
    monthlyIncomeAt62?: number | null;
    dateOfBirth?: string | null;
    [key: string]: any;
  };
}

const getPensionValue = (income: number, yearsOfService: number): number => {
  const incomeLevels = Object.keys(pensionLookupTable).map(Number);
  const closestIncome = incomeLevels.reduce((prev, curr) =>
    Math.abs(curr - income) < Math.abs(prev - income) ? curr : prev
  );

  if (pensionLookupTable[closestIncome]) {
    const pensionValue = pensionLookupTable[closestIncome][yearsOfService];
    return typeof pensionValue === "number" ? pensionValue : 0;
  }
  return 0;
};

const FederalInformationTable: React.FC<FederalInformationTableProps> = ({
  client,
}) => {
  const keys = [
    "retirementAge",
    "retirementDate",
    "qualifyingYearsOfService",
    "creditingYearsOfService",
    "distributionStartAge",
    "distributionStartDate",
    "monthlyIncomeFromPension",
  ] as const;

  const calculateRetirementDate = (age: number, dateOfBirth: string) => {
    const birthDate = moment(dateOfBirth);
    const retirementDate = birthDate.clone().add(age, "years");
    return retirementDate.format("MM/DD/YYYY");
  };

  const calculateYearsOfService = (serviceComputationDate: string) => {
    const startDate = moment(serviceComputationDate);
    const endDate = moment();
    const years = endDate.diff(startDate, "years");
    startDate.add(years, "years");
    const months = endDate.diff(startDate, "months");
    startDate.add(months, "months");
    const days = endDate.diff(startDate, "days");
    return `${years}y ${months}m ${days}d`;
  };

  const formatCurrency = (value: number) => {
    if (isNaN(value)) return "N/A";
    return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  };

  const lookupMonthlyIncomeFromPension = (
    income: number | null | undefined,
    yearsOfService: string
  ) => {
    if (income === null || income === undefined) return "N/A";
    const years = parseInt(yearsOfService.split("y")[0]);
    return formatCurrency(getPensionValue(income, years));
  };

  const [data, setData] = useState({
    currentSelection: {
      retirementAge: client.targetRetirementAge?.toString() ?? "N/A",
      retirementDate:
        client.targetRetirementAge && client.dateOfBirth
          ? calculateRetirementDate(
              client.targetRetirementAge,
              client.dateOfBirth
            )
          : "N/A",
      qualifyingYearsOfService: client.serviceComputationDate
        ? calculateYearsOfService(client.serviceComputationDate)
        : "N/A",
      creditingYearsOfService: client.serviceComputationDate
        ? calculateYearsOfService(client.serviceComputationDate)
        : "N/A",
      distributionStartAge:
        client.ageToStartDistributionsAt?.toString() ?? "N/A",
      distributionStartDate:
        client.ageToStartDistributionsAt && client.dateOfBirth
          ? calculateRetirementDate(
              client.ageToStartDistributionsAt,
              client.dateOfBirth
            )
          : "N/A",
      monthlyIncomeFromPension: lookupMonthlyIncomeFromPension(
        client.monthlyIncomeAt62,
        client.serviceComputationDate
          ? calculateYearsOfService(client.serviceComputationDate)
          : "N/A"
      ),
    },
    earliestWithIncomeAndNoPenalty: {
      retirementAge: "62", // Assuming 62 is the age with no penalty
      retirementDate: client.dateOfBirth
        ? calculateRetirementDate(62, client.dateOfBirth)
        : "N/A",
      qualifyingYearsOfService: client.serviceComputationDate
        ? calculateYearsOfService(client.serviceComputationDate)
        : "N/A",
      creditingYearsOfService: client.serviceComputationDate
        ? calculateYearsOfService(client.serviceComputationDate)
        : "N/A",
      distributionStartAge: "62",
      distributionStartDate: client.dateOfBirth
        ? calculateRetirementDate(62, client.dateOfBirth)
        : "N/A",
      monthlyIncomeFromPension: lookupMonthlyIncomeFromPension(
        client.monthlyIncomeAt62,
        client.serviceComputationDate
          ? calculateYearsOfService(client.serviceComputationDate)
          : "N/A"
      ),
    },
    earliestPossible: {
      retirementAge: "57", // Assuming 57 is the earliest possible retirement age
      retirementDate: client.dateOfBirth
        ? calculateRetirementDate(57, client.dateOfBirth)
        : "N/A",
      qualifyingYearsOfService: client.serviceComputationDate
        ? calculateYearsOfService(client.serviceComputationDate)
        : "N/A",
      creditingYearsOfService: client.serviceComputationDate
        ? calculateYearsOfService(client.serviceComputationDate)
        : "N/A",
      distributionStartAge: "57",
      distributionStartDate: client.dateOfBirth
        ? calculateRetirementDate(57, client.dateOfBirth)
        : "N/A",
      monthlyIncomeFromPension: lookupMonthlyIncomeFromPension(
        client.monthlyIncomeAt62,
        client.serviceComputationDate
          ? calculateYearsOfService(client.serviceComputationDate)
          : "N/A"
      ),
    },
  });

  useEffect(() => {
    const updatedData = {
      currentSelection: {
        retirementAge: client.targetRetirementAge?.toString() ?? "N/A",
        retirementDate:
          client.targetRetirementAge && client.dateOfBirth
            ? calculateRetirementDate(
                client.targetRetirementAge,
                client.dateOfBirth
              )
            : "N/A",
        qualifyingYearsOfService: client.serviceComputationDate
          ? calculateYearsOfService(client.serviceComputationDate)
          : "N/A",
        creditingYearsOfService: client.serviceComputationDate
          ? calculateYearsOfService(client.serviceComputationDate)
          : "N/A",
        distributionStartAge:
          client.ageToStartDistributionsAt?.toString() ?? "N/A",
        distributionStartDate:
          client.ageToStartDistributionsAt && client.dateOfBirth
            ? calculateRetirementDate(
                client.ageToStartDistributionsAt,
                client.dateOfBirth
              )
            : "N/A",
        monthlyIncomeFromPension: lookupMonthlyIncomeFromPension(
          client.monthlyIncomeAt62,
          client.serviceComputationDate
            ? calculateYearsOfService(client.serviceComputationDate)
            : "N/A"
        ),
      },
      earliestWithIncomeAndNoPenalty: {
        retirementAge: "62", // Assuming 62 is the age with no penalty
        retirementDate: client.dateOfBirth
          ? calculateRetirementDate(62, client.dateOfBirth)
          : "N/A",
        qualifyingYearsOfService: client.serviceComputationDate
          ? calculateYearsOfService(client.serviceComputationDate)
          : "N/A",
        creditingYearsOfService: client.serviceComputationDate
          ? calculateYearsOfService(client.serviceComputationDate)
          : "N/A",
        distributionStartAge: "62",
        distributionStartDate: client.dateOfBirth
          ? calculateRetirementDate(62, client.dateOfBirth)
          : "N/A",
        monthlyIncomeFromPension: lookupMonthlyIncomeFromPension(
          client.monthlyIncomeAt62,
          client.serviceComputationDate
            ? calculateYearsOfService(client.serviceComputationDate)
            : "N/A"
        ),
      },
      earliestPossible: {
        retirementAge: "57", // Assuming 57 is the earliest possible retirement age
        retirementDate: client.dateOfBirth
          ? calculateRetirementDate(57, client.dateOfBirth)
          : "N/A",
        qualifyingYearsOfService: client.serviceComputationDate
          ? calculateYearsOfService(client.serviceComputationDate)
          : "N/A",
        creditingYearsOfService: client.serviceComputationDate
          ? calculateYearsOfService(client.serviceComputationDate)
          : "N/A",
        distributionStartAge: "57",
        distributionStartDate: client.dateOfBirth
          ? calculateRetirementDate(57, client.dateOfBirth)
          : "N/A",
        monthlyIncomeFromPension: lookupMonthlyIncomeFromPension(
          client.monthlyIncomeAt62,
          client.serviceComputationDate
            ? calculateYearsOfService(client.serviceComputationDate)
            : "N/A"
        ),
      },
    };
    setData(updatedData);
  }, [client]);

  return (
    <Card className="mb-8" id="federal-information">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">
          Federal Information
        </h4>
      </CardHeader>
      <CardContent>
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow className="bg-gray-100">
                <TableHead> </TableHead>
                <TableHead>Current Selection</TableHead>
                <TableHead>Earliest with Income & No Penalty</TableHead>
                <TableHead>Earliest Possible</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {keys.map((key) => (
                <TableRow key={key}>
                  <TableCell className="capitalize">
                    {key.replace(/([A-Z])/g, " $1")}
                  </TableCell>
                  <TableCell>{data.currentSelection[key]}</TableCell>
                  <TableCell>
                    {data.earliestWithIncomeAndNoPenalty[key]}
                  </TableCell>
                  <TableCell>{data.earliestPossible[key]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default FederalInformationTable;
