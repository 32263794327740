import React, { useEffect, useState } from "react";
import { ArrowUpIcon } from "@radix-ui/react-icons";

const BackToTopButton: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div className="fixed bottom-20 right-2 z-50">
      {visible && (
        <button
          onClick={scrollToTop}
          className="p-3 rounded-full bg-accent text-white shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
        >
          <ArrowUpIcon />
        </button>
      )}
    </div>
  );
};

export default BackToTopButton;
