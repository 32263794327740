// import React from "react";
// import { Card, CardContent, CardHeader } from "../../../components/ui/card";
// import AmountController from "../../form-fields/AmountController";
// import IntegerController from "../../form-fields/IntegerController";
// import PercentageController from "../../form-fields/PercentageController";

// interface CurrentIncomeProps {
//   client: ClientType;
// }

// const CurrentIncome: React.FC<CurrentIncomeProps> = ({ client }) => {
//   return (
//     <Card className="mb-8">
//       <CardHeader>
//         <h4 className="text-lg font-semibold text-gray-700">Current Income</h4>
//       </CardHeader>
//       <CardContent className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
//         <AmountController
//           name="grossIncome"
//           label="Gross Income per Pay Period"
//           placeholder="Enter your gross income per pay period."
//           defaultValue={client?.grossIncome || 0.0}
//           infoMessage="Example: 6000 (for $6,000.00)"
//         />

//         <IntegerController
//           name="payPeriodsAnnual"
//           label="Pay Periods per Year"
//           placeholder="20"
//           defaultValue={client?.payPeriodsAnnual || 0}
//           infoMessage="Example: 26 (for bi-weekly pay)"
//         />

//         <PercentageController
//           name="expectedAnnualRaise"
//           label="Expected Annual Raise (%)"
//           placeholder="0.00"
//           defaultValue={client?.expectedAnnualRaise || 0.0}
//           infoMessage="Example: 3.5 (for 3.5% annual raise)"
//         />
//       </CardContent>
//     </Card>
//   );
// };

// export default CurrentIncome;

import React from "react";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import AmountController from "../../form-fields/AmountController";
import IntegerController from "../../form-fields/IntegerController";
import PercentageController from "../../form-fields/PercentageController";
import { useAuth } from "../../../lib/AuthContext";

interface CurrentIncomeProps {
  client: ClientType;
}

const CurrentIncome: React.FC<CurrentIncomeProps> = ({ client }) => {
  const auth = useAuth();

  return (
    <Card className="mb-8" id="current-income">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">Current Income</h4>
      </CardHeader>
      <CardContent className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
        <AmountController
          name="grossIncome"
          label="Gross Income per Pay Period"
          placeholder="Enter your gross income per pay period."
          defaultValue={client?.grossIncome ?? 0}
          infoMessage="Example: 6000 (for $6,000.00)"
        />

        <IntegerController
          name="payPeriodsAnnual"
          label="Pay Periods per Year"
          placeholder="20"
          defaultValue={client?.payPeriodsAnnual || 0}
          infoMessage="Example: 26 (for bi-weekly pay)"
        />

        <PercentageController
          name="expectedAnnualRaise"
          label="Expected Annual Raise (%)"
          placeholder="0.00"
          defaultValue={
            client?.expectedAnnualRaise ||
            auth?.currentUser?.user_preferences?.annual_raise
          }
          infoMessage="Example: 3.5 (for 3.5% annual raise)"
        />
      </CardContent>
    </Card>
  );
};

export default CurrentIncome;
