import React from "react";
import { useFormContext } from "react-hook-form";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import SelectController from "../../form-fields/SelectController";
import SwitchController from "../../form-fields/SwitchController";
import AmountController from "../../form-fields/AmountController";

// Options for the federal supplement method selection
const federalSupplementOptions = [
  { value: "calculate_value", label: "Calculate Value" },
  { value: "enter_age_62_value", label: "Enter Age 62 Value" },
];

interface FederalSupplementProps {
  client: ClientType;
}

const FederalSupplement: React.FC<FederalSupplementProps> = ({ client }) => {
  const { getValues } = useFormContext(); // Hook to get form values

  return (
    <Card className="mb-8" id="federal-supplement">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">
          Federal Supplement
        </h4>
      </CardHeader>
      <CardContent className="space-y-4">
        <SelectController
          name="determineSocialSecurityMethod"
          label="Method to determine Social Security at age 62"
          options={federalSupplementOptions}
          defaultValue={
            client?.determineSocialSecurityMethod || "calculate_value"
          }
          infoMessage="Select the appropriate federal supplement option."
        />

        {getValues("determineSocialSecurityMethod") ===
          "enter_age_62_value" && (
          <AmountController
            name="monthlyIncomeAt62"
            label="Monthly Income at Age 62"
            placeholder="0.00"
            defaultValue={client?.monthlyIncomeAt62 || 0.0}
            infoMessage="What will be the monthly income at age 62?"
          />
        )}

        <SwitchController
          name="supplementHasAnnualCola"
          label="Supplement has Annual COLA"
          defaultValue={client?.supplementHasAnnualCola || false}
          infoMessage="Toggle to include federal supplement in calculations."
        />
      </CardContent>
    </Card>
  );
};

export default FederalSupplement;
