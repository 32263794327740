import React from "react";
// import Header from "../components/header/header";
import Footer from "../components/footer/footer";

const Terms: React.FC = () => {
  return (
    <>
      <div className="hidden flex-col min-h-screen md:flex">
        {/* <Header /> */}
        <div className="flex-1 space-y-4 p-8 pt-6 container mx-auto">
          <div className="pt-10">
            <h2 className="text-3xl font-bold tracking-tight">
              Terms of Service
            </h2>
            <p className="text-muted-foreground mt-4">
              {/* Placeholder for a brief introduction to your terms */}
              Please read these Terms of Service carefully before using our
              services.
            </p>
          </div>

          {/* The terms of service content */}
          <div className="prose lg:prose-xl max-w-none text-muted-foreground pt-6">
            {/* Your Terms of Service Content Here */}
            <p>
              This section should outline the terms and conditions that govern
              the use of your service. Be sure to cover all necessary legal
              aspects in detail to protect both you and your users.
            </p>
            {/* Add more content as needed */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Terms;
