import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import TextController from "../../../form-fields/TextController";
import PercentageController from "../../../form-fields/PercentageController";
import AmountController from "../../../form-fields/AmountController";
import { debounce } from "../../../../lib/utils";
import { useAuth } from "../../../../lib/AuthContext";
import { Badge } from "../../../../components/ui/badge";

const defaultAllocations = [
  {
    fund: "G",
    rateOfReturn: 0,
    preTaxPercentAllocated: 0,
    preTaxCurrentBalance: 0,
    rothPercentAllocated: 0,
    rothCurrentBalance: 0,
  },
  {
    fund: "F",
    rateOfReturn: 0,
    preTaxPercentAllocated: 0,
    preTaxCurrentBalance: 0,
    rothPercentAllocated: 0,
    rothCurrentBalance: 0,
  },
  {
    fund: "C",
    rateOfReturn: 0,
    preTaxPercentAllocated: 0,
    preTaxCurrentBalance: 0,
    rothPercentAllocated: 0,
    rothCurrentBalance: 0,
  },
  {
    fund: "S",
    rateOfReturn: 0,
    preTaxPercentAllocated: 0,
    preTaxCurrentBalance: 0,
    rothPercentAllocated: 0,
    rothCurrentBalance: 0,
  },
  {
    fund: "I",
    rateOfReturn: 0,
    preTaxPercentAllocated: 0,
    preTaxCurrentBalance: 0,
    rothPercentAllocated: 0,
    rothCurrentBalance: 0,
  },
];

interface AllocationProps {
  client: any; // Replace `any` with your actual ClientType
}

interface PlatformUserPreferences {
  federal_tsp_g: number;
  federal_tsp_f: number;
  federal_tsp_c: number;
  federal_tsp_s: number;
  federal_tsp_i: number;
  // Add other preferences if needed
}

const parseAllocations = (allocationsString: string) => {
  try {
    const parsedAllocations = JSON.parse(allocationsString);
    if (
      Array.isArray(parsedAllocations) &&
      parsedAllocations.length === defaultAllocations.length
    ) {
      return parsedAllocations;
    }
  } catch {
    // If parsing fails, return default allocations
  }
  return defaultAllocations;
};

const Allocations: React.FC<AllocationProps> = ({ client }) => {
  const auth = useAuth();

  const { setValue } = useFormContext();
  const initialAllocations = parseAllocations(
    client.federal_tsp_allocations || ""
  );
  const [allocations, setAllocations] = useState(initialAllocations);

  useEffect(() => {
    const debouncedAutoSave = debounce(() => {
      const jsonAllocations = JSON.stringify(allocations);
      setValue("federal_tsp_allocations", jsonAllocations);
    }, 1000);

    debouncedAutoSave();
  }, [allocations, setValue]);

  const handleInputChange = (
    index: number,
    field: keyof (typeof defaultAllocations)[number],
    value: string | number
  ) => {
    const updatedAllocations = allocations.map((allocation, i) =>
      i === index ? { ...allocation, [field]: value } : allocation
    );
    setAllocations(updatedAllocations);
  };

  const handleSetDefaults = () => {
    if (auth?.currentUser?.user_preferences) {
      const userPrefs = auth.currentUser
        .user_preferences as PlatformUserPreferences;
      const updatedAllocations = allocations.map((allocation) => ({
        ...allocation,
        rateOfReturn:
          userPrefs[
            `federal_tsp_${allocation.fund.toLowerCase()}` as keyof PlatformUserPreferences
          ],
        percentAllocated: allocation.percentAllocated, // Maintain current value or set default if needed
        currentBalance: allocation.currentBalance, // Maintain current value or set default if needed
      }));
      setAllocations(updatedAllocations);
    }
  };

  return (
    <div className="flex flex-col w-full border">
      <Badge
        onClick={handleSetDefaults}
        className="m-4 w-[100px] cursor-pointer"
      >
        Set Defaults
      </Badge>
      <div className="grid grid-cols-1 md:grid-cols-6 gap-x-8 p-4">
        {allocations.map((allocation, index) => (
          <React.Fragment key={index}>
            <div className="space-y-2 mb-6">
              <TextController
                name={`allocations[${index}].fund`}
                label="Fund"
                placeholder="Fund"
                defaultValue={allocation.fund}
                disabled={true}
                onChange={(value) => handleInputChange(index, "fund", value)}
              />
            </div>
            <div className="space-y-2 mb-6">
              <PercentageController
                name={`allocations[${index}].rateOfReturn`}
                label="Rate of Return"
                placeholder="0.0000"
                defaultValue={allocation.rateOfReturn}
                onChange={(value) =>
                  handleInputChange(index, "rateOfReturn", value)
                }
                infoMessage="Enter the rate of return for the fund."
              />
            </div>
            <div className="space-y-2 mb-6">
              <PercentageController
                name={`allocations[${index}].preTaxPercentAllocated`}
                label="Pre-Tax Percent Allocated"
                placeholder="0.0000"
                defaultValue={allocation.preTaxPercentAllocated}
                onChange={(value) =>
                  handleInputChange(index, "preTaxPercentAllocated", value)
                }
                infoMessage="Enter the percent allocated to the fund for Pre-Tax."
              />
            </div>
            <div className="space-y-2 mb-6">
              <AmountController
                name={`allocations[${index}].preTaxCurrentBalance`}
                label="Pre-Tax Current Balance"
                placeholder="$0"
                defaultValue={allocation.preTaxCurrentBalance}
                onChange={(value) =>
                  handleInputChange(index, "preTaxCurrentBalance", value)
                }
                infoMessage="Enter the current balance for the fund for Pre-Tax."
              />
            </div>
            <div className="space-y-2 mb-6">
              <PercentageController
                name={`allocations[${index}].rothPercentAllocated`}
                label="Roth Percent Allocated"
                placeholder="0.0000"
                defaultValue={allocation.rothPercentAllocated}
                onChange={(value) =>
                  handleInputChange(index, "rothPercentAllocated", value)
                }
                infoMessage="Enter the percent allocated to the fund for Roth."
              />
            </div>
            <div className="space-y-2 mb-6">
              <AmountController
                name={`allocations[${index}].rothCurrentBalance`}
                label="Roth Current Balance"
                placeholder="$0"
                defaultValue={allocation.rothCurrentBalance}
                onChange={(value) =>
                  handleInputChange(index, "rothCurrentBalance", value)
                }
                infoMessage="Enter the current balance for the fund for Roth."
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Allocations;
