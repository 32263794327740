import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogTrigger,
} from "../ui/dialog";
import TextController from "../form-fields/TextController";
import SwitchController from "../form-fields/SwitchController";

const roleSchema = z.object({
  role: z
    .string()
    .trim()
    .min(1, "Role name is required.")
    .min(2, "Role name must be at least 2 characters."),
  can_manage_users: z.boolean(),
  can_manage_clients: z.boolean(),
  can_view_reports: z.boolean(),
  can_edit_preferences: z.boolean(),
});

type RoleValues = z.infer<typeof roleSchema>;

interface RoleModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: RoleValues) => void;
}

const RoleModal: React.FC<RoleModalProps> = ({ isOpen, onClose, onSave }) => {
  const form = useForm<RoleValues>({
    resolver: zodResolver(roleSchema),
    defaultValues: {
      role: "",
      can_manage_users: false,
      can_manage_clients: false,
      can_view_reports: false,
      can_edit_preferences: false,
    },
  });

  const onSubmit = (data: RoleValues) => {
    onSave(data);
    form.reset();
    onClose(); // Ensure the modal is closed after submission
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      {/* <DialogTrigger asChild>
        <Button variant="outline" className="text-accent hover:text-white">
          Add Role
        </Button>
      </DialogTrigger> */}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add New Role</DialogTitle>
          <DialogDescription>Specify the new role's details.</DialogDescription>
        </DialogHeader>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <TextController
              name="role"
              label="Role Name"
              placeholder="Enter role name"
            />
            <SwitchController
              name="can_manage_users"
              label="Can Manage Users"
              defaultValue={false}
              infoMessage=""
            />
            <SwitchController
              name="can_manage_clients"
              label="Can Manage Clients"
              defaultValue={false}
              infoMessage=""
            />
            <SwitchController
              name="can_view_reports"
              label="Can View Reports"
              defaultValue={false}
              infoMessage=""
            />
            <SwitchController
              name="can_edit_preferences"
              label="Can Edit Preferences"
              defaultValue={false}
              infoMessage=""
            />
            <DialogFooter>
              <Button type="submit">Add Role</Button>
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>
            </DialogFooter>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default RoleModal;
