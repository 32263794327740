import React, { useEffect, useState } from "react";
import { useAuth } from "../../lib/AuthContext";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../../components/ui/card";

const Statistics: React.FC = () => {
  const auth = useAuth();
  console.log(auth.currentUser);
  const [clientsNo, setClientsNo] = useState(auth?.clients?.length);
  const [typeFederalClients, setTypeFederalClients] = useState(
    auth?.clients?.filter((c) => c.type === "federal")
  );
  const [type401kClients, setType401kClients] = useState(
    auth?.clients?.filter((c) => c.type === "401k")
  );

  const stats = [
    {
      title: "Total # of Clients",
      value: clientsNo,
      subtitle: "Total number of clients you have added.",
    },
    {
      title: "Federal Clients",
      value: typeFederalClients?.length,
      subtitle: "Federal module clients you have added.",
    },
    {
      title: "401k Clients",
      value: type401kClients?.length,
      subtitle: "401k clients you have added.",
    },
    {
      title: "Modules Available",
      value: 2,
      subtitle: "Modules currently available for your clients.",
    },
  ];

  return (
    <div className={`grid gap-4 md:grid-cols-2 lg:grid-cols-4 mb-8 `}>
      {stats.map((stat, index) => (
        <Card key={index}>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">{stat.title}</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold text-accent">{stat.value}</div>
            <p className="text-xs text-muted-foreground">{stat.subtitle}</p>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default Statistics;
