import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import SelectController from "../../form-fields/SelectController";
import SwitchController from "../../form-fields/SwitchController";
import DateController from "../../form-fields/DateController";
import IntegerController from "../../form-fields/IntegerController";

interface FederalRetirementPlanProps {
  client: ClientType;
  watchAtAge: any;
  watchRetirementPlan: any;
}

const FederalRetirementPlan: React.FC<FederalRetirementPlanProps> = ({
  client,
  watchAtAge,
  watchRetirementPlan,
}) => {
  // const watchRetirementPlan = useWatch("retirementPlan");

  return (
    <Card className="mb-8" id="federal-retirement-plan">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">
          Federal Retirement Plan
        </h4>
      </CardHeader>

      <CardContent className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
        <SelectController
          name="retirementPlan"
          label="Retirement Plan"
          options={[
            {
              value: "fers",
              label: "Federal Employees Retirement System (FERS)",
            },
            {
              value: "csrs",
              label: "Civil Service Retirement System (CSRS)",
            },
            {
              value: "csrs_offset",
              label: "Civil Service Retirement System (CSRS) Offset",
            },
          ]}
          defaultValue={client?.retirementPlan || "fers"}
          infoMessage="FERS is the default retirement plan for most federal employees."
        />

        {watchRetirementPlan === "fers" && (
          <SelectController
            name="fersPlan"
            label="Plan Option (FERS)"
            options={[
              { value: "unreduced_annuity", label: "Unreduced Annuity" },
              { value: "full", label: "Full" },
              { value: "partial", label: "Partial" },
            ]}
            defaultValue={client?.fersPlan || "unreduced_annuity"}
            infoMessage="Select your FERS plan option."
          />
        )}

        <DateController
          name="serviceComputationDate"
          label="Service Computation Date"
          placeholder="YYYY-MM-DD"
          defaultValue={client?.serviceComputationDate || ""}
          infoMessage="Enter the service computation date."
          showDuration={true}
          client={client}
          validation={{
            // required: "Date of birth is required",
            min: {
              value: "01/01/1900",
              message: "Date cannot be before 01/01/1900",
            },
            max: {
              value: "12/31/2023",
              message: "Date cannot be after 12/31/2023",
            },
            noFutureDates: true,
          }}
        />

        <SelectController
          name="startRetirementDistAt"
          label="Start Retirement Distributions At"
          options={[
            { value: "no_penalty_age", label: "No Penalty Age" },
            { value: "specific_age", label: "Specific Age" },
            { value: "earliest_possible", label: "Earliest Possible" },
          ]}
          defaultValue={client?.startRetirementDistAt || "no_penalty_age"}
          infoMessage="Select when you plan to start retirement distributions. 'No Penalty Age' is the default option."
        />

        <SwitchController
          name="includeAnnualLeaveBenefit"
          label="Include Annual Leave Benefit"
          defaultValue={client?.includeAnnualLeaveBenefit || false}
          infoMessage="Specify if you want to include annual leave benefit in the calculation."
        />

        {/* <SwitchController
          name="includeMilitaryService"
          label="Includes Military Service"
          defaultValue={client?.includeMilitaryService || false}
          infoMessage="Indicate if military service is to be included in the calculation."
        /> */}

        {watchAtAge === "specific_age" && (
          <IntegerController
            name="ageToStartDistributionsAt"
            label="Age to Start Distributions At"
            defaultValue={client?.ageToStartDistributionsAt || 0}
            placeholder="65"
            infoMessage="Enter the age you'd like start distributions at"
          />
        )}
      </CardContent>
    </Card>
  );
};

export default FederalRetirementPlan;
