import { Row } from "@tanstack/react-table";
import { Button } from "../../components/ui/button";
import { DeleteClient } from "./delete-client";
import { useAuth } from "../../lib/AuthContext";

interface Client {
  id: string;
}

interface DataTableRowActionsProps {
  row: Row<Client>;
}

export function DataTableRowActions({ row }: DataTableRowActionsProps) {
  const auth = useAuth();
  const permissions = auth.currentUser?.user_permissions[0];
  const client = row.original;

  const handleEdit = () => {
    window.location.replace(`/edit-client/${client.id}`);
  };

  return (
    <div>
      {permissions.can_manage_clients && (
        <Button
          variant="outline"
          onClick={handleEdit}
          className="mr-2 hover:bg-accent"
        >
          Edit
        </Button>
      )}

      {permissions.can_manage_clients && <DeleteClient id={client.id} />}
    </div>
  );
}
