import React, { useState, useEffect } from "react";
import ComparisonsComponent from "../components/comparisons-component";
import ComparisonsTable401k from "../components/comparisons-table-401k";
import { ComparisonsAlert } from "../../../components/modals/comparisons-alert";

const defaultItem: Comparison = {
  id: 0,
  comparisonName: "",
  rateOfReturn: 0,
  expectedIncomePercentage: 0,
  yearsUntilIncome: 0,
};

const emptyCollection = { values: [defaultItem] };

const parseJsonSafely = (json: string | undefined): Comparison[] => {
  if (!json) return emptyCollection.values;
  const values = JSON.parse(json).values;
  return values;
};

const Comparisons401k: React.FC<ComparisonsProps> = ({
  client,
}: ClientType) => {
  const [comparisons, setComparisons] = useState<Comparison[]>(
    parseJsonSafely(client.comparisons) || []
  );
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if (
      !client.calculated_401k_value ||
      client.calculated_401k_value.length === 0
    )
      return;

    const generatedData = client.calculated_401k_value.map(
      (item: any, index: number) => {
        const row: any = {
          year: item.year,
          balance401k: item.balance,
        };

        comparisons.forEach((comparison, compIndex) => {
          const balanceKey = `balance${compIndex}`;
          const diffKey = `diff${compIndex}`;

          const balance =
            item.balance * (1 + (index * comparison.rateOfReturn) / 100);

          row[balanceKey] = balance || 0;
          row[diffKey] = balance - item.balance || 0;
        });

        return row;
      }
    );

    setData(generatedData);
  }, [client.calculated_401k_value, comparisons]);

  const hasValidComparisons = comparisons.some(
    (comp) =>
      comp.comparisonName !== "" ||
      comp.rateOfReturn !== 0 ||
      comp.expectedIncomePercentage !== 0 ||
      comp.yearsUntilIncome !== 0
  );

  return (
    <div className="py-6">
      <ComparisonsComponent client={client} setComparisons={setComparisons} />
      {client.calculated_401k_value.length === 0 && <ComparisonsAlert />}
      {client.calculated_401k_value.length !== 0 && hasValidComparisons && (
        <ComparisonsTable401k data={data} comparisons={comparisons} />
      )}
    </div>
  );
};

export default Comparisons401k;
