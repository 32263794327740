import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { toast } from "../components/ui/use-toast";
import XanoAPI from "../lib/XanoAPI";
import SignInImage from "../images/signin.jpg";

// Define a schema for the form data
const signUpSchema = z
  .object({
    // name: z.string().optional(),
    email: z.string().email({ message: "Invalid email address" }),
    password: z
      .string()
      .min(12, { message: "Password must be at least 12 characters" }),
    confirmPassword: z
      .string()
      .min(12, { message: "Confirm password must be at least 12 characters" }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

type SignUpValues = z.infer<typeof signUpSchema>;

export default function SignUp() {
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get("invite_code") || "";
  const email = searchParams.get("email") || "";

  const methods = useForm<SignUpValues>({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      // inviteCode,
      email: email,
      password: "",
      confirmPassword: "",
    },
  });

  const api = XanoAPI.getInstance();

  const onSubmit = async (data: SignUpValues) => {
    try {
      await api.signUp(inviteCode, data.password);
      toast({
        title: "Sign Up Successful",
        description: "You have successfully signed up. Please log in.",
      });
      window.location.replace("/sign-in");
    } catch (error) {
      console.error("Error during sign-up:", error);
      toast({
        title: "Sign Up Error",
        description: "There was an error during sign-up. Please try again.",
      });
    }
  };

  return (
    <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[400px] gap-6">
          <div className="grid gap-2 text-center mb-8">
            <h1 className="text-3xl font-bold mb-8">Sign Up</h1>
            <p className="text-balance text-muted-foreground">
              You have been invited to join the agency. Please set your password
              in order to continue.
            </p>
          </div>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className="grid gap-4"
            >
              {/* <div className="grid gap-2">
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  type="text"
                  placeholder="Your name"
                  {...methods.register("name")}
                />
                {methods.formState.errors.name && (
                  <p className="text-red-500 text-xs">
                    {methods.formState.errors.name.message}
                  </p>
                )}
              </div>
              */}
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="m@example.com"
                  {...methods.register("email")}
                />
                {methods.formState.errors.email && (
                  <p className="text-red-500 text-xs">
                    {methods.formState.errors.email.message}
                  </p>
                )}
              </div>
              <div className="grid gap-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  placeholder="***********"
                  {...methods.register("password")}
                />
                {methods.formState.errors.password && (
                  <p className="text-red-500 text-xs">
                    {methods.formState.errors.password.message}
                  </p>
                )}
              </div>
              <div className="grid gap-2">
                <Label htmlFor="confirm-password">Confirm Password</Label>
                <Input
                  id="confirm-password"
                  type="password"
                  placeholder="***********"
                  {...methods.register("confirmPassword")}
                />
                {methods.formState.errors.confirmPassword && (
                  <p className="text-red-500 text-xs">
                    {methods.formState.errors.confirmPassword.message}
                  </p>
                )}
              </div>
              <Button type="submit" className="w-full">
                Sign Up
              </Button>
            </form>
          </FormProvider>
          <div className="mt-4 text-center text-sm">
            Already have an account?{" "}
            <Link to="/sign-in" className="underline">
              Sign in
            </Link>
          </div>
        </div>
      </div>
      <div className="hidden bg-muted lg:block">
        <div
          className="relative z-20 mt-auto bg-cover bg-center bg-no-repeat h-screen"
          style={{ backgroundImage: `url(${SignInImage})` }}
        ></div>
      </div>
    </div>
  );
}
