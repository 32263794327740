import React, { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import moment from "moment";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Input } from "../ui/input";
import { FormItem, FormLabel, FormControl, FormMessage } from "../ui/form";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css"; // Ensure correct CSS import

interface DateControllerProps {
  name: string;
  label: string;
  placeholder?: string;
  defaultValue?: string; // Date in "MM/DD/YYYY" format
  validation?: {
    required?: string;
    min?: { value: string; message: string }; // Date in "MM/DD/YYYY" format
    max?: { value: string; message: string }; // Date in "MM/DD/YYYY" format
    noFutureDates?: boolean; // Option to prevent future dates
  };
  infoMessage?: string;
  showDuration?: boolean;
  onChange?: (value: any) => void;
  disabled?: boolean; // Added disabled prop
  client?: any;
}

const DateController: React.FC<DateControllerProps> = ({
  name,
  label,
  placeholder = "MM/DD/YYYY",
  defaultValue = "", // Default value as an empty string
  validation = {},
  infoMessage,
  showDuration,
  onChange,
  disabled = false, // Default value for disabled prop
  client,
}) => {
  const { control, setValue, setError, clearErrors } = useFormContext();
  const [internalValue, setInternalValue] = useState<string>(defaultValue);
  const [localError, setLocalError] = useState<string | null>(null);

  useEffect(() => {
    if (defaultValue) {
      const formattedValue = moment(defaultValue, "YYYY-MM-DD").format(
        "MM/DD/YYYY"
      );
      setInternalValue(formattedValue);
      setValue(name, formattedValue);
    } else {
      setInternalValue(defaultValue);
    }
  }, [defaultValue, setValue, name]);

  const validateDate = (dateString: string) => {
    const date = moment(dateString, "MM/DD/YYYY", true);
    if (!date.isValid()) {
      return "Invalid date format";
    }
    if (validation.noFutureDates && date.isAfter(moment())) {
      return "Date cannot be in the future";
    }
    if (
      validation.min &&
      date.isBefore(moment(validation.min.value, "MM/DD/YYYY"))
    ) {
      return validation.min.message;
    }
    if (
      validation.max &&
      date.isAfter(moment(validation.max.value, "MM/DD/YYYY"))
    ) {
      return validation.max.message;
    }
    return true;
  };

  const formatDate = (value: string): string => {
    const cleanedValue = value.replace(/\D/g, "");
    const length = cleanedValue.length;

    if (length < 3) return cleanedValue;
    if (length < 5)
      return `${cleanedValue.slice(0, 2)}/${cleanedValue.slice(2)}`;
    return `${cleanedValue.slice(0, 2)}/${cleanedValue.slice(
      2,
      4
    )}/${cleanedValue.slice(4, 8)}`;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const formattedValue = formatDate(rawValue);
    setInternalValue(formattedValue);
    handleValidation(formattedValue);
  };

  const handleInputBlur = () => {
    handleValidation(internalValue);
    if (onChange) {
      onChange(internalValue);
    }
    setValue(name, internalValue); // Update the form state
  };

  const calculateYearsOfService = (dateString: string): number => {
    const date = moment(dateString, "MM/DD/YYYY");
    const now = moment();
    const years = now.diff(date, "years");
    return years;
  };

  const handleDateSelect = (date: Date | undefined) => {
    const formattedDate = date ? moment(date).format("MM/DD/YYYY") : "";
    setInternalValue(formattedDate);
    handleValidation(formattedDate);
    setValue(name, formattedDate); // Update the form state
    if (onChange) {
      onChange(formattedDate);
    }
  };

  const handleValidation = (value: string) => {
    const validationResult = validateDate(value);
    if (validationResult === true) {
      clearErrors(name);
      setLocalError(null);
    } else {
      setError(name, { type: "manual", message: validationResult });
      setLocalError(validationResult);
    }
  };

  const calculateDuration = (dateString: string) => {
    const date = moment(dateString, "MM/DD/YYYY");
    const now = moment();
    const duration = moment.duration(now.diff(date));
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    // console.log(years, client?.yearsIncomeSsi, 9999);
    // if (client?.yearsIncomeSsi === 0) client?.yearsIncomeSsi = years;
    return `${years} years ${months} months ${days} days`;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...validation,
        validate: validateDate,
      }}
      defaultValue={defaultValue}
      render={({
        field: { onChange: fieldOnChange, ...fieldProps },
        fieldState: { error },
      }) => (
        <FormItem className="mb-6">
          <FormLabel htmlFor={name}>{label}</FormLabel>
          <FormControl>
            <div className="flex">
              <span className="flex justify-center items-center w-10 h-10 bg-gray-300 text-white rounded mr-1">
                D
              </span>
              <Input
                {...fieldProps}
                id={name}
                type="text"
                placeholder={"MM/DD/YYYY" || placeholder}
                value={internalValue}
                onChange={(e) => {
                  handleInputChange(e);
                  fieldOnChange(e.target.value);
                }}
                onBlur={handleInputBlur}
                disabled={disabled} // Pass disabled prop to Input
              />
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" className="ml-2">
                    <CalendarIcon className="h-4 w-4" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <DayPicker
                    mode="single"
                    selected={
                      internalValue
                        ? moment(internalValue, "MM/DD/YYYY").toDate()
                        : undefined
                    }
                    onSelect={handleDateSelect}
                    initialFocus
                    captionLayout="dropdown"
                    fromYear={1930}
                    toYear={new Date().getFullYear()}
                  />
                </PopoverContent>
              </Popover>
            </div>
          </FormControl>
          {showDuration && internalValue && (
            <FormMessage className="mt-2 font-sm text-white p-2 bg-accent rounded">
              {calculateDuration(internalValue)}
            </FormMessage>
          )}
          <FormMessage
            className={
              error || localError
                ? "text-red-500"
                : "text-gray-500 text-xs font-normal"
            }
          >
            {error ? error.message : localError ? localError : infoMessage}
          </FormMessage>
        </FormItem>
      )}
    />
  );
};

export default DateController;
