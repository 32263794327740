import * as React from "react";
import { cn } from "../../utils";
import { Icons } from "../../utils/icons";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import XanoAPI from "../../lib/XanoAPI";
import { z } from "zod";
import { useToast } from "../../components/ui/use-toast";

// Define a schema for the form data
const loginSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  password: z.string().min(1, { message: "Password is required" }),
});

interface SignInFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function UserAuthForm({ className, ...props }: SignInFormProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const api = XanoAPI.getInstance();
  const { toast } = useToast();

  async function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const data = { email, password };
      loginSchema.parse(data);
      await api.login(data);
      setIsLoading(false);
      await api.getCurrentUser();
      window.location.replace("/");
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.error("Validation errors:", error.errors);
        toast({
          title: "Validation Error",
          description: error.errors.map((e) => e.message).join(", "),
          // status: "error",
        });
      } else {
        console.error("Submission error:", error);
        toast({
          title: "Login Error",
          description: "There was an error logging in. Please try again.",
          // status: "error",
        });
      }
      setIsLoading(false);
    }
  }

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <form onSubmit={onSubmit}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="" htmlFor="email">
              Email
            </Label>
            <Input
              name="email"
              id="email"
              placeholder="Your email address ..."
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isLoading}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="grid gap-1">
            <Label className="" htmlFor="password">
              Password
            </Label>
            <Input
              name="password"
              id="password"
              type="password"
              placeholder="Your password ..."
              autoCapitalize="none"
              autoCorrect="off"
              disabled={isLoading}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <Button disabled={isLoading}>
            {isLoading && (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            )}
            Sign In with Email
          </Button>
        </div>
      </form>
    </div>
  );
}
