import FooterMenu from "./footer-menu";
import BackToTopButton from "../../components/clients/components/back-to-top";

export default function Footer() {
  return (
    <div className="border-t flex fixed bottom-0 w-screen bg-white">
      <div className="flex h-16 items-center space-around px-2 space-y-8">
        <p className="text-slate-400 text-sm">
          &copy; {new Date().getFullYear()} Retire Planning Max. All Rights
          Reserved.
        </p>

        <div className="ml-auto flex items-center space-x-4">
          {/* <FooterMenu /> */}
          <BackToTopButton />
        </div>
      </div>
    </div>
  );
}
