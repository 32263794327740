import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "../../../components/ui/table";
import SelectController from "../../form-fields/SelectController";
import SwitchController from "../../form-fields/SwitchController";
import DateController from "../../form-fields/DateController";
import FegliCodes from "./fegli-codes";
import { Button } from "../../../components/ui/button";

interface FEGLIClientProps {
  client: ClientType;
}

const FEGLIClient: React.FC<FEGLIClientProps> = ({ client }) => {
  const { getValues, setValue, watch } = useFormContext();
  const [showFegliCodes, setShowFegliCodes] = useState(false);

  // Set default values from the client prop
  useEffect(() => {
    if (client) {
      (Object.keys(client) as Array<keyof ClientType>).forEach((key) => {
        setValue(key as string, client[key]);
      });
    }
  }, [client, setValue]);

  const fegliOptionB = watch("fegli_optionB");
  const fegliOptionC = watch("fegli_optionC");

  const yearsOptions = Array.from({ length: 60 }, (_, i) => ({
    value: (i + 1).toString(),
    label: (i + 1).toString(),
  }));

  const formatDate = (date: string) => {
    const d = new Date(date);
    return isNaN(d.getTime()) ? "N/A" : d.toLocaleDateString("en-US");
  };

  const formatPercentage = (value: number | undefined) => {
    return value !== undefined ? `${value.toFixed(2)}%` : "N/A";
  };

  const formatCurrency = (value: number | undefined) => {
    return value !== undefined
      ? `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
      : "N/A";
  };

  return (
    <Card className="mb-8" id="fegli-client">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">
          FEGLI Client - Personal Information
        </h4>
      </CardHeader>
      <CardContent>
        <a
          onClick={() => setShowFegliCodes((prev) => !prev)}
          className="inline-block px-3 py-1 text-white bg-accent rounded cursor-pointer mb-4"
        >
          {showFegliCodes ? "Hide FEGLI Codes" : "Show FEGLI Codes"}
        </a>

        {showFegliCodes && <FegliCodes />}
        <Table>
          <TableHeader>
            <TableRow className="bg-gray-100">
              <TableHead>Field</TableHead>
              <TableHead>Value</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="font-semibold">Date of Birth</TableCell>
              <TableCell>
                {client.dateOfBirth ? formatDate(client.dateOfBirth) : "N/A"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">Retire Age</TableCell>
              <TableCell>{client.targetRetirementAge || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">Retire Month</TableCell>
              <TableCell>{client.targetRetirementMonth || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">Pay Per Year</TableCell>
              <TableCell>{client.payPeriodsAnnual || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">Raises</TableCell>
              <TableCell>
                {formatPercentage(client.expectedAnnualRaise)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">
                Income Per Pay Period
              </TableCell>
              <TableCell>{formatCurrency(client.grossIncome)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h4 className="text-lg font-semibold text-gray-700 mt-8">
          FEGLI Insurance Coverage
        </h4>
        <div className="space-y-4 mb-6">
          <SwitchController
            name="fegli_basicCoverage"
            label="Basic Coverage"
            defaultValue={client.fegli_basicCoverage || false}
            infoMessage="Toggle to enable or disable basic coverage."
          />
          <SwitchController
            name="fegli_postalEmployee"
            label="Postal Employee"
            defaultValue={client.fegli_postalEmployee || false}
            infoMessage="Toggle if you are a postal employee."
          />
          <SelectController
            name="fegli_reductionAtAge65"
            label="Reduction At Age 65"
            options={[
              { value: "no_reduction", label: "No Reduction" },
              { value: "50_percent_reduction", label: "50% Reduction" },
              { value: "75_percent_reduction", label: "75% Reduction" },
            ]}
            defaultValue={client.fegli_reductionAtAge65 || "no_reduction"}
            infoMessage="Select the reduction at age 65."
          />
          <SwitchController
            name="fegli_optionA"
            label="Option A"
            defaultValue={client.fegli_optionA || false}
            infoMessage="Toggle to enable or disable Option A."
          />
          <div className="flex items-center space-x-4">
            <SwitchController
              name="fegli_optionB"
              label="Option B"
              defaultValue={client.fegli_optionB || false}
              infoMessage="Toggle to enable or disable Option B."
            />
            {fegliOptionB && (
              <>
                <SelectController
                  name="fegli_optionBMultiplier"
                  label="Multiplier"
                  options={[
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "3", label: "3" },
                    { value: "4", label: "4" },
                    { value: "5", label: "5" },
                  ]}
                  defaultValue={
                    client.fegli_optionBMultiplier?.toString() || "1"
                  }
                  infoMessage="Select the multiplier for Option B."
                />
                <SelectController
                  name="fegli_optionBAge65Reduction"
                  label="Age 65 Multiplier Reduction"
                  options={[
                    { value: "none", label: "None" },
                    { value: "partial", label: "Partial" },
                    { value: "full", label: "Full" },
                  ]}
                  defaultValue={client.fegli_optionBAge65Reduction || "none"}
                  infoMessage="Select the age 65 multiplier reduction for Option B."
                />
              </>
            )}
          </div>
          <div className="flex items-center space-x-4">
            <SwitchController
              name="fegli_optionC"
              label="Option C"
              defaultValue={client.fegli_optionC || false}
              infoMessage="Toggle to enable or disable Option C."
            />
            {fegliOptionC && (
              <>
                <SelectController
                  name="fegli_optionCMultiplier"
                  label="Multiplier"
                  options={[
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "3", label: "3" },
                    { value: "4", label: "4" },
                    { value: "5", label: "5" },
                  ]}
                  defaultValue={
                    client.fegli_optionCMultiplier?.toString() || "1"
                  }
                  infoMessage="Select the multiplier for Option C."
                />
                <SwitchController
                  name="fegli_optionCIncludeSpouse"
                  label="Include Spouse"
                  defaultValue={client.fegli_optionCIncludeSpouse || false}
                  infoMessage="Toggle to include spouse in Option C."
                />
                <SelectController
                  name="fegli_optionCAge65Reduction"
                  label="Age 65 Multiplier Reduction"
                  options={[
                    { value: "none", label: "None" },
                    { value: "partial", label: "Partial" },
                    { value: "full", label: "Full" },
                  ]}
                  defaultValue={client.fegli_optionCAge65Reduction || "none"}
                  infoMessage="Select the age 65 multiplier reduction for Option C."
                />
              </>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default FEGLIClient;
