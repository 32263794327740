import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider } from "react-hook-form";
import * as z from "zod";
import React, { useState } from "react";
import { Button } from "../../ui/button";
import { toast } from "../../../components/ui/use-toast";
import { useAuth } from "../../../lib/AuthContext";
import XanoAPI from "../../../lib/XanoAPI";
import TextController from "../../form-fields/TextController";
import SwitchController from "../../form-fields/SwitchController"; // Import the SwitchController

// Validation schema for two-factor authentication form
const twoFactorFormSchema = z.object({
  twoFactorCode: z.string().min(6, {
    message: "Two-Factor Code must be at least 6 characters.",
  }),
  enable2FA: z.boolean(),
});

type TwoFactorFormValues = z.infer<typeof twoFactorFormSchema>;

export function TwoFactorAuthForm() {
  const { currentUser } = useAuth();
  const api = XanoAPI.getInstance();
  const [loading, setLoading] = useState(false);

  // Form for two-factor authentication
  const twoFactorForm = useForm<TwoFactorFormValues>({
    resolver: zodResolver(twoFactorFormSchema),
    defaultValues: {
      twoFactorCode: "",
      enable2FA: false,
    },
  });

  async function handle2FASubmit(data: TwoFactorFormValues) {
    setLoading(true);
    try {
      // Replace this with actual 2FA API calls (e.g., enabling/disabling 2FA)
      if (data.enable2FA) {
        // await api.enable2FA(currentUser.id, data.twoFactorCode);
        toast({
          title: "2FA Enabled",
          description: "Two-Factor Authentication has been enabled.",
        });
      } else {
        // await api.disable2FA(currentUser.id);
        toast({
          title: "2FA Disabled",
          description: "Two-Factor Authentication has been disabled.",
        });
      }
    } catch (error) {
      console.error("Error enabling/disabling 2FA:", error);
      toast({
        title: "Update Failed",
        description: "There was an error updating 2FA. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <FormProvider {...twoFactorForm}>
      <div className="space-y-6">
        <h2 className="text-xl font-semibold text-accent mb-4">
          Two Factor Authentication
        </h2>
        <form
          onSubmit={twoFactorForm.handleSubmit(handle2FASubmit)}
          className="space-y-6"
        >
          <TextController
            name="twoFactorCode"
            label="Two-Factor Authentication Code"
            placeholder="Enter the 6-digit code"
            infoMessage="Enter the 6-digit code generated by your 2FA app."
            validation={{
              required: "Two-Factor Code is required.",
              minLength: {
                value: 6,
                message: "Two-Factor Code must be 6 digits.",
              },
            }}
          />

          <SwitchController
            name="enable2FA"
            label="Enable Two-Factor Authentication"
            defaultValue={false}
            infoMessage="Toggle to enable or disable Two-Factor Authentication"
          />

          <Button type="submit" className="bg-accent" disabled={loading}>
            {loading ? "Updating..." : "Update 2FA Settings"}
          </Button>
        </form>
      </div>
    </FormProvider>
  );
}
