import React, { useState, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Input } from "../../components/ui/input";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "../../components/ui/form";

interface AmountControllerProps {
  name: string;
  label: string;
  placeholder: string;
  defaultValue?: string | number;
  fixed?: string;
  validation?: {
    required?: string;
  };
  infoMessage?: string;
  onChange?: (value: any) => void;
}

const AmountController: React.FC<AmountControllerProps> = ({
  name,
  label,
  placeholder,
  defaultValue,
  fixed = "2",
  validation = {},
  infoMessage,
  onChange,
}) => {
  const { control, setValue } = useFormContext();
  const [internalValue, setInternalValue] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (defaultValue !== undefined) {
      const formattedValue = formatCurrencyValue(defaultValue);
      setInternalValue(formattedValue);
      setValue(name, parseValue(formattedValue)); // Set initial form value
    }
  }, [defaultValue, setValue, name]);

  const formatCurrencyValue = (value: number | string): string => {
    const num = typeof value === "string" ? parseFloat(value) : value;
    return num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: parseInt(fixed),
      maximumFractionDigits: parseInt(fixed),
    });
  };

  const parseValue = (value: string): number => {
    const cleanedValue = value.replace(/[^0-9.-]+/g, "");
    return parseFloat(cleanedValue) || 0;
  };

  const handleValidation = (value: string): boolean => {
    const parsedValue = parseValue(value);
    // console.log(parsedValue, 999);
    if (isNaN(parsedValue)) {
      setError("Amount must be a number.");
      return false;
    }
    if (parsedValue < 0) {
      setError("Amount must be non-negative.");
      return false;
    }
    setError(null);
    return true;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    setInternalValue(rawValue);
    handleValidation(rawValue);
  };

  const handleInputBlur = () => {
    const parsedValue = parseValue(internalValue);
    const formattedValue = formatCurrencyValue(parsedValue);
    setInternalValue(formattedValue);
    if (handleValidation(internalValue) && onChange) {
      onChange(parsedValue);
    }
    setValue(name, parsedValue); // Update form value
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...validation,
        validate: (value) => handleValidation(formatCurrencyValue(value)),
      }}
      render={({
        field: { onChange: fieldOnChange, value, ...fieldProps },
        fieldState: { error: fieldError },
      }) => (
        <FormItem className="mb-6">
          <FormLabel htmlFor={name}>{label}</FormLabel>
          <FormControl>
            <div className="flex">
              <span className="flex justify-center items-center w-10 h-10 bg-gray-300 text-white rounded mr-1">
                $
              </span>
              <Input
                {...fieldProps}
                id={name}
                type="text"
                placeholder={placeholder}
                value={
                  internalValue ||
                  (defaultValue !== undefined
                    ? formatCurrencyValue(defaultValue)
                    : "$0.00")
                }
                onChange={(e) => {
                  handleInputChange(e);
                  const parsedValue = parseValue(e.target.value);
                  fieldOnChange(parsedValue);
                }}
                onBlur={handleInputBlur}
              />
            </div>
          </FormControl>
          <FormMessage
            className={
              fieldError || error
                ? "text-red-500"
                : "text-gray-500 text-xs font-normal"
            }
          >
            {fieldError ? fieldError.message : error ? error : infoMessage}
          </FormMessage>
        </FormItem>
      )}
    />
  );
};

export default AmountController;
