import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import XanoAPI from "../../lib/XanoAPI";
import { Button } from "../../components/ui/button";

export function ExportToCsv({ id }: any) {
  const api = XanoAPI.getInstance();

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button className="bg-accent">Export to CSV/Excel</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Export Data</AlertDialogTitle>
          <AlertDialogDescription>
            Export the current selections as a CSV/Excel
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction>Yes, please!</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
