import React from "react";
import { useFormContext } from "react-hook-form";
import TextController from "../../form-fields/TextController";
import PhoneNumberController from "../../form-fields/PhoneNumberController";
import SelectController from "../../form-fields/SelectController";
import DateController from "../../form-fields/DateController";
import IntegerController from "../../form-fields/IntegerController"; // For dependents
import { getStates } from "../../../lib/utils";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";

export default function PersonalInformationComponent({
  client,
}: {
  client: ClientType;
}) {
  const { control } = useFormContext();

  return (
    <Card className="mb-8 shadow" id="personal-information">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">
          Personal Information
        </h4>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8">
          <div className="my-2">
            <TextController
              name="firstName"
              label="First Name"
              placeholder="Enter first name"
              defaultValue={client?.firstName || ""}
              validation={{ required: "First name is required." }}
              infoMessage="Please enter your first name."
              capitalize={true}
            />
          </div>
          <div className="my-2">
            <TextController
              name="lastName"
              label="Last Name"
              defaultValue={client?.lastName || ""}
              placeholder="Enter last name"
              validation={{ required: "Last name is required." }}
              infoMessage="Please enter your last name."
              capitalize={true}
            />
          </div>
          {/* Gender Field */}
          <div className="my-2">
            <SelectController
              name="gender"
              label="Gender"
              options={[
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
              ]}
              defaultValue={client?.gender || "male"}
              infoMessage="Please select your gender."
            />
          </div>
          <div className="my-2">
            <PhoneNumberController
              name="phoneNumber"
              label="Phone Number"
              placeholder="Enter phone number"
              defaultValue={client?.phoneNumber || ""}
              validation={{
                required: "Phone Number is required.",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Phone number must only contain digits.",
                },
              }}
              infoMessage="Please enter your phone number."
            />
          </div>
          <div className="my-2">
            <SelectController
              name="stateProvince"
              label="State / Province"
              options={getStates()}
              defaultValue={client?.stateProvince || ""}
              infoMessage="Please enter the state or province of your client's residence."
            />
          </div>
          <div className="my-2">
            <DateController
              name="dateOfBirth"
              label="Date of Birth"
              placeholder="YYYY-MM-DD"
              defaultValue={client?.dateOfBirth || ""}
              infoMessage="Please enter your client's date of birth."
              validation={{
                min: {
                  value: "01/01/1900",
                  message: "Date cannot be before 01/01/1900",
                },
                max: {
                  value: "12/31/2023",
                  message: "Date cannot be after 12/31/2023",
                },
                noFutureDates: true,
              }}
            />
          </div>
          <div className="my-2">
            <SelectController
              name="type"
              label="Account Type"
              options={[
                { label: "Federal", value: "federal" },
                { label: "401k", value: "401k" },
              ]}
              defaultValue={client.type || "federal"}
              infoMessage="Please enter your client's type of account."
            />
          </div>

          {/* Residence Type (Homeowner / Renter) */}
          <div className="my-2">
            <SelectController
              name="residenceType"
              label="Residence Type"
              options={[
                { label: "Homeowner", value: "homeowner" },
                { label: "Renter", value: "renter" },
              ]}
              defaultValue={client?.residenceType || "homeowner"}
              infoMessage="Please select if you are a homeowner or a renter."
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
