import React, { useEffect, useState } from "react";
import AuditLogTable from "./logs/audit-log-table";

// Sample mock data for the audit logs
const mockAuditLogs = [
  {
    name: "John Doe",
    ip: "192.168.1.10",
    location: "San Francisco, USA",
    timestamp: "2023-09-12T08:30:00Z",
  },
  {
    name: "Jane Smith",
    ip: "192.168.1.11",
    location: "London, UK",
    timestamp: "2023-09-11T12:45:00Z",
  },
  {
    name: "Michael Brown",
    ip: "192.168.1.12",
    location: "Berlin, Germany",
    timestamp: "2023-09-10T14:15:00Z",
  },
];

const AuditLog: React.FC = () => {
  const [auditLogs, setAuditLogs] = useState(mockAuditLogs);

  // Simulate fetching audit logs from an API
  useEffect(() => {
    // In real scenario, replace with API call
    // e.g., fetchAuditLogs().then((data) => setAuditLogs(data));
  }, []);

  return (
    <div className="py-6">
      <h2 className="text-xl font-semibold text-accent mb-4">Audit Log</h2>
      <AuditLogTable logs={auditLogs} />
    </div>
  );
};

export default AuditLog;
