import { Icons } from "../../utils/icons";
// import Logo from "../../images/logo.png";

const Loader: React.FC = () => {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className="h-32 w-32 flex flex-col justify-center items-center">
        {/* <img src={Logo} alt="logo" className="h-16 w-16 mb-4" /> */}
        <Icons.spinner className="h-6 w-6 animate-spin" />
      </div>
    </div>
  );
};

export default Loader;
