import { BadgeAlert } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";

export function ComparisonsAlert() {
  return (
    <Alert>
      <BadgeAlert className="h-4 w-4" />
      <AlertTitle>Warning</AlertTitle>
      <AlertDescription>
        Please fill in Pretax Current Balance in order to show comparisons
      </AlertDescription>
    </Alert>
  );
}
