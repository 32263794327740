import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../lib/AuthContext";
import XanoAPI from "../lib/XanoAPI";
import Logo from "../images/logo.png";
import { Icons } from "./icons";

const LoadingSpinner: React.FC = () => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="h-32 w-32 flex flex-col justify-center items-center">
        <img src={Logo} alt="logo" className="h-16 w-16 mb-4" />
        <Icons.spinner className="mr-2 h-6 w-6 animate-spin center" />
      </div>
    </div>
  );
};

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated, isLoading } = useAuth();
  const api = XanoAPI.getInstance();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated) {
    api.signOut();
    // return <Navigate to="/sign-in" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
