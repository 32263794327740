import React, { useState, useEffect, useCallback, useRef } from "react";
import { Icons } from "../../../utils/icons";
import { Button } from "../../ui/button";
import XanoAPI from "../../../lib/XanoAPI";
import AdditionalIncomeAndDeductions from "../../charts/additional-income-and-deductions";
import TSPBalanceOverTime from "../../charts/tsp-balance-over-time";

interface ResultsProps {
  client: ClientType;
}

const Placeholder: React.FC = () => (
  <div className="flex justify-center items-center h-full border border-gray-200 rounded p-4">
    <p className="text-gray-500">
      No PDF available. Please regenerate a report.
    </p>
  </div>
);

const ErrorMessage: React.FC<{ message: string }> = ({ message }) => (
  <div className="flex justify-center items-center h-full border border-red-500 rounded p-4 bg-red-100">
    <p className="text-red-500">{message}</p>
  </div>
);

const Results: React.FC<ResultsProps> = ({ client }) => {
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [retrying, setRetrying] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [iframeKey, setIframeKey] = useState<number>(0); // Force re-rendering with key
  const [retryCount, setRetryCount] = useState<number>(0);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  // const handleRegenerate = async () => {
  //   try {
  //     setLoading(true);
  //     setError(""); // Clear any previous error
  //     setRetryCount(0); // Reset retry count
  //     const xano = XanoAPI.getInstance();
  //     if (client && client.id) {
  //       const newPdfUrl = await xano.generateAgentReport(client.id);
  //       console.log("New PDF URL:", newPdfUrl);
  //       setPdfUrl(newPdfUrl);
  //       setIframeKey((prevKey) => prevKey + 1); // Force iframe re-render
  //     }
  //   } catch (error) {
  //     console.error("Error regenerating PDF:", error);
  //     setError("Error regenerating PDF. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleRegenerate = async () => {
    try {
      setLoading(true);
      setError(""); // Clear any previous error
      setRetryCount(0); // Reset retry count
      const xano = XanoAPI.getInstance();
      if (client && client.id) {
        const newPdfUrl = await xano.generateAgentReport(client.id);
        console.log("New PDF URL:", newPdfUrl);
        setPdfUrl(newPdfUrl);
        setIframeKey((prevKey) => prevKey + 1); // Force iframe re-render
      }
    } catch (error) {
      console.error("Error regenerating PDF:", error);
      setError("Error regenerating PDF. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleExport = () => {
    window.open(pdfUrl, "_blank");
  };

  const checkIframeContent = useCallback(() => {
    if (iframeRef.current) {
      const iframeDoc = iframeRef.current.contentDocument;
      if (
        iframeDoc &&
        iframeDoc.body &&
        iframeDoc.body.innerHTML.trim() === ""
      ) {
        console.log("Iframe content is empty");
        return false; // The iframe is empty
      }
      console.log("Iframe content is loaded");
    }
    return true; // The iframe has content
  }, []);

  useEffect(() => {
    if (pdfUrl && retryCount < 10 && !checkIframeContent()) {
      setRetrying(true);
      const intervalId = setInterval(() => {
        if (!checkIframeContent()) {
          console.log(`Retry attempt ${retryCount + 1}`);
          setIframeKey((prevKey) => prevKey + 1); // Force iframe re-render
          setRetryCount((prevCount) => prevCount + 1);
        } else {
          setRetrying(false);
          clearInterval(intervalId);
        }
      }, 3000); // Retry every 3 seconds

      return () => clearInterval(intervalId);
    } else {
      setRetrying(false);
    }
  }, [pdfUrl, retryCount, checkIframeContent]);

  const officeViewerUrl = `https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(
    pdfUrl
  )}`;

  return (
    <div className="py-6">
      <h3 className="pb-4 font-bold text-2xl text-accent">Results</h3>
      <div className="mb-4 py-4 w-full">
        {/* <AdditionalIncomeAndDeductions client={client} /> */}
        <TSPBalanceOverTime client={client} />
      </div>

      <div className="w-full">
        <AdditionalIncomeAndDeductions client={client} />
        {/* <TSPBalanceOverTime client={client} /> */}
      </div>
      <div className="py-6">
        {loading ? (
          <div className="flex justify-center items-center h-[800px]">
            <p className="text-gray-500">Loading...</p>
          </div>
        ) : error ? (
          <ErrorMessage message={error} />
        ) : pdfUrl ? (
          <iframe
            ref={iframeRef}
            key={iframeKey} // Force re-render by using key
            src={officeViewerUrl}
            width="100%"
            height="800px"
            frameBorder={0}
            onLoad={() => console.log("Iframe loaded")}
          ></iframe>
        ) : (
          <Placeholder />
        )}
      </div>

      <div className="fixed bottom-0 right-0 z-50 mr-2">
        <Button
          type="button"
          className="my-2 mx-2 bg-accent"
          onClick={handleRegenerate}
          disabled={loading} // Disable while loading or retrying
        >
          <Icons.spinner className="mr-2" />
          {loading ? "Regenerating..." : "Regenerate PDF"}
        </Button>
        <Button
          type="button"
          className="my-3 bg-accent"
          onClick={handleExport}
          disabled={!pdfUrl}
        >
          <Icons.results className="mr-2" />
          Export PDF
        </Button>
      </div>
    </div>
  );
};

export default Results;
