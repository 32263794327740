import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import XanoAPI from "../../../lib/XanoAPI";
import { useToast } from "../../../components/ui/use-toast";
// form components
import PersonalInformationComponent from "../components/personal-information";
import FamilyInformationComponent from "../components/family-information";
import RetirementInformationComponent from "../components/retirement-information";
import SavingsInformationComponent from "../components/savings-information";
import ItemsComponent from "../../form-fields/ItemsComponent";
// ui components
import { Icons } from "../../../utils/icons";
import { Button } from "../../../components/ui/button";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import SelectController from "../../form-fields/SelectController";
import { getStates } from "../../../lib/utils";

// Initialize Xano API instance
const api = XanoAPI.getInstance();

// Define the form schema using zod
const formSchema = z.object({
  user_id: z.number(),
  firstName: z
    .string()
    .trim()
    .min(1, "First Name is required.")
    .min(2, "First Name must be at least 2 characters."),
  lastName: z
    .string()
    .trim()
    .min(1, "Last Name is required.")
    .min(2, "Last Name must be at least 2 characters."),
  phoneNumber: z
    .string()
    .trim()
    .min(10, "Phone number must be at least 10 digits.")
    .regex(/^\d+$/, "Phone number should only contain digits"),
  stateProvince: z
    .string()
    .trim()
    .min(1, "State/Province is required.")
    .min(2, "State/Province must be at least 2 characters."),
  type: z.enum(["federal", "401k"], {
    required_error: "Type is required",
  }),
  dateOfBirth: z.string().optional(),
  spousesName: z.string().optional(),
  spousesDateOfBirth: z.string().nullable().optional(),
  targetRetirementAge: z.number().optional(),
  targetRetirementMonth: z.string().optional(),
  lifeExpectancyAge: z.number().optional(),
  interestRatePriorToRetirement: z.number().optional(),
  interestRateInRetirement: z.number().optional(),
  annuallyIncreaseSavingsWithRaises: z.boolean().optional(),
  additionalIncome: z.string().optional(),
  deductions: z.string().optional(),
  gender: z.enum(["male", "female"], {
    required_error: "Gender is required",
  }),
  dependents: z.number().min(0, "Dependents cannot be negative").optional(),
  residenceType: z.enum(["homeowner", "renter"], {
    required_error: "Residence Type is required",
  }),
});

type FormData = z.infer<typeof formSchema>;

export default function PersonalInformation({
  client,
  onUpdate,
  setFormsDirty,
  fetchClientData,
  resetFormsDirtyState,
  formsDirty,
}: {
  client: ClientType;
  onUpdate: Function;
  setFormsDirty: Function;
  fetchClientData: Function;
  resetFormsDirtyState: Function;
  formsDirty: any;
}) {
  const { toast } = useToast();

  useEffect(() => {
    onUpdate(); // Update form on component mount
  }, []);

  // Initialize react-hook-form
  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      user_id: api.getUserId(),
      firstName: client.firstName ?? "",
      lastName: client.lastName ?? "",
      phoneNumber: client.phoneNumber ?? "",
      stateProvince: client.stateProvince ?? "",
      type: client.type ?? "federal", // Default value for type
      dateOfBirth: client.dateOfBirth ?? "",
      spousesName: client.spousesName ?? "",
      spousesDateOfBirth: client.spousesDateOfBirth ?? "",
      targetRetirementAge: client.targetRetirementAge ?? 0,
      targetRetirementMonth: client.targetRetirementMonth ?? "",
      lifeExpectancyAge: client.lifeExpectancyAge ?? 0,
      interestRatePriorToRetirement: client.interestRatePriorToRetirement ?? 0,
      interestRateInRetirement: client.interestRateInRetirement ?? 0,
      annuallyIncreaseSavingsWithRaises:
        client.annuallyIncreaseSavingsWithRaises ?? false,

      // Default values for the new fields
      gender: client.gender ?? "male", // Default to "male" if not provided
      dependents: client.dependents ?? 0, // Default to 0 dependents
      residenceType: client.residenceType ?? "homeowner", // Default to "homeowner"
    },
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
  } = form;

  if (!client) return null;

  const onSave = (items: string, field: any): void => {
    if (items) setValue(field, JSON.stringify({ values: items }));
  };

  const onSubmit = async (data: FormData) => {
    if (!client || !client.id) {
      console.error("Client ID is missing.");
      return;
    }

    const processedData = { ...client, ...data };

    try {
      const updatedClient = await api.updateClient(
        client.id.toString(),
        processedData
      );

      fetchClientData(client.id, 2000);
      toast({
        title: "Personal Details Updated!",
        description: "Your client's personal information has been updated.",
      });
      console.log("Client updated successfully", updatedClient);
    } catch (error) {
      console.error("Error updating client:", error);
      if (error instanceof Error && error.message.includes("401")) {
        api.signOut();
        console.log("Redirecting to sign-in due to unauthorized access.");
      }
    }
  };

  return (
    <div className="py-6 mb-8">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PersonalInformationComponent client={client} />
          <FamilyInformationComponent client={client} />
          <RetirementInformationComponent client={client} />
          <SavingsInformationComponent client={client} />

          {/* SelectController for Type Field */}
          {/* <Card className="mb-8">
            <CardHeader>
              <h4 className="text-lg font-semibold text-gray-700">
                Account Type
              </h4>
            </CardHeader>
            <CardContent>
              <SelectController
                name="type"
                label="Account Type"
                options={[
                  { label: "Federal", value: "federal" },
                  { label: "401k", value: "401k" },
                ]}
                defaultValue={client.type || "federal"} // Default to "federal" if not provided
              />
            </CardContent>
          </Card> */}

          <div className="fixed bottom-0 right-0 m-3 z-50">
            <Button
              type="submit"
              className="mt-5 p-3 bg-accent"
              disabled={!isValid}
            >
              <Icons.save className="mr-2" />
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
