import { TrendingUp } from "lucide-react";
import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";

import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart";

interface ChartProps {
  client: ClientType;
}

// Helper function to parse and calculate annualized totals
const calculateAnnualizedTotal = (jsonString: string | undefined): number => {
  if (!jsonString) {
    console.warn("JSON string is undefined or null.");
    return 0;
  }

  try {
    const parsedData = JSON.parse(jsonString);

    if (parsedData && parsedData.values && parsedData.values.length > 0) {
      return JSON.parse(parsedData.values).reduce(
        (total: number, item: any) => {
          if (
            item &&
            typeof item.cost === "number" &&
            item.frequency &&
            item.frequency === "monthly"
          ) {
            const annualizedCost = item.cost * 12;
            return total + annualizedCost;
          } else {
            console.warn(
              "Item structure or frequency type is unexpected:",
              item
            );
          }
          return total;
        },
        0
      );
    } else {
      console.warn("Parsed data structure is not as expected:", parsedData);
    }
  } catch (error) {
    console.error("Error parsing JSON string:", error);
  }

  return 0;
};

export default function AdditionalIncomeAndDeductions({ client }: ChartProps) {
  // Calculate the annualized totals for additional income and deductions
  const additionalIncome = calculateAnnualizedTotal(client.additionalIncome);
  const deductions = calculateAnnualizedTotal(client.deductions);

  // Calculate the annualized current contributions
  const currentContributions =
    (client.contributionPerPay || 0) * (client.payPeriodsAnnual || 1);

  // Prepare the data for the chart
  const chartData = [
    {
      category: "Additional Income",
      value: additionalIncome,
    },
    {
      category: "Deductions",
      value: deductions,
    },
    {
      category: "Current Contributions",
      value: currentContributions,
    },
  ];

  // console.log("Chart Data:", chartData); // Debugging log to check data passed to the chart

  const chartConfig = {
    desktop: {
      label: "Category",
      color: "hsl(var(--chart-1))",
    },
  } satisfies ChartConfig;

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-accent">
          Additional Income & Deductions vs Current Contributions
        </CardTitle>
        <CardDescription>Annualized Totals</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart
            accessibilityLayer
            data={chartData}
            margin={{
              top: 20,
            }}
            className="p-4"
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="category"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Bar dataKey="value" fill="var(--color-desktop)" radius={8}>
              <LabelList
                position="top"
                offset={12}
                className="fill-foreground"
                fontSize={12}
              />
            </Bar>
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        {/* <div className="flex gap-2 font-medium leading-none">
          Trending up by 5.2% this month <TrendingUp className="h-4 w-4" />
        </div> */}
        <div className="leading-none text-muted-foreground">
          Showing annualized totals for Additional Income, Deductions, and
          Current Contributions
        </div>
      </CardFooter>
    </Card>
  );
}
