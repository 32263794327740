import React, { useState, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Input } from "../../components/ui/input";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "../../components/ui/form";

interface PercentageControllerProps {
  name: string;
  label: string;
  placeholder: string;
  defaultValue?: string | number;
  fixed?: string;
  validation?: {
    required?: string;
  };
  infoMessage?: string;
  onChange?: (value: any) => void;
}

const PercentageController: React.FC<PercentageControllerProps> = ({
  name,
  label,
  placeholder,
  defaultValue,
  fixed = "2",
  validation = {},
  infoMessage,
  onChange,
}) => {
  const { control, setValue, setError, clearErrors } = useFormContext();
  const [internalValue, setInternalValue] = useState<string>("");
  const [localError, setLocalError] = useState<string | null>(null);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setInternalValue(formatPercentageValue(defaultValue));
      setValue(name, parseValue(defaultValue.toString()));
    }
  }, [defaultValue, setValue, name]);

  const formatPercentageValue = (value: number | string): string => {
    const num = typeof value === "string" ? parseFloat(value) : value;
    return isNaN(num) ? "" : num.toFixed(parseInt(fixed)) + "%";
  };

  const parseValue = (value: string): number => {
    const cleanedValue = value.replace(/[^0-9.-]+/g, "");
    const parsed = parseFloat(cleanedValue);
    return isNaN(parsed) ? 0 : parsed;
  };

  const handleValidation = (value: string): boolean => {
    const parsedValue = parseValue(value);
    if (isNaN(parsedValue)) {
      setLocalError("Percentage must be a number.");
      setError(name, {
        type: "manual",
        message: "Percentage must be a number.",
      });
      return false;
    }
    if (parsedValue < 0 || parsedValue > 100) {
      setLocalError("Percentage must be between 0 and 100.");
      setError(name, {
        type: "manual",
        message: "Percentage must be between 0 and 100.",
      });
      return false;
    }
    setLocalError(null);
    clearErrors(name);
    return true;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    setInternalValue(rawValue);
    handleValidation(rawValue);
  };

  const handleInputBlur = () => {
    const parsedValue = parseValue(internalValue);
    const formattedValue = formatPercentageValue(parsedValue);
    setInternalValue(formattedValue);
    if (handleValidation(internalValue) && onChange) {
      onChange(parsedValue);
    }
    setValue(name, parsedValue); // Update form value
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      render={({
        field: { onChange: fieldOnChange, value, ...fieldProps },
        fieldState: { error: fieldError },
      }) => (
        <FormItem className="mb-6">
          <FormLabel htmlFor={name}>{label}</FormLabel>
          <FormControl>
            <div className="flex">
              <span className="flex justify-center items-center w-10 h-10 bg-gray-300 text-white rounded mr-1">
                %
              </span>
              <Input
                {...fieldProps}
                id={name}
                type="text"
                placeholder={placeholder}
                value={internalValue}
                onChange={(e) => {
                  handleInputChange(e);
                  const value = parseValue(e.target.value);
                  if (!isNaN(value)) {
                    fieldOnChange(value);
                  }
                }}
                onBlur={handleInputBlur}
              />
            </div>
          </FormControl>
          <FormMessage
            className={
              fieldError || localError
                ? "text-red-500"
                : "text-gray-500 text-xs font-normal"
            }
          >
            {fieldError
              ? fieldError.message
              : localError
              ? localError
              : infoMessage}
          </FormMessage>
        </FormItem>
      )}
    />
  );
};

export default PercentageController;
