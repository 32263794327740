import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Card, CardContent, CardHeader } from "../../../../components/ui/card";
import SelectController from "../../../form-fields/SelectController";
import SwitchController from "../../../form-fields/SwitchController";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../../components/ui/tabs";
import PreTaxAccount from "./pre-tax-account";
import RothAccount from "./roth-account";
import Allocations from "./allocations";
import LifeCycleFundsAllocations from "./life-cycle-funds";
import FederalSavingsPlan from "../federal-savings-plan"; // Import the FederalSavingsPlan component

const allocationOptions = [
  { value: "custom_gfcsi", label: "Custom GFCSI" },
  { value: "mixed", label: "Mixed" },
  { value: "l_income", label: "L Income" },
  { value: "l_2025", label: "L 2025" },
  { value: "l_2030", label: "L 2030" },
  { value: "l_2035", label: "L 2035" },
  { value: "l_2040", label: "L 2040" },
  { value: "l_2045", label: "L 2045" },
  { value: "l_2050", label: "L 2050" },
  { value: "l_2055", label: "L 2055" },
  { value: "l_2060", label: "L 2060" },
  { value: "l_2065", label: "L 2065" },
];

const contributionOptions = [
  { value: "percent_of_pay", label: "Percent of Pay" },
  { value: "dollars_per_deposit", label: "Dollars per Deposit" },
  { value: "maximum", label: "Maximum" },
];

const calculationOptions = [
  { value: "historical_data", label: "Historical Data" },
  { value: "user_provided_data", label: "User Provided Data" },
];

interface FederalTSPFormProps {
  client: ClientType;
}

const FederalTSPForm: React.FC<FederalTSPFormProps> = ({ client }) => {
  const { getValues, watch } = useFormContext();

  const includeRothAccount = getValues("includeRothAccount");
  const allocationMethod = getValues("allocationMethod");
  const contributionMethodTSP = watch("contributionMethodTSP");

  React.useEffect(() => {
    // console.log(client, 888);
  }, [client]);

  return (
    <Card className="mb-8" id="federal-tsp">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">Federal TSP</h4>
      </CardHeader>
      <FederalSavingsPlan client={client} />

      <CardContent className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
          <SelectController
            name="allocationMethod"
            label="Allocation Method"
            options={allocationOptions}
            defaultValue={client?.allocationMethod || "l_2065"}
            infoMessage="Choose the method for allocating your TSP."
          />
          <SelectController
            name="contributionMethodTSP"
            label="Contribution Method"
            options={contributionOptions}
            defaultValue={client?.contributionMethodTSP || "percent_of_pay"}
            infoMessage="Choose how contributions are calculated, e.g., as a percentage of pay."
          />
          <SelectController
            name="tspCalculationMethod"
            label="TSP Calculation Method"
            options={calculationOptions}
            defaultValue={client?.tspCalculationMethod || "historical_data"}
            infoMessage="Choose how the calculations should be made, by historical data or by user-provided data."
          />
          <SwitchController
            name="includeRothAccount"
            label="Include Roth Account"
            defaultValue={client?.includeRothAccount || false}
            infoMessage="Specify if you want to include a Roth account in your TSP."
          />
        </div>
        <Tabs defaultValue="pre-tax" className="w-full">
          <TabsList>
            <TabsTrigger value="pre-tax">Pre-Tax Account</TabsTrigger>
            {includeRothAccount && (
              <TabsTrigger value="roth">Roth Account</TabsTrigger>
            )}
            {(allocationMethod === "custom_gfcsi" ||
              allocationMethod === "mixed" ||
              allocationMethod.startsWith("l_")) && (
              <TabsTrigger value="allocation">Allocation</TabsTrigger>
            )}
          </TabsList>
          <TabsContent value="pre-tax">
            <PreTaxAccount
              client={client}
              contributionMethodTSP={contributionMethodTSP}
            />
          </TabsContent>
          {includeRothAccount && (
            <TabsContent value="roth">
              <RothAccount
                client={client}
                contributionMethodTSP={contributionMethodTSP}
              />
            </TabsContent>
          )}
          {(allocationMethod === "custom_gfcsi" ||
            allocationMethod === "mixed" ||
            allocationMethod.startsWith("l_")) && (
            <TabsContent value="allocation">
              <Allocations client={client} />
              <p>&nbsp;</p>
              {allocationMethod === "mixed" && (
                <LifeCycleFundsAllocations client={client} />
              )}
            </TabsContent>
          )}
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default FederalTSPForm;
