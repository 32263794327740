"use client";
import React, { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { cn } from "../../lib/utils";
import { Button } from "../../components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import Cookies from "js-cookie";
import { SidebarClose, SidebarOpen, Menu, ChevronRight } from "lucide-react";
import XanoAPI from "../../lib/XanoAPI";
import AnchorMenuAccordion from "./anchor-menu-accordion";

const FormSchema = z.object({
  clientId: z.string({
    required_error: "Please select a client.",
  }),
});

export default function Sidebar({ activeTab }: any) {
  const [clients, setClients] = useState<ClientType[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    Cookies.get("sidebarState") === "open"
  ); // State to manage sidebar visibility
  const api = XanoAPI.getInstance();

  const FinancialInformationAnchorMenu = [
    {
      name: "Current Income",
      id: "#current-income",
    },
    {
      name: "Federal Retirment Plan",
      id: "#federal-retirement-plan",
    },
    {
      name: "Social Security",
      id: "#social-security",
    },
    {
      name: "Federal Information",
      id: "#federal-information",
    },
    {
      name: "Federal Supplement",
      id: "#federal-supplement",
    },
    {
      name: "Retirment Incomee Needed",
      id: "#retirement-income-neeeded",
    },
    { name: "FEGLI Client", id: "#fegli-client" },
    {
      name: "Unused Sick Leave at Retirement",
      id: "#unused-sick-leave",
    },
    {
      name: "Federal TSP",
      id: "#federal-tsp",
    },
  ];

  useEffect(() => {
    (async () => {
      const _clients = await api.getAllClients();
      setClients(_clients);
    })();
  }, []);
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    if (data.clientId) window.location.href = `/edit-client/${data.clientId}`;
  }

  const labelDisplay = (field: any) => {
    const value = parseInt(field.value);
    const clientSearch = clients.find((client) => client.id === value);
    if (!clientSearch) return "Select Client";
    return clientSearch.firstName + " " + clientSearch.lastName;
  };

  const toggleSidebar = () => {
    const newState = !isSidebarOpen;
    setIsSidebarOpen(newState);
    Cookies.set("sidebarState", newState ? "open" : "closed");
  };

  return (
    <div>
      <a
        onClick={toggleSidebar}
        className="fixed top-2 right-10 mt-3 mr-4 z-[10] cursor-pointer hover:text-accent"
      >
        {isSidebarOpen ? (
          <SidebarOpen className="h-6 w-6" />
        ) : (
          <SidebarClose className="h-6 w-6" />
        )}
      </a>
      {isSidebarOpen && (
        <div className="w-[300px] fixed top-0 bottom-0 right-0 py-20 px-3 border-l bg-white z-[9]">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-8 flex justify-between"
            >
              <FormField
                control={form.control}
                name="clientId"
                render={({ field }) => (
                  <FormItem className="">
                    <FormLabel>Clients</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            className={cn(
                              "w-[230px] justify-between",
                              !field.value && "text-muted-foreground"
                            )}
                          >
                            {labelDisplay(field)}
                            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-[230px] p-0">
                        <Command>
                          <CommandInput
                            placeholder="Search client..."
                            className="h-9"
                          />
                          <CommandEmpty>No client found.</CommandEmpty>
                          <CommandGroup>
                            {clients.map((client) => (
                              <CommandItem
                                value={client.id?.toString()}
                                key={client.id}
                                onSelect={() => {
                                  form.setValue("clientId", String(client.id));
                                }}
                              >
                                {client.firstName + " " + client.lastName}
                                <CheckIcon
                                  className={cn(
                                    "ml-auto h-4 w-4",
                                    client.lastName === field.value
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" className="bg-accent mt-8 ml-1">
                <ChevronRight className="w-4 h-4" />
              </Button>
            </form>
          </Form>
          <h4 className="text-sm space-x-2 font-bold mt-4">Menu</h4>

          <AnchorMenuAccordion activeTab={activeTab} />
        </div>
      )}
    </div>
  );
}
