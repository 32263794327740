import React, { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "../../components/ui/form";

interface SelectControllerProps {
  name: string;
  label: string;
  options: { value: string; label: string }[];
  defaultValue: string;
  infoMessage?: string;
  inline?: boolean;
  onChange?: (value: string) => void;
}

const SelectController: React.FC<SelectControllerProps> = ({
  name,
  label,
  options,
  defaultValue,
  infoMessage,
  inline,
  onChange,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  useEffect(() => {
    // console.log(`Setting default value for ${name}:`, defaultValue);
    setValue(name, defaultValue);
  }, [defaultValue, name, setValue]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange: fieldOnChange, value },
        fieldState: { error },
      }) => (
        <FormItem className={`mb-6 ${inline ? "" : ""}`}>
          <FormLabel htmlFor={name} className="w-1/3">
            {label}
          </FormLabel>
          <FormControl className="w-2/3">
            <Select
              onValueChange={(val) => {
                fieldOnChange(val);
                if (onChange) {
                  onChange(val);
                }
              }}
              value={value || defaultValue}
            >
              <SelectTrigger>
                <SelectValue placeholder={`Select ${label.toLowerCase()}`} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {options.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </FormControl>
          {error && (
            <FormMessage className="text-red-500">{error.message}</FormMessage>
          )}
          {!error && infoMessage && (
            <FormMessage className="text-gray-500 text-xs font-normal">
              {infoMessage}
            </FormMessage>
          )}
        </FormItem>
      )}
    />
  );
};

export default SelectController;
