import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import NotFoundImage from "../images/404-not-found.png";

const NotFound: React.FC = () => {
  return (
    <>
      <div className="flex flex-col min-h-screen bg-white">
        <Header />
        <main className="flex flex-1 flex-col justify-center items-center px-4 text-center">
          <img
            src={NotFoundImage}
            alt="Page Not Found"
            className="w-full max-w-xs md:max-w-md mb-8"
          />
          <h1 className="text-6xl font-bold tracking-tighter mb-4 text-accent">
            404
          </h1>
          <p className="text-xl md:text-3xl font-semibold mb-4 text-accent">
            Page Not Found
          </p>
          <p className="mb-8">We can't find the page you're looking for.</p>
          <a
            href="/"
            className="text-primary hover:text-primary-dark transition duration-300 ease-in-out"
          >
            Go back home
          </a>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default NotFound;
