import React, { useState, useEffect } from "react";
import ComparisonsComponent from "../components/comparisons-component";
import ComparisonsTable from "../components/comparisons-table-federal";
import { ComparisonsAlert } from "../../modals/comparisons-alert";

const defaultItem: Comparison = {
  id: 0,
  comparisonName: "",
  rateOfReturn: 0,
  expectedIncomePercentage: 0,
  yearsUntilIncome: 0,
};

const emptyCollection = { values: [defaultItem] };

const parseJsonSafely = (json: string | undefined): Comparison[] => {
  if (!json) return emptyCollection.values;
  const values = JSON.parse(json).values;
  return values;
};

const Comparisons: React.FC<ComparisonsProps> = ({ client }) => {
  const [comparisons, setComparisons] = useState<Comparison[]>(
    parseJsonSafely(client.comparisons) || []
  );
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if (!client.calculated_tsp_value_simulation.length) return;

    const generatedData = client.calculated_tsp_value_simulation.map(
      (item: any, index: number) => {
        const row: any = {
          year: item.year,
          tspBalance: item["TSP"],
          tspIncome: item["TSPIncome"],
        };

        comparisons.forEach((comparison, compIndex) => {
          const balanceKey = `balance${compIndex}`;
          const diffKey = `diff${compIndex}`;
          const incomeKey = `income${compIndex}`;
          const diffIncomeKey = `diffIncome${compIndex}`;

          const balance =
            item.pretaxBalance * (1 + (index * comparison.rateOfReturn) / 100);
          const income = balance * (comparison.expectedIncomePercentage / 100);

          row[balanceKey] = balance || 0;
          row[diffKey] = balance - item.pretaxBalance || 0;
          row[incomeKey] = income || 0;
          row[diffIncomeKey] = income - row.tspIncome || 0;
        });

        return row;
      }
    );

    setData(generatedData);
  }, [client.calculated_tsp_value_simulation, comparisons]);

  const hasValidComparisons = comparisons.some(
    (comp) =>
      comp.comparisonName !== "" ||
      comp.rateOfReturn !== 0 ||
      comp.expectedIncomePercentage !== 0 ||
      comp.yearsUntilIncome !== 0
  );

  return (
    <div className="py-6">
      <ComparisonsComponent client={client} setComparisons={setComparisons} />
      {client.calculated_tsp_balance === 0 && <ComparisonsAlert />}
      {client.calculated_tsp_balance !== 0 && hasValidComparisons && (
        <ComparisonsTable data={data} comparisons={comparisons} />
      )}
    </div>
  );
};

export default Comparisons;
