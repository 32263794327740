import { Separator } from "../../components/ui/separator";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Icons } from "../../utils/icons";
import { Link } from "react-router-dom";

export default function AboutCompany() {
  return (
    <Card className="w-full mb-12">
      <CardHeader className="space-y-1">
        <h2 className="text-sm font-medium leading-none flex items-center gap-2 text-xl">
          <Icons.building className="w-8 h-8 text-accent" />
          About the Company
        </h2>
        <p className="text-sm text-muted-foreground">
          Retire Planning Max (RPM) is a premier calculator designed
          specifically for insurance and benefit specialists who cater to the
          Federal Employee market. Trusted by hundreds of agencies and
          individual agents, RPM streamlines the complex process of calculating
          Federal Employee retirement benefits, including Thrift Savings Plan
          (TSP), Social Security, and Federal Employee Retirement System (FERS)
          benefits. This comprehensive software solution simplifies retirement
          planning by integrating all the key components of a federal employee’s
          retirement package. RPM’s user-friendly interface and robust features
          make it an essential tool for professionals in the field. By providing
          accurate and efficient retirement calculations, RPM helps ensure that
          federal employees can plan their retirements with confidence and
          clarity. The software’s ability to handle the intricacies of federal
          retirement benefits makes it a valuable asset for those dedicated to
          assisting federal employees in achieving their retirement goals.
        </p>
      </CardHeader>
      <CardContent>
        <Separator className="my-4" />
        {/* <div className="space-y-1">
          <h2 className="text-sm font-medium leading-none flex items-center gap-2 text-xl">
            <Icons.changes className="w-8 h-8 text-accent" />
            Recent Changes
          </h2>
          <ul className="text-sm text-muted-foreground">
            <li> Item one</li>
            <li> Item two</li>
          </ul>
        </div>
        <Separator className="my-4" /> */}
        <div className="flex h-5 items-center space-x-4 text-sm">
          <div>
            <Link to="/">Dashboard</Link>
          </div>
          <Separator orientation="vertical" />
          <div>
            <Link to="https://billing.stripe.com/p/login/5kA9BIfw61Kr18cfYY">
              Billing
            </Link>
          </div>
          <Separator orientation="vertical" />
          <div>
            <Link to="/clients/">Clients</Link>
          </div>
          <Separator orientation="vertical" />
          <div>
            <Link to="/feedback/">Feedback</Link>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
