import React, { useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Input } from "../ui/input"; // Assuming you have an Input component
import config from "../../config";
import XanoAPI from "../../lib/XanoAPI"; // Assuming this is where the API is defined

// Load Stripe with the publishable key
const stripePromise = loadStripe(config.STRIPE_KEY);

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "18px",
      color: "#424770",
      letterSpacing: "0.025em",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

interface AddCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCardAdded: (paymentMethod: any) => void;
}

class CheckoutForm extends React.Component<any, any> {
  api = XanoAPI.getInstance(); // Instance of your Xano API

  constructor(props: any) {
    super(props);
    this.state = {
      name: "",
      postal: "",
      errorMessage: null,
      paymentMethod: null,
      isSubmitting: false,
    };
  }

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { stripe, elements } = this.props;
    const { name, postal } = this.state;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardNumberElement);

    if (card == null) {
      return;
    }

    this.setState({ isSubmitting: true, errorMessage: null });

    try {
      // Create the PaymentMethod using Stripe.js
      const payload = await stripe.createPaymentMethod({
        type: "card",
        card,
        billing_details: {
          name,
          address: {
            postal_code: postal,
          },
        },
      });

      if (payload.error) {
        this.setState({
          errorMessage: payload.error.message,
          paymentMethod: null,
          isSubmitting: false,
        });
        return;
      }

      this.setState({
        paymentMethod: payload.paymentMethod,
        errorMessage: null,
      });

      // Call the Xano API to attach the card using api.addBillingCard
      const response = await this.api.addBillingCard(payload.paymentMethod.id); // Pass the ID directly

      if (response && response.success) {
        // Handle the success response from the Xano API
        this.props.onCardAdded(payload.paymentMethod);
        this.props.onClose(); // Close the modal
      } else {
        // Handle any error response from the API
        this.setState({
          errorMessage: response?.message || "Failed to attach the card.",
        });
      }
    } catch (error) {
      // Handle any network or other errors
      this.setState({
        errorMessage: "An error occurred while adding the card.",
      });
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    const { stripe } = this.props;
    const { name, postal, errorMessage, paymentMethod, isSubmitting } =
      this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="space-y-6">
          {/* Cardholder Name Input */}
          <div>
            <label
              htmlFor="cardholder-name"
              className="block text-sm font-medium"
            >
              Cardholder Name
            </label>
            <Input
              id="cardholder-name"
              type="text"
              placeholder="Enter cardholder name"
              value={name}
              onChange={(e) => this.setState({ name: e.target.value })}
              required
            />
          </div>

          {/* CardNumberElement */}
          <div>
            <label htmlFor="cardNumber" className="block text-sm font-medium">
              Card Number
            </label>
            <CardNumberElement id="cardNumber" options={ELEMENT_OPTIONS} />
          </div>

          {/* Expiry Element */}
          <div>
            <label htmlFor="expiry" className="block text-sm font-medium">
              Expiration Date
            </label>
            <CardExpiryElement id="expiry" options={ELEMENT_OPTIONS} />
          </div>

          {/* CVC Element */}
          <div>
            <label htmlFor="cvc" className="block text-sm font-medium">
              CVC
            </label>
            <CardCvcElement id="cvc" options={ELEMENT_OPTIONS} />
          </div>

          {/* Postal Code */}
          <div>
            <label htmlFor="postal" className="block text-sm font-medium">
              Postal Code
            </label>
            <Input
              id="postal"
              type="text"
              placeholder="12345"
              value={postal}
              onChange={(e) => this.setState({ postal: e.target.value })}
              required
            />
          </div>

          {/* Error Message */}
          {errorMessage && <div className="text-red-500">{errorMessage}</div>}
        </div>

        <DialogFooter>
          <Button
            type="submit"
            disabled={!stripe || isSubmitting}
            className="bg-accent"
          >
            {paymentMethod
              ? "Card Added"
              : isSubmitting
              ? "Processing..."
              : "Add Card"}
          </Button>
        </DialogFooter>
      </form>
    );
  }
}

const InjectedCheckoutForm = ({
  onClose,
  onCardAdded,
}: {
  onClose: () => void;
  onCardAdded: (paymentMethod: any) => void;
}) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm
        stripe={stripe}
        elements={elements}
        onClose={onClose}
        onCardAdded={onCardAdded}
      />
    )}
  </ElementsConsumer>
);

const AddCardModal: React.FC<AddCardModalProps> = ({
  isOpen,
  onClose,
  onCardAdded,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add a New Card</DialogTitle>
        </DialogHeader>
        <InjectedCheckoutForm onClose={onClose} onCardAdded={onCardAdded} />
      </DialogContent>
    </Dialog>
  );
};

export default function AddCardModalWrapper({
  isOpen,
  onClose,
  onCardAdded,
}: AddCardModalProps) {
  return (
    <Elements stripe={stripePromise}>
      <AddCardModal
        isOpen={isOpen}
        onClose={onClose}
        onCardAdded={onCardAdded}
      />
    </Elements>
  );
}
