import React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import EditForms from "../components/clients/edit-forms";

interface EditClientParams {
  id: string | number;
}

const EditClient: React.FC = () => {
  let { id } = useParams();
  return (
    <>
      <div className="hidden flex-col md:flex">
        <Header />
        <EditForms />
        <Footer />
      </div>
    </>
  );
};

export default EditClient;
