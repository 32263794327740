import React from "react";
// import Header from "../components/header/header";
import Footer from "../components/footer/footer";

const Privacy: React.FC = () => {
  return (
    <>
      <div className="hidden flex-col min-h-screen md:flex">
        {/* <Header /> */}
        <div className="flex-1 space-y-4 p-8 pt-6 container mx-auto">
          <div className="pt-10">
            <h2 className="text-3xl font-bold tracking-tight">
              Privacy Policy
            </h2>
            <p className="text-muted-foreground mt-4">
              {/* Here's a placeholder for your privacy policy text */}
              This is where your privacy policy details will go. It's important
              to provide clear and transparent information about how you
              collect, use, and protect your users' data.
            </p>
          </div>

          {/* The privacy policy content goes here */}
          <div className="prose lg:prose-xl max-w-none text-muted-foreground pt-6">
            {/* Your Privacy Policy Content */}
            <p>
              Please replace this paragraph with your actual privacy policy
              content. Be thorough and clear to ensure that visitors understand
              your practices.
            </p>
            {/* Add additional content as necessary */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Privacy;
