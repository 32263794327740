import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import TextController from "../../../form-fields/TextController";
import PercentageController from "../../../form-fields/PercentageController";
import AmountController from "../../../form-fields/AmountController";
import { debounce } from "../../../../lib/utils";

const defaultLifeCycleFundsAllocations = [
  {
    fund: "L Income",
    preTaxPercentAllocated: 0,
    preTaxCurrentBalance: 0,
    rothPercentAllocated: 0,
    rothCurrentBalance: 0,
  },
  {
    fund: "L 2025",
    preTaxPercentAllocated: 0,
    preTaxCurrentBalance: 0,
    rothPercentAllocated: 0,
    rothCurrentBalance: 0,
  },
  {
    fund: "L 2030",
    preTaxPercentAllocated: 0,
    preTaxCurrentBalance: 0,
    rothPercentAllocated: 0,
    rothCurrentBalance: 0,
  },
  {
    fund: "L 2035",
    preTaxPercentAllocated: 0,
    preTaxCurrentBalance: 0,
    rothPercentAllocated: 0,
    rothCurrentBalance: 0,
  },
  {
    fund: "L 2040",
    preTaxPercentAllocated: 0,
    preTaxCurrentBalance: 0,
    rothPercentAllocated: 0,
    rothCurrentBalance: 0,
  },
  {
    fund: "L 2045",
    preTaxPercentAllocated: 0,
    preTaxCurrentBalance: 0,
    rothPercentAllocated: 0,
    rothCurrentBalance: 0,
  },
  {
    fund: "L 2050",
    preTaxPercentAllocated: 0,
    preTaxCurrentBalance: 0,
    rothPercentAllocated: 0,
    rothCurrentBalance: 0,
  },
  {
    fund: "L 2055",
    preTaxPercentAllocated: 0,
    preTaxCurrentBalance: 0,
    rothPercentAllocated: 0,
    rothCurrentBalance: 0,
  },
  {
    fund: "L 2060",
    preTaxPercentAllocated: 0,
    preTaxCurrentBalance: 0,
    rothPercentAllocated: 0,
    rothCurrentBalance: 0,
  },
  {
    fund: "L 2065",
    preTaxPercentAllocated: 0,
    preTaxCurrentBalance: 0,
    rothPercentAllocated: 0,
    rothCurrentBalance: 0,
  },
];

interface LifeCycleFundsAllocationProps {
  client: ClientType;
}

const parseLifeCycleFundsAllocations = (allocationsString: string) => {
  try {
    const parsedAllocations = JSON.parse(allocationsString);
    if (
      Array.isArray(parsedAllocations) &&
      parsedAllocations.length === defaultLifeCycleFundsAllocations.length
    ) {
      return parsedAllocations;
    }
  } catch {
    // If parsing fails, return default life cycle funds allocations
  }
  return defaultLifeCycleFundsAllocations;
};

const LifeCycleFundsAllocations: React.FC<LifeCycleFundsAllocationProps> = ({
  client,
}) => {
  const { setValue } = useFormContext();
  const initialAllocations = parseLifeCycleFundsAllocations(
    client.life_cycle_funds_allocations || ""
  );
  const [allocations, setAllocations] = useState(initialAllocations);

  useEffect(() => {
    const debouncedAutoSave = debounce(() => {
      const jsonAllocations = JSON.stringify(allocations);
      setValue("life_cycle_funds_allocations", jsonAllocations);
    }, 1000);
    debouncedAutoSave();
  }, [allocations, setValue]);

  const handleInputChange = (
    index: number,
    field: keyof (typeof defaultLifeCycleFundsAllocations)[number],
    value: string | number
  ) => {
    const updatedAllocations = allocations.map((allocation, i) =>
      i === index ? { ...allocation, [field]: value } : allocation
    );
    setAllocations(updatedAllocations);
  };

  return (
    <div className="flex flex-col w-full border">
      <div className="grid grid-cols-1 md:grid-cols-5 gap-x-8 p-4">
        {allocations.map((allocation, index) => (
          <React.Fragment key={index}>
            <div className="space-y-2 mb-6">
              <TextController
                name={`lifeCycleFundsAllocations[${index}].fund`}
                label="Fund"
                placeholder="Fund"
                defaultValue={allocation.fund}
                disabled={true}
                onChange={(value) => handleInputChange(index, "fund", value)}
              />
            </div>
            <div className="space-y-2 mb-6">
              <PercentageController
                name={`lifeCycleFundsAllocations[${index}].preTaxPercentAllocated`}
                label="Pre-Tax Percent Allocated"
                placeholder="0.0000"
                defaultValue={allocation.preTaxPercentAllocated}
                onChange={(value) =>
                  handleInputChange(index, "preTaxPercentAllocated", value)
                }
                infoMessage="Enter the percent allocated to the fund for Pre-Tax."
              />
            </div>
            <div className="space-y-2 mb-6">
              <AmountController
                name={`lifeCycleFundsAllocations[${index}].preTaxCurrentBalance`}
                label="Pre-Tax Current Balance"
                placeholder="$0"
                defaultValue={allocation.preTaxCurrentBalance}
                onChange={(value) =>
                  handleInputChange(index, "preTaxCurrentBalance", value)
                }
                infoMessage="Enter the current balance for the fund for Pre-Tax."
              />
            </div>
            <div className="space-y-2 mb-6">
              <PercentageController
                name={`lifeCycleFundsAllocations[${index}].rothPercentAllocated`}
                label="Roth Percent Allocated"
                placeholder="0.0000"
                defaultValue={allocation.rothPercentAllocated}
                onChange={(value) =>
                  handleInputChange(index, "rothPercentAllocated", value)
                }
                infoMessage="Enter the percent allocated to the fund for Roth."
              />
            </div>
            <div className="space-y-2 mb-6">
              <AmountController
                name={`lifeCycleFundsAllocations[${index}].rothCurrentBalance`}
                label="Roth Current Balance"
                placeholder="$0"
                defaultValue={allocation.rothCurrentBalance}
                onChange={(value) =>
                  handleInputChange(index, "rothCurrentBalance", value)
                }
                infoMessage="Enter the current balance for the fund for Roth."
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default LifeCycleFundsAllocations;
