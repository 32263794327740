import React, { useEffect, useState } from "react";
import { Button } from "../../ui/button";
import Loader from "../../loader/loader";
import { toast } from "../../../components/ui/use-toast";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";

interface ReportType {
  id: number;
  client: string;
  name: string;
  generated_by: string;
  created_at: string;
  url: string;
}

export default function Reports() {
  const [reports, setReports] = useState<ReportType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // Mock data for reports
  const mockReports: ReportType[] = [
    {
      id: 1,
      client: "Matt Taylor",
      name: "Client One report",
      created_at: "2024-01-15",
      generated_by: "agent one",
      url: "/path/to/report1.pdf",
    },
    {
      id: 2,
      client: "Robert Greene",
      name: "Client Two report 2023",
      created_at: "2024-01-01",
      generated_by: "agent two",
      url: "/path/to/report2.pdf",
    },
    {
      id: 3,
      client: "John Wayne",
      name: "Client two report 2024",
      created_at: "2024-03-31",
      generated_by: "agent three",
      url: "/path/to/report3.pdf",
    },
  ];

  useEffect(() => {
    const fetchReports = async () => {
      try {
        // Simulate fetching reports from API with a delay
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setReports(mockReports);
      } catch (error) {
        console.error("Error fetching reports:", error);
        toast({
          title: "Error",
          description: "Failed to fetch reports. Please try again.",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchReports();
  }, []);

  const handleDownload = (url: string) => {
    window.open(url, "_blank");
  };

  const handleDelete = async (reportId: number) => {
    try {
      // Simulate deleting the report
      await new Promise((resolve) => setTimeout(resolve, 500));
      setReports(reports.filter((report) => report.id !== reportId));
      toast({
        title: "Report Deleted",
        description: "The report has been successfully deleted.",
      });
    } catch (error) {
      console.error("Error deleting report:", error);
      toast({
        title: "Delete Report Failed",
        description: "There was an error deleting the report.",
      });
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold text-accent mb-4">
        Generated Reports
      </h2>
      <Table>
        <TableCaption>A list of your generated reports.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Client Name</TableHead>
            <TableHead>Report Name</TableHead>
            <TableHead>Generated By</TableHead>
            <TableHead>Date Created</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {reports.map((report) => (
            <TableRow key={report.id}>
              <TableCell className="font-medium">{report.client}</TableCell>
              <TableCell>{report.name}</TableCell>
              <TableCell>{report.generated_by}</TableCell>
              <TableCell>
                {new Date(report.created_at).toLocaleDateString()}
              </TableCell>
              <TableCell>
                <Button
                  variant="outline"
                  onClick={() => handleDownload(report.url)}
                  className="mr-2 bg-accent text-white border-none"
                >
                  Download
                </Button>
                <Button
                  variant="outline"
                  onClick={() => handleDelete(report.id)}
                  className="border-red-600 text-red-600"
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {reports.length > 0 && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={5} className="text-right">
                Total Reports: {reports.length}
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </div>
  );
}
