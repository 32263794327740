// pensionLookupTable.ts

const pensionLookupTable: Record<number, Record<number, number>> = {
  40000: {
    5: 167,
    10: 333,
    15: 500,
    16: 533,
    17: 567,
    18: 600,
    19: 633,
    20: 667,
    21: 700,
    22: 733,
    23: 767,
    24: 800,
    25: 833,
    30: 1000,
    35: 1167,
  },
  43000: {
    5: 179,
    10: 358,
    15: 538,
    16: 573,
    17: 609,
    18: 645,
    19: 681,
    20: 717,
    21: 753,
    22: 788,
    23: 824,
    24: 860,
    25: 896,
    30: 1050,
    35: 1254,
  },
  46000: {
    5: 192,
    10: 383,
    15: 575,
    16: 613,
    17: 652,
    18: 690,
    19: 729,
    20: 767,
    21: 806,
    22: 844,
    23: 883,
    24: 921,
    25: 960,
    30: 1125,
    35: 1342,
  },
  50000: {
    5: 208,
    10: 417,
    15: 625,
    16: 667,
    17: 709,
    18: 750,
    19: 792,
    20: 833,
    21: 875,
    22: 917,
    23: 958,
    24: 1000,
    25: 1042,
    30: 1250,
    35: 1467,
  },
  53000: {
    5: 221,
    10: 442,
    15: 663,
    16: 707,
    17: 751,
    18: 795,
    19: 839,
    20: 883,
    21: 928,
    22: 972,
    23: 1016,
    24: 1060,
    25: 1104,
    30: 1325,
    35: 1546,
  },
  56000: {
    5: 233,
    10: 467,
    15: 700,
    16: 746,
    17: 793,
    18: 840,
    19: 886,
    20: 933,
    21: 980,
    22: 1026,
    23: 1073,
    24: 1120,
    25: 1167,
    30: 1400,
    35: 1633,
  },
  60000: {
    5: 250,
    10: 500,
    15: 750,
    16: 800,
    17: 850,
    18: 900,
    19: 950,
    20: 1000,
    21: 1050,
    22: 1100,
    23: 1150,
    24: 1200,
    25: 1250,
    30: 1500,
    35: 1750,
  },
  63000: {
    5: 263,
    10: 525,
    15: 788,
    16: 840,
    17: 893,
    18: 945,
    19: 998,
    20: 1050,
    21: 1103,
    22: 1155,
    23: 1208,
    24: 1260,
    25: 1313,
    30: 1575,
    35: 1833,
  },
  66000: {
    5: 275,
    10: 550,
    15: 825,
    16: 880,
    17: 935,
    18: 990,
    19: 1045,
    20: 1100,
    21: 1155,
    22: 1210,
    23: 1265,
    24: 1320,
    25: 1375,
    30: 1650,
    35: 1917,
  },
  70000: {
    5: 292,
    10: 583,
    15: 875,
    16: 933,
    17: 991,
    18: 1050,
    19: 1108,
    20: 1167,
    21: 1225,
    22: 1283,
    23: 1342,
    24: 1400,
    25: 1458,
    30: 1750,
    35: 2042,
  },
  73000: {
    5: 304,
    10: 608,
    15: 913,
    16: 973,
    17: 1034,
    18: 1095,
    19: 1155,
    20: 1216,
    21: 1277,
    22: 1337,
    23: 1398,
    24: 1458,
    25: 1519,
    30: 1825,
    35: 2133,
  },
  76000: {
    5: 317,
    10: 633,
    15: 950,
    16: 1013,
    17: 1077,
    18: 1140,
    19: 1204,
    20: 1267,
    21: 1330,
    22: 1394,
    23: 1457,
    24: 1521,
    25: 1583,
    30: 1925,
    35: 2217,
  },
  80000: {
    5: 333,
    10: 667,
    15: 1000,
    16: 1067,
    17: 1133,
    18: 1200,
    19: 1267,
    20: 1333,
    21: 1400,
    22: 1467,
    23: 1533,
    24: 1600,
    25: 1667,
    30: 2000,
    35: 2333,
  },
  83000: {
    5: 346,
    10: 692,
    15: 1038,
    16: 1108,
    17: 1178,
    18: 1247,
    19: 1317,
    20: 1387,
    21: 1457,
    22: 1527,
    23: 1597,
    24: 1667,
    25: 1737,
    30: 2083,
    35: 2421,
  },
  86000: {
    5: 358,
    10: 717,
    15: 1075,
    16: 1147,
    17: 1220,
    18: 1292,
    19: 1365,
    20: 1437,
    21: 1510,
    22: 1583,
    23: 1655,
    24: 1728,
    25: 1800,
    30: 2167,
    35: 2508,
  },
  90000: {
    5: 375,
    10: 750,
    15: 1125,
    16: 1200,
    17: 1275,
    18: 1350,
    19: 1425,
    20: 1500,
    21: 1575,
    22: 1650,
    23: 1725,
    24: 1800,
    25: 1875,
    30: 2250,
    35: 2625,
  },
  93000: {
    5: 388,
    10: 775,
    15: 1163,
    16: 1242,
    17: 1321,
    18: 1400,
    19: 1479,
    20: 1558,
    21: 1637,
    22: 1717,
    23: 1796,
    24: 1875,
    25: 1954,
    30: 2333,
    35: 2717,
  },
  96000: {
    5: 400,
    10: 800,
    15: 1200,
    16: 1280,
    17: 1360,
    18: 1440,
    19: 1520,
    20: 1600,
    21: 1680,
    22: 1760,
    23: 1840,
    24: 1920,
    25: 2000,
    30: 2500,
    35: 2883,
  },
  100000: {
    5: 417,
    10: 833,
    15: 1250,
    16: 1333,
    17: 1417,
    18: 1500,
    19: 1583,
    20: 1667,
    21: 1750,
    22: 1833,
    23: 1917,
    24: 2000,
    25: 2083,
    30: 2667,
    35: 3042,
  },
  110000: {
    5: 458,
    10: 917,
    15: 1375,
    16: 1458,
    17: 1542,
    18: 1625,
    19: 1708,
    20: 1792,
    21: 1875,
    22: 1958,
    23: 2042,
    24: 2125,
    25: 2208,
    30: 2750,
    35: 2929,
  },
};

export default pensionLookupTable;
