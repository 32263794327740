import MainNav from "./main-nav";
import UserNav from "./user-nav";
import React, { useState, useEffect } from "react";
import WarningModal from "../clients/details/screen-resolution-warning";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const checkScreenWidth = () => {
    if (window.innerWidth < 767) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  return (
    <div>
      <WarningModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <div className="border-b bg-white z-10 fixed w-[100vw]">
        <div className="flex h-16 items-center px-2">
          <MainNav className="mx-6" />
          <div className="ml-auto flex items-center space-x-4">
            <UserNav />
          </div>
        </div>
      </div>
    </div>
  );
}
