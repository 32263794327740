import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button } from "../../../ui/button";
import { toast } from "../../../ui/use-toast";
import XanoAPI from "../../../../lib/XanoAPI";
import Loader from "../../../loader/loader";
import { useAuth } from "../../../../lib/AuthContext";
import RolesTable from "./roles-table";
import RoleModal from "../../../modals/add-role-modal";
import EditRoleModal from "../../../modals/edit-role-modal";
import { BookUser } from "lucide-react";

interface FormValues {
  roleName: string;
}

const defaultValues: FormValues = {
  roleName: "",
};

interface RoleManagementProps {
  agencyId: number | null;
}

const RoleManagement: React.FC<RoleManagementProps> = ({ agencyId }) => {
  const auth = useAuth();
  const [roles, setRoles] = useState<RoleType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isRoleModalOpen, setRoleModalOpen] = useState(false);
  const [isEditRoleModalOpen, setEditRoleModalOpen] = useState(false);
  const [editingRole, setEditingRole] = useState<RoleType | null>(null);

  const fetchRoles = async () => {
    if (!agencyId) return;
    try {
      const api = XanoAPI.getInstance();
      const fetchedRoles = await api.getRoles(agencyId);
      setRoles(fetchedRoles);
    } catch (error) {
      console.error("Error fetching roles:", error);
      toast({
        title: "Error",
        description: "Failed to fetch roles.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, [agencyId]);

  const handleSaveRole = async (roleData: any) => {
    try {
      const api = XanoAPI.getInstance();
      const newRole = await api.addRole({
        ...roleData,
        agency_id: agencyId || 0,
      });
      setRoles([...roles, newRole]);
      toast({
        title: "Role Added",
        description: "The new role has been successfully added.",
      });
      setRoleModalOpen(false);
    } catch (error) {
      console.error("Error saving role:", error);
      toast({
        title: "Save Role Failed",
        description: "There was an error saving the role.",
      });
    }
  };

  const handleUpdateRole = async (roleData: any) => {
    try {
      const api = XanoAPI.getInstance();
      if (editingRole) {
        await api.updateRole(editingRole.id, roleData);
        setRoles(
          roles.map((role) =>
            role.id === editingRole.id ? { ...role, ...roleData } : role
          )
        );
        toast({
          title: "Role Updated",
          description: "The role has been successfully updated.",
        });
        setEditRoleModalOpen(false);
        setEditingRole(null);
      }
    } catch (error) {
      console.error("Error updating role:", error);
      toast({
        title: "Update Role Failed",
        description: "There was an error updating the role.",
      });
    }
  };

  const openEditRoleModal = (role: RoleType) => {
    setEditingRole(role);
    setEditRoleModalOpen(true);
  };

  return (
    <div className="mt-6">
      <h2 className="text-xl font-semibold text-accent mb-4">
        Role Management
      </h2>

      {/* <h3 className="text-2xl font-semibold text-accent mb-4 mt-8">
        Existing Roles
      </h3> */}
      {isLoading ? (
        <Loader />
      ) : (
        <RolesTable roles={roles} onEdit={openEditRoleModal} />
      )}

      <RoleModal
        isOpen={isRoleModalOpen}
        onClose={() => setRoleModalOpen(false)}
        onSave={handleSaveRole}
      />
      {editingRole && (
        <EditRoleModal
          isOpen={isEditRoleModalOpen}
          onClose={() => {
            setEditRoleModalOpen(false);
            setEditingRole(null);
          }}
          onSave={handleUpdateRole}
          initialData={editingRole}
        />
      )}

      <Button
        onClick={() => setRoleModalOpen(true)}
        className="mb-4 mt-4 bg-accent"
      >
        <BookUser className="mr-2" />
        Add New Role
      </Button>
    </div>
  );
};

export default RoleManagement;
