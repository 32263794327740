import * as React from "react";
import { z } from "zod";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "../../../components/ui/button";
import { Icons } from "../../../utils/icons";
import { useToast } from "../../../components/ui/use-toast";
import SectionFields401k from "../components/401k/section-fields"; // Import form fields component
import XanoAPI from "../../../lib/XanoAPI";

// Define the schema for the 401k form
const financialInformation401kSchema = z.object({
  t_401k_type: z.enum(["Traditional", "Roth"]), // Dropdown with "Traditional" or "Roth"
  t_401k_currentSalary: z.number().min(0, "Current salary must be positive"), // Annual salary
  t_401k_payPeriodsPerYear: z.number().min(1, "Must be at least 1"), // Pay periods per year
  t_401k_monthlyContributionPercent: z
    .number()
    .min(0)
    .max(100, "Percentage must be between 0 and 100"), // % of income to contribute
  t_401k_employerMatchPercent: z
    .number()
    .min(0)
    .max(100, "Percentage must be between 0 and 100"), // Employer's monthly % match
  t_401k_salaryLimitForEmployerMatch: z
    .number()
    .min(0)
    .max(100, "Percentage must be between 0 and 100"), // Salary limit for employer match
  t_401k_annualManagementFee: z
    .number()
    .min(0)
    .max(100, "Percentage must be between 0 and 100"), // Annual management fee
  t_401k_selfDirected: z.boolean(), // Self-Directed Slider (Yes/No)
  t_401k_estimatedEarlyWithdrawals: z.number().min(0, "Must be positive"), // Est. Early Withdrawals
  t_401k_earlyWithdrawalPenalty: z.boolean(), // Early Withdrawals Subject to Penalty (Yes/No)
  t_401k_existing401kValue: z.number(),
});

type FormData401k = z.infer<typeof financialInformation401kSchema>;

const FinancialInformation401k = ({
  client,
  onUpdate,
  setFormsDirty,
  fetchClientData,
  resetFormsDirtyState,
  handleTabChange,
  formsDirty,
}: {
  client: ClientType;
  onUpdate: Function;
  setFormsDirty: Function;
  fetchClientData: Function;
  resetFormsDirtyState: Function;
  handleTabChange: Function;
  formsDirty: any;
}) => {
  const { toast } = useToast();
  const api = XanoAPI.getInstance();

  // Using correct field names with the prefix `t_401k_`
  const form = useForm({
    resolver: zodResolver(financialInformation401kSchema),
    defaultValues: {
      t_401k_type: client?.t_401k_type || "Traditional",
      t_401k_currentSalary: client?.t_401k_currentSalary || 0,
      t_401k_payPeriodsPerYear: client?.t_401k_payPeriodsPerYear || 12,
      t_401k_monthlyContributionPercent:
        client?.t_401k_monthlyContributionPercent || 0,
      t_401k_employerMatchPercent: client?.t_401k_employerMatchPercent || 0,
      t_401k_salaryLimitForEmployerMatch:
        client?.t_401k_salaryLimitForEmployerMatch || 0,
      t_401k_annualManagementFee: client?.t_401k_annualManagementFee || 0,
      t_401k_selfDirected: client?.t_401k_selfDirected || false,
      t_401k_estimatedEarlyWithdrawals:
        client?.t_401k_estimatedEarlyWithdrawals || 0,
      t_401k_earlyWithdrawalPenalty:
        client?.t_401k_earlyWithdrawalPenalty || false,
      t_401k_existing401kValue: client?.t_401k_existing401kValue || 0.0,
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = form;

  const onSubmit = async (data: FormData401k) => {
    if (!client || !client.id) {
      console.error("Client ID is missing.");
      return;
    }

    try {
      const processedData = {
        ...client,
        ...data,
      };
      const updateFinancialInformation = await api.updateClient(
        client.id,
        processedData
      );
      console.log(updateFinancialInformation, processedData);
      fetchClientData(client.id, 2000);

      resetFormsDirtyState();
      toast({
        title: "401k Information Updated!",
        description:
          "Your client's 401k financial information has been updated.",
      });
    } catch (error) {
      console.error("Error updating 401k information:", error);
    }
  };

  return (
    <div className="py-6 mb-8">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SectionFields401k client={client} />
          <div className="fixed bottom-0 right-0 m-3 z-50">
            <Button
              type="submit"
              className="mt-5 p-3 bg-accent"
              disabled={!isValid}
            >
              <Icons.save className="mr-2" />
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FinancialInformation401k;
