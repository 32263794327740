import React, { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Input } from "../ui/input";
import { FormItem, FormLabel, FormControl, FormMessage } from "../ui/form";

interface TextControllerProps {
  name: string;
  label: string;
  placeholder: string;
  defaultValue?: string;
  type?: string; // Added type prop
  validation?: {
    required?: string;
    minLength?: { value: number; message: string };
    maxLength?: { value: number; message: string };
    pattern?: { value: RegExp; message: string };
    validate?: (value: any) => boolean | string; // Added validate prop
  };
  infoMessage?: string;
  onChange?: (value: any) => void;
  disabled?: boolean; // Added disabled prop
  capitalize?: boolean; // Added capitalize prop
}

const TextController: React.FC<TextControllerProps> = ({
  name,
  label,
  placeholder,
  defaultValue = "",
  type = "text", // Default value for type prop
  validation = {},
  infoMessage,
  onChange,
  disabled = false, // Default value for disabled prop
  capitalize = false, // Default value for capitalize prop
}) => {
  const { control, setValue, setError, clearErrors } = useFormContext();
  const [internalValue, setInternalValue] = useState<string>(defaultValue);
  const [localError, setLocalError] = useState<string | null>(null);

  useEffect(() => {
    setInternalValue(defaultValue);
  }, [defaultValue]);

  const capitalizeString = (value: string): string => {
    return value.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleValidation = (value: string): boolean => {
    const finalValue = capitalize ? capitalizeString(value) : value;
    setInternalValue(finalValue);
    setValue(name, finalValue); // Update form value

    // Additional validation logic, if any
    if (validation.pattern && !validation.pattern.value.test(finalValue)) {
      setLocalError(validation.pattern.message);
      setError(name, { type: "manual", message: validation.pattern.message });
      return false;
    }

    clearErrors(name);
    setLocalError(null);
    return true;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    setInternalValue(rawValue);
    handleValidation(rawValue);
    if (onChange) {
      onChange(rawValue);
    }
  };

  const handleInputBlur = () => {
    handleValidation(internalValue);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      defaultValue={defaultValue}
      render={({
        field: { onChange: fieldOnChange, ...fieldProps },
        fieldState: { error: fieldError },
      }) => (
        <FormItem className="mb-6">
          <FormLabel htmlFor={name}>{label}</FormLabel>
          <FormControl>
            <div className="flex">
              <span className="flex justify-center items-center w-10 h-10 bg-gray-300 text-white rounded mr-1">
                T
              </span>
              <Input
                {...fieldProps}
                id={name}
                type={type} // Use the type prop
                placeholder={placeholder}
                value={internalValue}
                onChange={(e) => {
                  handleInputChange(e);
                  fieldOnChange(e.target.value);
                }}
                onBlur={handleInputBlur}
                disabled={disabled} // Pass disabled prop to Input
              />
            </div>
          </FormControl>
          <FormMessage
            className={
              fieldError || localError
                ? "text-red-500"
                : "text-gray-500 text-xs font-normal"
            }
          >
            {fieldError
              ? fieldError.message
              : localError
              ? localError
              : infoMessage}
          </FormMessage>
        </FormItem>
      )}
    />
  );
};

export default TextController;
