import React from "react";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "../../../components/ui/table";

interface Comparison {
  id: number;
  comparisonName: string;
  rateOfReturn: number;
  expectedIncomePercentage: number;
  yearsUntilIncome: number;
}

interface ComparisonsTable401kProps {
  data: {
    year: number;
    balance401k: number;
    [key: string]: number | undefined;
  }[];
  comparisons: Comparison[];
}

const ComparisonsTable401k: React.FC<ComparisonsTable401kProps> = ({
  data,
  comparisons,
}) => {
  const formatCurrency = (value: number | undefined) => {
    if (value === undefined || value === null) return "N/A";
    return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  };

  return (
    <Card className="mb-8">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">
          Quick Results 401k
        </h4>
      </CardHeader>
      <CardContent>
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow className="bg-gray-100">
                <TableHead>Year</TableHead>
                <TableHead>401k Balance</TableHead>
                {comparisons.map((comparison, index) => (
                  <React.Fragment key={comparison.id}>
                    <TableHead>{`${comparison.comparisonName} Balance`}</TableHead>
                    <TableHead>{`${comparison.comparisonName} Diff`}</TableHead>
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.year}</TableCell>
                  <TableCell>{formatCurrency(row.balance401k)}</TableCell>
                  {comparisons.map((comparison, compIndex) => (
                    <React.Fragment key={comparison.id}>
                      <TableCell>
                        {formatCurrency(row[`balance${compIndex}`])}
                      </TableCell>
                      <TableCell>
                        {formatCurrency(row[`diff${compIndex}`])}
                      </TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default ComparisonsTable401k;
