"use client";

import React, { useEffect, useState } from "react";
import { DataTable } from "../../components/table/data-table";
import { columns } from "../../components/table/columns";
import XanoAPI from "../../lib/XanoAPI";

export default function ClientsTable() {
  // Assuming `data` state is initialized to match the expected structure
  const [data, setData] = useState<
    {
      id: string;
      firstName: string;
      lastName: string;
      stateProvince: string;
      phoneNumber: string;
      type: string;
    }[]
  >([]);
  //   const router = useRouter();
  const api = XanoAPI.getInstance();

  function formatPhoneNumber(phoneNumber: any) {
    const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phoneNumber;
  }

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clients: ClientType[] = await api.getAllClients();
        if (clients) {
          const transformedClients = clients.map((client) => ({
            id: String(client.id), // Convert to string to ensure matching the expected type
            firstName: client.firstName ?? "",
            lastName: client.lastName ?? "",
            stateProvince: client.stateProvince ?? "",
            phoneNumber: formatPhoneNumber(client.phoneNumber ?? ""),
            type: client?.type ?? "federal",
          }));
          // Use type assertion here
          setData(
            transformedClients as {
              id: string;
              firstName: string;
              lastName: string;
              stateProvince: string;
              phoneNumber: string;
              type: string;
            }[]
          );
        }
      } catch (error) {
        // Check if the error is a 401 Unauthorized
        if (
          error instanceof Error &&
          "status" in error &&
          error.status === 401
        ) {
          window.location.replace("/sign-in");
        }
        console.error(error);
      }
    };

    fetchClients();
  }, [api]);

  return <DataTable data={data} columns={columns} />;
}
