import React from "react";
import DashboardHeader from "../components/header/header";
import Statistics from "../components/statistics/statistics";
import ClientsTable from "../components/clients/clients-table";
import Footer from "../components/footer/footer";

const Clients: React.FC = () => {
  return (
    <>
      <div className="hidden flex-col md:flex">
        <DashboardHeader />
        <div className="flex-1 space-y-4 p-8 pt-6 container mt-10">
          <div className="pt-10">
            <h2 className="text-3xl font-bold tracking-tight">Clients</h2>
            <p className="text-muted-foreground text-sm">
              Here&apos;s a list of your clients
            </p>
          </div>
          <div className="pt-2">
            <Statistics />
            <ClientsTable />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Clients;
