import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider } from "react-hook-form";
import * as z from "zod";
import React, { useEffect, useState } from "react";
import { Button } from "../../ui/button";
// import {
//   Form,
//   FormControl,
//   FormField,
//   FormItem,
//   FormLabel,
//   FormMessage,
// } from "../../ui/form";
import { toast } from "../../../components/ui/use-toast";
import { useAuth } from "../../../lib/AuthContext";
import XanoAPI from "../../../lib/XanoAPI";
import TextController from "../../form-fields/TextController";

const profileFormSchema = z.object({
  name: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .max(30, {
      message: "Name must not be longer than 30 characters.",
    }),
  email: z
    .string({
      required_error: "Email is required.",
    })
    .email("Please enter a valid email address."),
  agency_id: z.number(),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

export function ProfileForm() {
  const { currentUser } = useAuth();
  const api = XanoAPI.getInstance();
  const [loading, setLoading] = useState(false);

  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      name: currentUser?.name || "",
      email: currentUser?.email || "",
      // agency_id: currentUser?.agency_id || null,
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (currentUser) {
      form.reset({
        name: currentUser.name || "",
        email: currentUser.email || "",
        // agency_id: currentUser.agency_id || null,
      });
    }
  }, [currentUser, form]);

  async function onSubmit(data: ProfileFormValues) {
    if (!currentUser || !currentUser.id) {
      toast({
        title: "Error",
        description: "User ID is not available. Cannot update profile.",
      });
      return;
    }

    setLoading(true);
    try {
      const updatedUser = await api.updateUser(currentUser.id.toString(), data);
      toast({
        title: "Profile Updated",
        description: "Your profile information has been successfully updated.",
      });
      console.log(updatedUser);
      // Optionally update the current user data in your auth context here
    } catch (error) {
      console.error("Error updating user:", error);
      toast({
        title: "Update Failed",
        description:
          "There was an error updating your profile. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <TextController
          name="name"
          label="Name"
          placeholder="Enter your name"
          defaultValue={currentUser?.name || ""}
          validation={{
            required: "Name is required.",
            minLength: {
              value: 2,
              message: "Name must be at least 2 characters.",
            },
            maxLength: {
              value: 30,
              message: "Name must not be longer than 30 characters.",
            },
          }}
          infoMessage="This is your full name."
        />

        <TextController
          name="email"
          label="Email"
          placeholder="Enter your email"
          defaultValue={currentUser?.email || ""}
          validation={{
            required: "Email is required.",
            pattern: {
              value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
              message: "Please enter a valid email address.",
            },
          }}
          infoMessage="This is your associated email address."
        />

        {/* <TextController
          name="agency_name"
          label="Agency"
          placeholder="Enter your agency"
          defaultValue={currentUser?.agency_id || ""}
          validation={{
            minLength: {
              value: 2,
              message: "Agency must be at least 2 characters.",
            },
          }}
          infoMessage="The agency you are associated with."
        /> */}

        <Button type="submit" className="bg-accent" disabled={loading}>
          {loading ? "Updating..." : "Update Profile"}
        </Button>
      </form>
    </FormProvider>
  );
}
