import { TrendingUp } from "lucide-react";
import {
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { useState, useEffect } from "react";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";

import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart";

// Define the chart configuration
const chartConfig = {
  desktop: {
    label: "TSP Balance",
    color: "hsl(var(--chart-1))",
  },
  mobile: {
    label: "Annuity Balance",
    color: "hsl(var(--chart-2))",
  },
};

export default function TSPBalanceOverTime({ client }: ClientType) {
  const [chartData, setChartData] = useState<
    { year: number; TSP: number; annuityBalances: { [key: string]: number } }[]
  >([]);

  useEffect(() => {
    if (client.calculated_tsp_value_simulation && client.comparisons) {
      // console.log(client.calculated_tsp_value_simulation, "TSP Data");
      // console.log(client.comparisons, "Comparisons Data");

      const comparisons = JSON.parse(client.comparisons);

      const data = client.calculated_tsp_value_simulation.map(
        (item: any, index: number) => {
          const yearData: any = {
            year: item.year,
            TSP: item.TSP,
            annuityBalances: {},
          };

          // Calculate annuity balances for this year
          comparisons.values.forEach((comparison: any, compIndex: number) => {
            const balance =
              item.pretaxBalance *
              (1 + (index * comparison.rateOfReturn) / 100);
            const income =
              balance * (comparison.expectedIncomePercentage / 100);

            yearData.annuityBalances[comparison.comparisonName] = balance;
          });

          return yearData;
        }
      );

      setChartData(data);
    }
  }, [client.calculated_tsp_value_simulation, client.comparisons]);

  // const percentageIncrease = 5.2; // Example placeholder value

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-accent">TSP Balance Over Time</CardTitle>
        <CardDescription>Annuities vs TSP Balance</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <LineChart
            data={chartData}
            margin={{ top: 20, right: 30, bottom: 20, left: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="year"
              tickLine={false}
              axisLine={false}
              tickMargin={10}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => `$${value}`}
            />
            <Tooltip content={<ChartTooltipContent />} />
            <Line
              type="monotone"
              dataKey="TSP"
              stroke="var(--color-desktop)"
              strokeWidth={2}
              dot={{ fill: "var(--color-desktop)" }}
              activeDot={{ r: 6 }}
            />
            {Object.keys(chartData[0]?.annuityBalances || {}).map((key) => (
              <Line
                key={key}
                type="monotone"
                dataKey={`annuityBalances.${key}`}
                stroke="var(--color-mobile)"
                strokeWidth={2}
                dot={{ fill: "var(--color-mobile)" }}
                activeDot={{ r: 6 }}
              />
            ))}
          </LineChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        {/* <div className="flex gap-2 font-medium leading-none">
          Trending up by {percentageIncrease}% this month{" "}
          <TrendingUp className="h-4 w-4" />
        </div> */}
        <div className="leading-none text-muted-foreground">
          Showing TSP balance and annuity balances over time
        </div>
      </CardFooter>
    </Card>
  );
}
