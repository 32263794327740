// import React, { useState, useEffect, useCallback } from "react";
// import { useForm, FormProvider } from "react-hook-form";
// import TextController from "./TextController";
// import AmountController from "./AmountController";
// import SelectController from "./SelectController"; // Assuming you have a SelectController
// import { Button } from "../ui/button";
// import { TrashIcon } from "@radix-ui/react-icons";
// import { PlusIcon } from "lucide-react";
// import { FormLabel } from "../ui/form";
// import { debounce } from "../../lib/utils";

// const defaultItem: ItemType = {
//   id: 0,
//   name: "",
//   frequency: "monthly",
//   cost: 0,
// };

// const emptyCollection = { values: [defaultItem] };

// const ItemsComponent: React.FC<ItemFormProps> = ({ json, onSave, field }) => {
//   // Use state to handle dynamic form fields
//   const parseJsonSafely = (json: string | undefined): ItemType[] => {
//     if (!json) return emptyCollection.values;
//     const values = JSON.parse(json).values;
//     return JSON.parse(values);
//   };

//   const methods = useForm();
//   const { setValue } = methods;

//   const [items, setItems] = useState<ItemType[]>(parseJsonSafely(json));

//   useEffect(() => {
//     const debouncedAutoSave = debounce(() => {
//       const json = JSON.stringify(items);
//       onSave(json, field);
//     }, 1000);

//     debouncedAutoSave();
//   }, [items, onSave, field]);

//   const handleAdd = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
//     e.preventDefault();
//     const updatedItems = [...items, { ...defaultItem, id: items.length }];
//     setItems(updatedItems);
//   };

//   const deleteItem = useCallback(
//     (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
//       e.preventDefault();
//       setItems((current) => {
//         const newItems = [...current];
//         newItems.splice(index, 1);
//         return newItems;
//       });
//     },
//     [setItems]
//   );

//   const handleInputChange = (
//     index: number,
//     field: keyof ItemType,
//     value: string | number
//   ) => {
//     setItems((current) =>
//       current.map((item, i) =>
//         i === index ? { ...item, [field]: value } : item
//       )
//     );
//   };

//   const handleSelectChange = (index: number, value: "monthly" | "annually") => {
//     setItems((current) =>
//       current.map((item, i) =>
//         i === index ? { ...item, frequency: value } : item
//       )
//     );
//   };

//   return (
//     <FormProvider {...methods}>
//       <div className="grid grid-cols-1 md:grid-cols-[2fr_2fr_2fr_auto] gap-x-8">
//         {items &&
//           items.map((item, index) => (
//             <React.Fragment key={item.id}>
//               <div className="space-y-1 mb-1">
//                 <TextController
//                   name={`items[${index}].name`}
//                   label="Name"
//                   placeholder="Name"
//                   defaultValue={item.name}
//                   onChange={(value: any) =>
//                     handleInputChange(index, "name", value)
//                   }
//                 />
//               </div>

//               <div className="space-y-1 mb-1">
//                 <SelectController
//                   name={`items[${index}].frequency`}
//                   label="Frequency"
//                   // placeholder="Select frequency"
//                   defaultValue={item.frequency}
//                   options={[
//                     { value: "monthly", label: "Monthly" },
//                     { value: "annually", label: "Annually" },
//                   ]}
//                   onChange={(value: any) => handleSelectChange(index, value)}
//                 />
//               </div>

//               <div className="space-y-1 mb-1">
//                 <AmountController
//                   name={`items[${index}].cost`}
//                   label="Cost"
//                   placeholder="Cost"
//                   defaultValue={item.cost}
//                   onChange={(value: any) =>
//                     handleInputChange(index, "cost", value)
//                   }
//                 />
//               </div>

//               <div className="space-y-1 mb-1">
//                 <Button
//                   variant="outline"
//                   size="icon"
//                   className="mt-8"
//                   onClick={(e) => deleteItem(e, index)}
//                 >
//                   <TrashIcon className="h-4 w-4" />
//                 </Button>
//               </div>
//             </React.Fragment>
//           ))}

//         <Button size="icon" className="mt-1" onClick={handleAdd}>
//           <PlusIcon className="h-4 w-4" />
//         </Button>
//       </div>
//     </FormProvider>
//   );
// };

// export default ItemsComponent;

// import React, { useState, useEffect, useCallback } from "react";
// import { useForm, FormProvider } from "react-hook-form";
// import TextController from "./TextController";
// import AmountController from "./AmountController";
// import SelectController from "./SelectController"; // Assuming you have a SelectController
// import { Button } from "../ui/button";
// import { TrashIcon } from "@radix-ui/react-icons";
// import { PlusIcon } from "lucide-react";
// import { FormLabel } from "../ui/form";

// const defaultItem: ItemType = {
//   id: 0,
//   name: "",
//   frequency: "monthly",
//   cost: 0,
// };

// const emptyCollection = { values: [defaultItem] };

// const ItemsComponent: React.FC<ItemFormProps> = ({ json, onSave, field }) => {
//   // Use state to handle dynamic form fields
//   const parseJsonSafely = (json: string | undefined): ItemType[] => {
//     if (!json) return emptyCollection.values;
//     const values = JSON.parse(json).values;
//     return JSON.parse(values);
//   };

//   const methods = useForm();
//   const { setValue, handleSubmit } = methods;

//   const [items, setItems] = useState<ItemType[]>(parseJsonSafely(json));

//   useEffect(() => {
//     const json = JSON.stringify(items);
//     onSave(json, field);
//   }, [items, onSave, field]);

//   const handleAdd = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
//     e.preventDefault();
//     const updatedItems = [...items, { ...defaultItem, id: items.length }];
//     setItems(updatedItems);
//   };

//   const deleteItem = useCallback(
//     (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
//       e.preventDefault();
//       setItems((current) => {
//         const newItems = [...current];
//         newItems.splice(index, 1);
//         return newItems;
//       });
//     },
//     [setItems]
//   );

//   const handleInputChange = (
//     index: number,
//     field: keyof ItemType,
//     value: string | number
//   ) => {
//     setItems((current) =>
//       current.map((item, i) =>
//         i === index ? { ...item, [field]: value } : item
//       )
//     );
//   };

//   const handleSelectChange = (index: number, value: "monthly" | "annually") => {
//     setItems((current) =>
//       current.map((item, i) =>
//         i === index ? { ...item, frequency: value } : item
//       )
//     );
//   };

//   const onSubmit = () => {
//     // Force update to ensure latest state
//     const json = JSON.stringify(items);
//     onSave(json, field);
//   };

//   return (
//     <FormProvider {...methods}>
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <div className="grid grid-cols-1 md:grid-cols-[2fr_2fr_2fr_auto] gap-x-8">
//           {items &&
//             items.map((item, index) => (
//               <React.Fragment key={item.id}>
//                 <div className="space-y-1 mb-1">
//                   <TextController
//                     name={`items[${index}].name`}
//                     label="Name"
//                     placeholder="Name"
//                     defaultValue={item.name}
//                     onChange={(value: any) =>
//                       handleInputChange(index, "name", value)
//                     }
//                   />
//                 </div>

//                 <div className="space-y-1 mb-1">
//                   <SelectController
//                     name={`items[${index}].frequency`}
//                     label="Frequency"
//                     // placeholder="Select frequency"
//                     defaultValue={item.frequency}
//                     options={[
//                       { value: "monthly", label: "Monthly" },
//                       { value: "annually", label: "Annually" },
//                     ]}
//                     onChange={(value: any) => handleSelectChange(index, value)}
//                   />
//                 </div>

//                 <div className="space-y-1 mb-1">
//                   <AmountController
//                     name={`items[${index}].cost`}
//                     label="Cost"
//                     placeholder="Cost"
//                     defaultValue={item.cost}
//                     onChange={(value: any) =>
//                       handleInputChange(index, "cost", value)
//                     }
//                   />
//                 </div>

//                 <div className="space-y-1 mb-1">
//                   <Button
//                     variant="outline"
//                     size="icon"
//                     className="mt-8"
//                     onClick={(e) => deleteItem(e, index)}
//                   >
//                     <TrashIcon className="h-4 w-4" />
//                   </Button>
//                 </div>
//               </React.Fragment>
//             ))}

//           <Button size="icon" className="mt-1" onClick={handleAdd}>
//             <PlusIcon className="h-4 w-4" />
//           </Button>
//         </div>
//         <Button type="submit" className="mt-4">
//           Save
//         </Button>
//       </form>
//     </FormProvider>
//   );
// };

// export default ItemsComponent;

import React, { useState, useEffect, useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import TextController from "./TextController";
import AmountController from "./AmountController";
import SelectController from "./SelectController"; // Assuming you have a SelectController
import { Button } from "../ui/button";
import { TrashIcon } from "@radix-ui/react-icons";
import { PlusIcon } from "lucide-react";
import { FormLabel } from "../ui/form";

const defaultItem: ItemType = {
  id: 0,
  name: "",
  frequency: "monthly",
  cost: 0,
};

const emptyCollection = { values: [defaultItem] };

const ItemsComponent: React.FC<ItemFormProps> = ({ json, onSave, field }) => {
  // Use state to handle dynamic form fields
  const parseJsonSafely = (json: string | undefined): ItemType[] => {
    if (!json) return emptyCollection.values;
    const values = JSON.parse(json).values;
    return JSON.parse(values);
  };

  const methods = useForm();
  const { setValue } = methods;

  const [items, setItems] = useState<ItemType[]>(parseJsonSafely(json));

  useEffect(() => {
    const json = JSON.stringify(items);
    onSave(json, field);
  }, [items, onSave, field]);

  const handleAdd = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const updatedItems = [...items, { ...defaultItem, id: items.length }];
    setItems(updatedItems);
  };

  const deleteItem = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
      e.preventDefault();
      setItems((current) => {
        const newItems = [...current];
        newItems.splice(index, 1);
        return newItems;
      });
    },
    [setItems]
  );

  const handleInputChange = (
    index: number,
    field: keyof ItemType,
    value: string | number
  ) => {
    setItems((current) =>
      current.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
  };

  const handleSelectChange = (index: number, value: "monthly" | "annually") => {
    setItems((current) =>
      current.map((item, i) =>
        i === index ? { ...item, frequency: value } : item
      )
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="grid grid-cols-1 md:grid-cols-[2fr_2fr_2fr_auto] gap-x-8">
        {items &&
          items.map((item, index) => (
            <React.Fragment key={item.id}>
              <div className="space-y-1 mb-1">
                <TextController
                  name={`items[${index}].name`}
                  label="Name"
                  placeholder="Name"
                  defaultValue={item.name}
                  onChange={(value: any) =>
                    handleInputChange(index, "name", value)
                  }
                />
              </div>

              <div className="space-y-1 mb-1">
                <SelectController
                  name={`items[${index}].frequency`}
                  label="Frequency"
                  defaultValue={item.frequency}
                  options={[
                    { value: "monthly", label: "Monthly" },
                    { value: "annually", label: "Annually" },
                  ]}
                  onChange={(value: any) => handleSelectChange(index, value)}
                />
              </div>

              <div className="space-y-1 mb-1">
                <AmountController
                  name={`items[${index}].cost`}
                  label="Cost"
                  placeholder="Cost"
                  defaultValue={item.cost}
                  onChange={(value: any) =>
                    handleInputChange(index, "cost", value)
                  }
                />
              </div>

              <div className="space-y-1 mb-1">
                <Button
                  variant="outline"
                  size="icon"
                  className="mt-8"
                  onClick={(e) => deleteItem(e, index)}
                >
                  <TrashIcon className="h-4 w-4" />
                </Button>
              </div>
            </React.Fragment>
          ))}

        <Button size="icon" className="mt-1" onClick={handleAdd}>
          <PlusIcon className="h-4 w-4" />
        </Button>
      </div>
    </FormProvider>
  );
};

export default ItemsComponent;
