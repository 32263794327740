import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "../../../ui/card";
import { Button } from "../../../ui/button";
import { UserRoundX, UsersRound, UserCheck, NotebookPen } from "lucide-react";
import { UserStatusModal } from "../../../modals/user-status-modal";
import XanoAPI from "../../../../lib/XanoAPI";
import { useAuth } from "../../../../lib/AuthContext";

const getStatusClassNames = (
  status: string,
  agencyCreator: number | null,
  memberId: number
): string => {
  let classNames = "relative min-h-[160px] mb-8 ";
  if (status === "deactivated")
    classNames += "bg-red-50 border border-red-200 ";
  if (status === "active") classNames += "bg-green-50 border border-green-200 ";
  if (status === "invited")
    classNames += "bg-yellow-50 border border-yellow-200 ";
  if (agencyCreator === memberId)
    classNames += "bg-green-100 border border-green-200 ";
  return classNames.trim();
};

interface TeamMemberCardProps {
  member: TeamMember;
  agencyCreator: number | null;
  fetchAgencyNameAndMembers: () => void;
}

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({
  member,
  agencyCreator,
  fetchAgencyNameAndMembers,
}) => {
  const auth = useAuth();
  const getRoleName = (id: number) => {
    const role = auth.roles.find((role: any) => role.id === id);
    return role.role;
  };
  return (
    <Card
      key={member.id}
      className={getStatusClassNames(member.status, agencyCreator, member.id)}
    >
      <CardHeader>
        <CardTitle>{member.name}</CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-xs text-gray-400">
          Role: {getRoleName(member.permissions_id)}
        </p>
        <p className="text-xs text-gray-400 ">
          {member.status === "active" && (
            <UserCheck className="w-8 h-8 text-green-400 mr-3 mt-3 absolute top-0 right-0" />
          )}
          {member.status === "deactivated" && (
            <UserRoundX className="w-8 h-8 text-red-400 mr-3 mt-3 absolute top-0 right-0" />
          )}
          {member.status === "invited" && (
            <NotebookPen className="w-8 h-8 text-yellow-400 mr-3 mt-3 absolute top-0 right-0" />
          )}
        </p>
      </CardContent>
      <CardFooter className="static bottom-0">
        <div className="absolute bottom-2 right-2 flex space-x-1">
          {agencyCreator !== member.id && (
            <UserStatusModal
              userId={member.id}
              agencyId={member.agency_id}
              userStatus={member.status}
              userRole={member.role}
              onSave={fetchAgencyNameAndMembers} // Refresh the UI on save
            />
          )}
          <Link to={`/team/${member.id}`} className="text-accent">
            <Button variant="outline">
              <UsersRound className="w-4 h-4" />
            </Button>
          </Link>
        </div>
      </CardFooter>
    </Card>
  );
};

export default TeamMemberCard;
