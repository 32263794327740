import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";

interface EarlyWithdrawalChartProps {
  client: ClientType;
}

const EarlyWithdrawalChart: React.FC<EarlyWithdrawalChartProps> = ({
  client,
}) => {
  const [chartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    if (client?.calculated_401k_value) {
      const penaltyAge = 65;
      const penaltyAmount = 5000;

      const transformedData = client.calculated_401k_value.map((item: any) => ({
        year: item.year,
        balance401k: item.balance,
        penalty: item.age < penaltyAge ? penaltyAmount : 0,
      }));

      setChartData(transformedData);
    }
  }, [client]);

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-accent">
          Early Withdrawal Penalties
        </CardTitle>
        <CardDescription>
          Comparing 401k balance and penalties over time
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis tickFormatter={(value) => `$${value.toLocaleString()}`} />
            <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />
            <Line
              type="monotone"
              dataKey="balance401k"
              stroke="hsl(var(--accent))"
              strokeWidth={2}
              dot={{ fill: "hsl(var(--accent))" }}
              activeDot={{ r: 6 }}
            />
            <Line
              type="monotone"
              dataKey="penalty"
              stroke="hsl(var(--destructive))"
              strokeWidth={2}
              dot={{ fill: "hsl(var(--destructive))" }}
              activeDot={{ r: 6 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
      <CardFooter className="text-sm text-muted-foreground">
        401k balance and penalties over 30 years, with penalty drop at age 65
      </CardFooter>
    </Card>
  );
};

export default EarlyWithdrawalChart;
