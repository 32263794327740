import React, { useState, useEffect, useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextController from "../../form-fields/TextController";
import PercentageController from "../../form-fields/PercentageController";
import IntegerController from "../../form-fields/IntegerController"; // Import a number controller for the new columns
import { Button } from "../../ui/button";
import { TrashIcon } from "@radix-ui/react-icons";
import { PlusIcon } from "lucide-react";
import { debounce } from "../../../lib/utils";
import XanoAPI from "../../../lib/XanoAPI";
import { useToast } from "../../ui/use-toast";
import { Icons } from "../../../utils/icons";
import { Card, CardContent, CardFooter, CardHeader } from "../../ui/card";

interface Comparison {
  id: number;
  comparisonName: string;
  rateOfReturn: number;
  expectedIncomePercentage: number;
  yearsUntilIncome: number;
}

const formSchema = z.object({
  comparisons: z
    .array(
      z.object({
        id: z.number(),
        comparisonName: z.string().max(100),
        rateOfReturn: z.number().min(0).max(100),
        expectedIncomePercentage: z.number().min(0).max(100),
        yearsUntilIncome: z.number().min(0),
      })
    )
    .optional(),
});

type FormData = z.infer<typeof formSchema>;

const defaultItem: Comparison = {
  id: 0,
  comparisonName: "",
  rateOfReturn: 0,
  expectedIncomePercentage: 0,
  yearsUntilIncome: 0,
};

const emptyCollection = { values: [defaultItem] };

const ComparisonsComponent: React.FC<{
  client: ClientType;
  setComparisons: (comparisons: Comparison[]) => void;
}> = ({ client, setComparisons }) => {
  const api = XanoAPI.getInstance();
  const { toast } = useToast();

  const parseJsonSafely = (json: string | undefined): Comparison[] => {
    if (!json) return emptyCollection.values;
    const values = JSON.parse(json).values;
    return values;
  };

  const methods = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      comparisons: parseJsonSafely(client.comparisons),
    },
  });

  const { handleSubmit, setValue, getValues } = methods;
  const [items, setItems] = useState(parseJsonSafely(client.comparisons));

  const debouncedAutoSave = useCallback(
    debounce(() => {
      const json = JSON.stringify({ values: getValues("comparisons") });
      setComparisons(getValues("comparisons") || []);
    }, 1000),
    [getValues, setComparisons]
  );

  useEffect(() => {
    debouncedAutoSave();
  }, [items, debouncedAutoSave]);

  const handleAdd = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const updatedItems = [...items, { ...defaultItem, id: items.length }];
    setItems(updatedItems);
    setValue("comparisons", updatedItems);
    debouncedAutoSave();
  };

  const deleteItem = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
      e.preventDefault();
      setItems((current) => {
        const newItems = [...current];
        newItems.splice(index, 1);
        setValue("comparisons", newItems);
        debouncedAutoSave();
        return newItems;
      });
    },
    [setValue, debouncedAutoSave]
  );

  const handleInputChange = (
    index: number,
    field: keyof typeof defaultItem,
    value: string | number
  ) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setItems(updatedItems);
    setValue("comparisons", updatedItems);
    debouncedAutoSave();
  };

  const onSubmit = async (data: FormData) => {
    setValue("comparisons", getValues("comparisons"));

    if (!client || !client.id) {
      console.error("Client ID is missing.");
      return;
    }

    const processedData = {
      ...client,
      comparisons: JSON.stringify({ values: data.comparisons }),
    };

    try {
      const updateClient = await api.updateClient(client.id, processedData);
      console.log("Client comparisons updated", updateClient);

      toast({
        title: "Comparisons Updated!",
        description: "Your client's comparisons have been updated.",
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit, (errors) => console.log(errors))}>
        <Card className="mb-8">
          <CardHeader>
            <h4 className="text-lg font-semibold text-gray-700">Comparisons</h4>
          </CardHeader>
          <CardContent className="grid grid-cols-1 md:grid-cols-[2fr_2fr_2fr_2fr_auto] gap-x-8">
            {items &&
              items.map((item, index) => (
                <React.Fragment key={item.id}>
                  <div className="space-y-1 mb-1">
                    <TextController
                      name={`items[${index}].comparisonName`}
                      label="Comparison Name"
                      placeholder="Comparison Name"
                      defaultValue={item.comparisonName}
                      onChange={(value: any) =>
                        handleInputChange(index, "comparisonName", value)
                      }
                    />
                  </div>

                  <div className="space-y-1 mb-2">
                    <PercentageController
                      name={`items[${index}].rateOfReturn`}
                      label="Rate of Return"
                      placeholder="0.00"
                      defaultValue={item.rateOfReturn}
                      onChange={(value: any) =>
                        handleInputChange(index, "rateOfReturn", value)
                      }
                    />
                  </div>

                  <div className="space-y-1 mb-2">
                    <PercentageController
                      name={`items[${index}].expectedIncomePercentage`}
                      label="Expected Income %"
                      placeholder="0.00"
                      defaultValue={item.expectedIncomePercentage}
                      onChange={(value: any) =>
                        handleInputChange(
                          index,
                          "expectedIncomePercentage",
                          value
                        )
                      }
                    />
                  </div>

                  <div className="space-y-1 mb-2">
                    <IntegerController
                      name={`items[${index}].yearsUntilIncome`}
                      label="Years Until Income"
                      placeholder="0"
                      defaultValue={item.yearsUntilIncome}
                      onChange={(value: any) =>
                        handleInputChange(index, "yearsUntilIncome", value)
                      }
                    />
                  </div>

                  <div className="flex items-end mb-8">
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={(e) => deleteItem(e, index)}
                    >
                      <TrashIcon className="h-4 w-4" />
                    </Button>
                  </div>
                </React.Fragment>
              ))}

            <Button size="icon" className="mt-0" onClick={handleAdd}>
              <PlusIcon className="h-4 w-4" />
            </Button>
          </CardContent>
        </Card>
        <div className="fixed bottom-0 right-0 m-3 z-50">
          <Button type="submit" className="mt-5 p-3 bg-accent">
            <Icons.save className="mr-2" />
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default ComparisonsComponent;
