import React from "react";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "../../../components/ui/table";

// Define the FEGLI codes and their corresponding options
const fegliOptions = [
  { code: "A", options: "Ineligible" },
  { code: "B", options: "No Benefits" },
  { code: "C", options: "BL" },
  { code: "D", options: "BL, A" },
  { code: "E", options: "BL, C" },
  { code: "F", options: "BL, A, C" },
  { code: "G", options: "BL, B-1X" },
  { code: "H", options: "BL, A, B-1X" },
  { code: "I", options: "BL, B, C" },
  { code: "J", options: "BL, B, A, C" },
  { code: "K", options: "BL, B-2" },
  { code: "L", options: "BL, A, B-2" },
  { code: "M", options: "BL, B-2, C" },
  { code: "N", options: "BL, B-2, A, C" },
  { code: "O", options: "BL, B-3" },
  { code: "P", options: "BL, B-3, A" },
  { code: "Q", options: "BL, B-3, C" },
  { code: "R", options: "BL, B-3, A, C" },
  { code: "S", options: "BL, B-4" },
  { code: "T", options: "BL, A, B-4" },
  { code: "U", options: "BL, B-4, C" },
  { code: "V", options: "BL, B-4, A, C" },
  { code: "W", options: "BL, B-5" },
  { code: "X", options: "BL, A, B-5" },
  { code: "Y", options: "BL, B-5, C" },
  { code: "Z", options: "BL, B-5, A, C" },
];

// Split the FEGLI options into two columns
const splitOptions = (options: typeof fegliOptions) => {
  const middleIndex = Math.ceil(options.length / 2);
  return [options.slice(0, middleIndex), options.slice(middleIndex)];
};

const [leftOptions, rightOptions] = splitOptions(fegliOptions);

const FegliTable: React.FC = () => {
  return (
    <Card className="mb-8">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">FEGLI Options</h4>
      </CardHeader>
      <CardContent>
        <div className="flex overflow-x-auto">
          <div className="w-1/2 pr-2">
            <Table>
              <TableHeader>
                <TableRow className="bg-gray-100">
                  <TableHead>Code</TableHead>
                  <TableHead>Options</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {leftOptions.map((option) => (
                  <TableRow key={option.code}>
                    <TableCell>{option.code}</TableCell>
                    <TableCell>{option.options}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div className="w-1/2 pl-2">
            <Table>
              <TableHeader>
                <TableRow className="bg-gray-100">
                  <TableHead>Code</TableHead>
                  <TableHead>Options</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {rightOptions.map((option) => (
                  <TableRow key={option.code}>
                    <TableCell>{option.code}</TableCell>
                    <TableCell>{option.options}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default FegliTable;
