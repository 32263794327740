import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import SelectController from "../../form-fields/SelectController";
import AmountController from "../../form-fields/AmountController";
import PercentageController from "../../form-fields/PercentageController";
import IntegerController from "../../form-fields/IntegerController";

interface FederalSavingsPlanProps {
  client: ClientType;
}

const contributionMethodOptions = [
  { value: "percent_of_pay", label: "Percent of Pay" },
  { value: "dollar_amount", label: "Dollar Amount" },
  { value: "maximum", label: "Maximum" },
];

const FederalSavingsPlan: React.FC<FederalSavingsPlanProps> = ({ client }) => {
  const { control, setValue } = useFormContext();

  const watchContributionMethod = useWatch({
    control,
    name: "contributionMethod",
    defaultValue: client?.contributionMethod || "percent_of_pay",
  });

  const watchDistributionStartMethod = useWatch({
    control,
    name: "distributionStartMethod",
    defaultValue: client?.distributionStartMethod || "at_retirement",
  });

  React.useEffect(() => {
    setValue(
      "contributionMethod",
      client?.contributionMethod || "percent_of_pay"
    );
    setValue(
      "distributionStartMethod",
      client?.distributionStartMethod || "at_retirement"
    );
  }, [client, setValue]);

  return (
    // <Card className="mb-8">
    //   <CardHeader>
    //     <h4 className="text-lg font-semibold text-gray-700">
    //       Federal Savings Plan
    //     </h4>
    //   </CardHeader>

    <CardContent className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
      <SelectController
        name="contributionMethod"
        label="Contribution Method"
        options={contributionMethodOptions}
        defaultValue={client?.contributionMethod || "percent_of_pay"}
        infoMessage="Choose how contributions are calculated, e.g., as a percentage of pay."
      />

      {watchContributionMethod === "percent_of_pay" && (
        <PercentageController
          name="contributionPerPay"
          label="Contribution per Pay (%)"
          placeholder="Enter the percentage (e.g., 5 for 5%)"
          defaultValue={client?.contributionPerPay || 0.0}
          infoMessage="Example: Enter 5 for a 5% contribution per pay period."
        />
      )}

      {watchContributionMethod === "dollar_amount" && (
        <AmountController
          name="contributionPerPay"
          label="Contribution per Pay ($)"
          placeholder="Enter the amount (e.g., 500$)"
          defaultValue={client?.contributionPerPay || 0.0}
          infoMessage="Example: Enter 500 for a $500 contribution per pay period."
        />
      )}

      <AmountController
        name="pretaxCurrentBalance"
        label="Pre-Tax Current Balance"
        defaultValue={client?.pretaxCurrentBalance || 0.0}
        placeholder="$500,000.00"
        infoMessage="Enter your current balance before taxes."
      />

      <PercentageController
        name="preretireRateOfReturn"
        label="Pre-Retire Rate of Return (%)"
        placeholder="Enter expected rate of return (e.g., 6 for 6%)"
        defaultValue={client?.preretireRateOfReturn || 0.0}
        infoMessage="Example: Enter 6 for a 6% expected rate of return."
      />

      <SelectController
        name="distributionMethod"
        label="Distribution Method"
        options={[
          {
            value: "use_funds_as_needed",
            label: "Use Funds as Needed",
          },
        ]}
        defaultValue={client?.distributionMethod || "use_funds_as_needed"}
        infoMessage="Select how you plan to distribute the funds during retirement."
      />

      <SelectController
        name="distributionStartMethod"
        label="Distribution Start Method"
        options={[
          { value: "at_retirement", label: "At Retirement" },
          { value: "at_age", label: "At Age" },
        ]}
        defaultValue={client?.distributionStartMethod || "at_retirement"}
        infoMessage="Specify when you would like to start receiving distributions from your retirement savings."
      />

      {watchDistributionStartMethod === "at_age" && (
        <IntegerController
          name="distributionStartMethodAge"
          label="Distribution Start Method Age"
          placeholder="Enter your target retirement age"
          defaultValue={client?.distributionStartMethodAge || 0}
          infoMessage="Enter the age at which you would like to start the distribution."
        />
      )}
    </CardContent>
    // </Card>
  );
};

export default FederalSavingsPlan;
