import React, { useEffect } from "react";
import TeamMemberCard from "./team-member-card";
import Loader from "../../../loader/loader";
import { useAuth } from "../../../../lib/AuthContext";

interface TeamMembersListProps {
  teamMembers: TeamMember[];
  agencyCreator: number | null;
  fetchAgencyNameAndMembers: () => void;
}

const TeamMembersList: React.FC<TeamMembersListProps> = ({
  teamMembers,
  agencyCreator,
  fetchAgencyNameAndMembers,
}) => {
  const sortedMembers = [...teamMembers].sort((a, b) =>
    a.role === "admin" ? -1 : 1
  );
  return (
    <>
      {!sortedMembers.length && <Loader />}
      {sortedMembers.map((member) => (
        <TeamMemberCard
          key={member.id}
          member={member}
          agencyCreator={agencyCreator}
          fetchAgencyNameAndMembers={fetchAgencyNameAndMembers}
        />
      ))}
    </>
  );
};

export default TeamMembersList;
