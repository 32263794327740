import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Switch } from "../../components/ui/switch"; // Adjust the import path as needed
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "../../components/ui/form";

interface SwitchControllerProps {
  name: string;
  label: string;
  defaultValue: boolean;
  infoMessage: string;
}

const SwitchController: React.FC<SwitchControllerProps> = ({
  name,
  label,
  defaultValue,
  infoMessage,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <FormItem className="flex flex-col gap-2 mb-6">
          <FormLabel
            htmlFor={name}
            className="text-sm font-medium text-gray-700"
          >
            {label}
          </FormLabel>
          <Switch
            id={name}
            className="form-switch h-6 w-11 rounded-full bg-gray-200" // Adjust this according to your actual `Switch` component
            checked={value}
            onCheckedChange={onChange}
          />
          <FormMessage className="text-xs font-normal text-gray-500">
            {infoMessage}
          </FormMessage>
          {error && (
            <FormMessage className="text-sm text-red-500">
              {error.message}
            </FormMessage>
          )}
        </FormItem>
      )}
    />
  );
};

export default SwitchController;
