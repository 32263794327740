import React from "react";
import { useFormContext } from "react-hook-form";
import TextController from "../../form-fields/TextController";
import DateController from "../../form-fields/DateController";
import IntegerController from "../../form-fields/IntegerController";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";

export default function FamilyInformationComponent({
  client,
}: {
  client: ClientType;
}) {
  return (
    <Card className="mb-8 shadow" id="family-information">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">
          Family Information
        </h4>
      </CardHeader>
      <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-x-8">
        <div className="my-2">
          <TextController
            name="spousesName"
            label="Spouse's Name"
            defaultValue={client?.spousesName || ""}
            placeholder="Enter spouse's name"
            infoMessage="Please enter your spouse's full name."
            capitalize={true}
          />
        </div>
        <div className="my-2">
          <DateController
            name="spousesDateOfBirth"
            label="Spouse's Date of Birth"
            placeholder="YYYY-MM-DD"
            defaultValue={client?.spousesDateOfBirth || ""}
            infoMessage="Please enter the spouse's date of birth."
            validation={{
              // required: "Date of birth is required",
              min: {
                value: "01/01/1900",
                message: "Date cannot be before 01/01/1900",
              },
              max: {
                value: "12/31/2023",
                message: "Date cannot be after 12/31/2023",
              },
              noFutureDates: true,
            }}
          />
        </div>

        {/* Number of Dependents */}
        <div className="my-2">
          <IntegerController
            name="dependents"
            label="Number of Dependents"
            defaultValue={client?.dependents || 0}
            placeholder="Enter number of dependents"
            infoMessage="Please enter the number of dependents."
          />
        </div>
      </CardContent>
    </Card>
  );
}
