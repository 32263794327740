import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import FeedbackForm from "../components/feedback/form";

const Feedback: React.FC = () => {
  return (
    <>
      <div className="hidden flex-col md:flex">
        <Header />
        <div className="flex-1 space-y-4 p-8 pt-6 container mt-10">
          <div className="pt-10">
            <h2 className="text-3xl font-bold tracking-tight">Feedback</h2>
            <p className="text-muted-foreground">
              {/* Here's a list of your clients */}
              Let us know what you think
            </p>
          </div>

          <div className="pt-0">
            <FeedbackForm />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Feedback;
