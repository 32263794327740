import { Link, useLocation } from "react-router-dom";
import logo from "../../images/logo.png";
import { cn } from "../../lib/utils";

export default function MainNav({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const location = useLocation();

  return (
    <nav
      className={cn("flex items-center space-x-4 lg:space-x-6", className)}
      {...props}
    >
      <Link to="/">
        <img src={logo} alt="logo" width="50" height="50" />
      </Link>
      <Link
        to="/"
        className={`text-sm font-medium text-muted-foregorund hover:text-primary ${
          location.pathname === "/" ? "text-primary" : ""
        }`}
      >
        Dashboard
      </Link>
      <Link
        to="/clients/"
        className={`text-sm font-medium text-muted-foregorund hover:text-primary ${
          location.pathname === "/clients/" ? "text-primary" : ""
        }`}
      >
        Clients
      </Link>
      <Link
        to="/feedback/"
        className={`text-sm font-medium text-muted-foregorund hover:text-primary ${
          location.pathname === "/feedback/" ? "text-primary" : ""
        }`}
      >
        Feedback
      </Link>{" "}
    </nav>
  );
}
