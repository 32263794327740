import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { toast } from "../components/ui/use-toast";
import XanoAPI from "../lib/XanoAPI";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import { Edit } from "lucide-react";
import { Card, CardHeader, CardContent } from "../components/ui/card";
import { formatPhoneNumber } from "../lib/utils";

export default function TeamMember() {
  const { memberId } = useParams<{ memberId: string }>();
  const [clients, setClients] = useState<ClientType[]>([]);
  const [memberName, setMemberName] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!memberId) {
          toast({
            title: "Error",
            description: "Member ID is missing.",
          });
          return;
        }

        console.log("Fetching data for memberId:", memberId);

        const api = XanoAPI.getInstance();

        // Fetch the team member's information
        const member = await api.getTeamMemberById(parseInt(memberId));
        console.log("Fetched member:", member);

        if (member && member.name) {
          setMemberName(member.name);
        } else {
          toast({
            title: "Error",
            description: "Failed to fetch team member information.",
          });
        }

        // Fetch the clients for the team member
        const clients = await api.getClientsByMemberId(parseInt(memberId));
        console.log("Fetched clients:", clients);
        setClients(clients);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast({
          title: "Error",
          description: "Failed to fetch data.",
        });
      }
    };

    fetchData();
  }, [memberId]);

  return (
    <>
      <Header />
      <div className="container py-8">
        <h2 className="text-xl font-semibold text-accent mb-4">
          Clients of {memberName}
        </h2>

        {!clients.length && (
          <p className="border p-5 border-accent rounded-xl text-gray-600 text-sm">
            The team member hasn't added any clients yet.
          </p>
        )}

        {!!clients.length && (
          <Card>
            <CardHeader>
              <h2 className="text-xl font-semibold text-accent mb-4">
                Clients of {memberName}
              </h2>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>First Name</TableHead>
                    <TableHead>Last Name</TableHead>
                    <TableHead>Phone Number</TableHead>
                    <TableHead>State/Province</TableHead>
                    <TableHead>Edit</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {clients.map((client) => (
                    <TableRow key={client.id}>
                      <TableCell>{client.firstName}</TableCell>
                      <TableCell>{client.lastName}</TableCell>
                      <TableCell>
                        {formatPhoneNumber(client.phoneNumber)}
                      </TableCell>
                      <TableCell>{client.stateProvince}</TableCell>
                      <TableCell>
                        <Link
                          to={`/edit-client/${client.id}`}
                          className="bg-accent"
                        >
                          <Edit className="w-4 h-4 text-accent" />
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        )}
      </div>
      <Footer />
    </>
  );
}
