import React from "react"; // Import React
import { Cross2Icon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { DataTableViewOptions } from "./data-table-view-options";
import { MassDelete } from "./mass-delete";
import { ExportToCsv } from "./export-to-csv";
import { AddClientModal } from "./add-client-modal";
import { useAuth } from "../../lib/AuthContext";

interface DataTableToolbarProps {
  table: any;
}

export function DataTableToolbar({ table }: DataTableToolbarProps) {
  const isFiltered = table.getState().columnFilters.length > 0;
  const auth = useAuth();
  const permissions = auth.currentUser?.user_permissions[0];

  const selectedIds = table
    .getSelectedRowModel()
    .flatRows.map((row: any) => row.original.id)
    .filter((id: any): id is string => id !== undefined);

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          placeholder="Filter by Last Name..."
          value={
            (table.getColumn("lastName")?.getFilterValue() as string) ?? ""
          }
          onChange={(event: any) =>
            table.getColumn("lastName")?.setFilterValue(event.target.value)
          }
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
        {permissions.can_manage_clients && <AddClientModal />}
        {permissions.can_manage_clients && (
          <MassDelete selectedIds={selectedIds} />
        )}
        {permissions.can_manage_clients && <ExportToCsv />}
      </div>
      <DataTableViewOptions table={table} />
    </div>
  );
}
