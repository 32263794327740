import React from "react";
import { useFormContext } from "react-hook-form";
import IntegerController from "../../form-fields/IntegerController";
import SelectController from "../../form-fields/SelectController";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";

const targetRetirementMonthOptions = [
  { value: "", label: "Please Select ..." },
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

export default function RetirementInformationComponent({
  client,
}: {
  client: ClientType;
}) {
  const { control } = useFormContext();

  return (
    <Card className="mb-8 shadow" id="retirement-information">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">Retirement</h4>
      </CardHeader>
      <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-x-8">
        <div className="my-2">
          <IntegerController
            name="targetRetirementAge"
            label="Target Retirement Age"
            placeholder="Enter your target retirement age"
            defaultValue={client?.targetRetirementAge || 0}
            infoMessage="Enter the age at which you plan to retire."
            validation={{
              min: { value: 0, message: "Age must be at least 0." },
              max: { value: 100, message: "Age must be at most 100." },
            }}
            increments={true}
          />
        </div>
        <div className="my-2">
          <SelectController
            name="targetRetirementMonth"
            label="Target Retirement Month"
            options={targetRetirementMonthOptions}
            defaultValue={client?.targetRetirementMonth || ""}
            infoMessage="Select your target month for retirement."
          />
        </div>
        <div className="my-2">
          <IntegerController
            name="lifeExpectancyAge"
            label="Life Expectancy Age"
            placeholder="Enter your expected life expectancy age"
            defaultValue={client?.lifeExpectancyAge || 0}
            infoMessage="Enter the age expected to live until."
            validation={{
              min: { value: 0, message: "Age must be at least 0." },
              max: { value: 120, message: "Age must be at most 120." },
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
}
