import React from "react";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import { useFormContext, useWatch } from "react-hook-form";

import SelectController from "../../form-fields/SelectController";
import IntegerController from "../../form-fields/IntegerController";
import PercentageController from "../../form-fields/PercentageController";

interface SocialSecurityProps {
  client: ClientType;
  yearsIncomeSsiDiff: number;
}

const startPaymentsOptions = [
  { value: "retirement_or_62", label: "At Retirement or 62" },
  {
    value: "age_no_penalty_occurs",
    label: "Age No Penalty Occurs",
  },
  {
    value: "do_not_calculate",
    label: "Do Not Calculate",
  },
  {
    value: "specified_age",
    label: "Specified Age",
  },
];

const calculationMethodOptions = [
  {
    value: "calculate",
    label: "Calculate",
  },
  {
    value: "value_at_retirement",
    label: "Value at Retirement",
  },
  {
    value: "todays_value",
    label: "Today's Value",
  },
];

const SocialSecurity: React.FC<SocialSecurityProps> = ({
  client,
  yearsIncomeSsiDiff,
}) => {
  const { control, setValue } = useFormContext();

  return (
    <Card className="mb-8" id="social-security">
      <CardHeader>
        <h4 className="text-lg font-semibold text-gray-700">Social Security</h4>
      </CardHeader>

      <CardContent className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
        <SelectController
          name="startPayments"
          label="Start Payments"
          options={startPaymentsOptions}
          defaultValue={client?.startPayments || "retirement_or_62"}
          infoMessage="Select when you would like to start receiving payments."
        />

        <SelectController
          name="calculationMethod"
          label="Calculation Method"
          options={calculationMethodOptions}
          defaultValue={client?.calculationMethod || "value_at_retirement"}
          infoMessage="Select the method for calculating retirement benefits."
        />

        <IntegerController
          name="yearsIncomeSsi"
          label="Years of Income for Social Security"
          defaultValue={client?.yearsIncomeSsi || 0}
          calculatedValue={yearsIncomeSsiDiff ? yearsIncomeSsiDiff : 0}
          placeholder="0"
          infoMessage="Enter the total number of years contributing to Social Security."
        />

        <PercentageController
          name="maximumRetirementCola"
          label="Maximum Retirement COLA (%)"
          defaultValue={client?.maximumRetirementCola || 0.0}
          placeholder="0.00"
          infoMessage="Enter the maximum Cost of Living Adjustment rate as a percentage."
        />
      </CardContent>
    </Card>
  );
};

export default SocialSecurity;
