import React from "react";
import { Link } from "react-router-dom";

import SignInForm from "../components/sign-in/SignInForm";
import logo from "../images/logo.png";
import SignInImage from "../images/signin.jpg";

export default function SignIn() {
  return (
    <>
      <div className="container relative hidden h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-full flex-col bg-muted  text-white dark:border-r lg:flex">
          <div className="absolute inset-0 bg-zinc-200 bg-cover" />
          <div
            className="relative z-20 mt-auto bg-cover bg-center bg-no-repeat h-screen"
            style={{ backgroundImage: `url(${SignInImage})` }}
          >
            {/* <img src={SignInImage} alt="" /> */}
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <div className="flex items-center justify-center w-full">
                <div className="w-[80px] h-[80px] relative mb-8">
                  <img
                    src={logo}
                    alt="logo"
                    // layout="fill"
                    sizes="(max-width: 768px) 100px, 50vw"
                  />
                </div>
              </div>

              <h1 className="text-2xl font-semibold tracking-tight">
                Sign In to Your Account
              </h1>
              <p className="text-sm text-muted-foreground">
                Enter your email below to continue
              </p>
            </div>
            <SignInForm />
            <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{" "}
              <Link
                to="/terms"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                to="/privacy"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
