import React from "react";
import { Check, X, Edit3 } from "lucide-react";

interface RolesTableProps {
  roles: RoleType[];
  onEdit: (role: RoleType) => void;
}

const RolesTable: React.FC<RolesTableProps> = ({ roles, onEdit }) => (
  <div className="overflow-x-auto">
    <table className="min-w-full bg-white border border-gray-200">
      <thead>
        <tr>
          <th className="px-4 py-2 text-sm">Role</th>
          <th className="px-4 py-2 text-sm">Manage Users</th>
          <th className="px-4 py-2 text-sm">Manage Clients</th>
          <th className="px-4 py-2 text-sm">View Reports</th>
          <th className="px-4 py-2 text-sm">Edit Preferences</th>
          <th className="px-4 py-2 text-sm">Edit</th>
        </tr>
      </thead>
      <tbody>
        {roles.map((role) => (
          <tr key={role.id} className="border-t border-gray-200">
            <td className="px-4 py-2 text-sm">{role.role}</td>
            <td className="px-4 py-2">
              {role.can_manage_users ? (
                <Check className="text-green-500 w-5 h-5" />
              ) : (
                <X className="text-red-500 w-5 h-5" />
              )}
            </td>
            <td className="px-4 py-2">
              {role.can_manage_clients ? (
                <Check className="text-green-500 w-5 h-5" />
              ) : (
                <X className="text-red-500 w-5 h-5" />
              )}
            </td>
            <td className="px-4 py-2">
              {role.can_view_reports ? (
                <Check className="text-green-500 w-5 h-5" />
              ) : (
                <X className="text-red-500 w-5 h-5" />
              )}
            </td>
            <td className="px-4 py-2">
              {role.can_edit_preferences ? (
                <Check className="text-green-500 w-5 h-5" />
              ) : (
                <X className="text-red-500 w-5 h-5" />
              )}
            </td>
            <td className="px-4 py-2">
              <button onClick={() => onEdit(role)} className="text-primary">
                {role.role !== "admin" && <Edit3 className="w-5 h-5" />}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default RolesTable;
