import React from "react";
import { useFormContext } from "react-hook-form";
import AmountController from "../../../form-fields/AmountController";
import PercentageController from "../../../form-fields/PercentageController";
import SelectController from "../../../form-fields/SelectController";

interface RothAccountProps {
  client: ClientType;
  contributionMethodTSP: string;
}

const RothAccount: React.FC<RothAccountProps> = ({
  client,
  contributionMethodTSP,
}) => {
  return (
    <div className="space-y-4">
      <AmountController
        name="roth_currentBalance"
        label="Current Balance"
        placeholder="Enter current balance"
        defaultValue={client?.roth_currentBalance || 0.0}
        infoMessage="Enter the current balance of your Roth account."
      />
      {contributionMethodTSP === "percent_of_pay" && (
        <PercentageController
          name="toth_contributionPerPaycheck"
          label="Contribution Per Paycheck"
          placeholder="0.0000"
          defaultValue={client?.roth_contributionPerPaycheck || 0.0}
          infoMessage="Enter the contribution per paycheck for the Pre-Tax account."
        />
      )}
      {contributionMethodTSP === "dollars_per_deposit" && (
        <AmountController
          name="roth_contributionPerPaycheck"
          label="Contribution Per Paycheck"
          placeholder="0.0000"
          defaultValue={client?.roth_contributionPerPaycheck || 0.0}
          infoMessage="Enter the contribution per paycheck for the Pre-Tax account."
        />
      )}
      <PercentageController
        name="roth_preRetireRateOfReturn"
        label="Pre-Retire Rate of Return (%)"
        placeholder="Enter rate of return"
        defaultValue={client?.roth_preRetireRateOfReturn || 0.0}
        infoMessage="Enter the expected rate of return before retirement."
      />
      <SelectController
        name="roth_futureContributionsMethod"
        label="Future Contributions Method"
        options={[
          { value: "none", label: "None" },
          { value: "percent_of_pay", label: "Percent of Pay" },
          { value: "dollar_per_deposit", label: "Dollar per Deposit" },
          { value: "maximum", label: "Maximum" },
        ]}
        defaultValue={client?.roth_futureContributionsMethod || "none"}
        infoMessage="Choose the method for future contributions."
      />
      <SelectController
        name="roth_annualIncreaseMethod"
        label="Annual Increase Method"
        options={[
          { value: "no_increase", label: "No Increase" },
          { value: "increase_in_value", label: "Increase in Value" },
          { value: "percentage_increase", label: "Percentage Increase" },
        ]}
        defaultValue={client?.roth_annualIncreaseMethod || "no_increase"}
        infoMessage="Choose the method for annual increases."
      />
      <SelectController
        name="roth_distributionMethod"
        label="Distribution Method"
        options={[
          { value: "use_funds_as_needed", label: "Use Funds as Needed" },
          { value: "annuitize", label: "Annuitize" },
          { value: "retain_principal", label: "Retain Principal" },
          { value: "amortize_no_inflation", label: "Amortize (No Inflation)" },
          {
            value: "amortize_with_inflation",
            label: "Amortize (With Inflation)",
          },
          { value: "percent_of_account", label: "Percent of Account" },
          { value: "rmd_only", label: "RMD Only" },
          { value: "tsp_annuity", label: "TSP Annuity" },
          {
            value: "dollar_value_no_inflation",
            label: "Dollar Value (No Inflation)",
          },
          {
            value: "dollar_value_with_inflation",
            label: "Dollar Value (With Inflation)",
          },
        ]}
        defaultValue={client?.roth_distributionMethod || "use_funds_as_needed"}
        infoMessage="Choose the method for distributions."
      />
      <PercentageController
        name="roth_rateOfReturn"
        label="Rate of Return (%)"
        placeholder="Enter rate of return"
        defaultValue={client?.roth_rateOfReturn || 0.0}
        infoMessage="Enter the expected rate of return."
      />
      <SelectController
        name="roth_distributionStartMethod"
        label="Distribution Start Method"
        options={[
          { value: "at_retirement", label: "At Retirement" },
          { value: "specific_date", label: "Specific Date" },
          { value: "owners_age", label: "Owner's Age" },
          { value: "years_after_retirement", label: "Years After Retirement" },
          { value: "59_and_a_half", label: "59 1/2 Years" },
        ]}
        defaultValue={client?.roth_distributionStartMethod || "at_retirement"}
        infoMessage="Choose when to start distributions."
      />
    </div>
  );
};

export default RothAccount;
