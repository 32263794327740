import React from "react";
import { Accordion, AccordionItem, AccordionTrigger } from "../ui/accordion";

const FinancialInformationAnchorMenu = [
  {
    name: "Current Income",
    id: "#current-income",
  },
  {
    name: "Federal Retirement Plan",
    id: "#federal-retirement-plan",
  },
  {
    name: "Social Security",
    id: "#social-security",
  },
  {
    name: "Federal Information",
    id: "#federal-information",
  },
  {
    name: "Federal Supplement",
    id: "#federal-supplement",
  },
  {
    name: "Retirement Income Needed",
    id: "#retirement-income-needed",
  },
  {
    name: "FEGLI Client",
    id: "#fegli-client",
  },
  {
    name: "Unused Sick Leave at Retirement",
    id: "#unused-sick-leave",
  },
  {
    name: "Federal TSP",
    id: "#federal-tsp",
  },
];

const PersonalInformationAnchorMenu = [
  {
    name: "Personal Information",
    id: "#personal-information",
  },
  {
    name: "Family Information",
    id: "#family-information",
  },
  {
    name: "Retirement Information",
    id: "#retirement-information",
  },
  {
    name: "Savings Information",
    id: "#savings-information",
  },
  {
    name: "Additional Income",
    id: "#additional-income",
  },
  {
    name: "Deductions",
    id: "#deductions",
  },
];

interface AnchorMenuAccordionProps {
  activeTab: string;
}

const AnchorMenuAccordion: React.FC<AnchorMenuAccordionProps> = ({
  activeTab,
}) => {
  const handleScroll = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: string
  ) => {
    event.preventDefault();
    const element = document.querySelector(id);
    if (element) {
      const yOffset = -70; // Offset value of 20px
      const yPosition =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: yPosition, behavior: "smooth" });
    }
  };

  const renderMenuItems = (menuItems: { name: string; id: string }[]) => {
    return menuItems.map((item) => (
      <AccordionItem key={item.id} value={item.id}>
        <AccordionTrigger>
          <a
            href={item.id}
            className="text-accent text-sm"
            onClick={(event) => handleScroll(event, item.id)}
          >
            {item.name}
          </a>
        </AccordionTrigger>
      </AccordionItem>
    ));
  };

  return (
    <Accordion type="single" collapsible className="w-full">
      {activeTab === "financial-information" &&
        renderMenuItems(FinancialInformationAnchorMenu)}
      {activeTab === "personal-information" &&
        renderMenuItems(PersonalInformationAnchorMenu)}
    </Accordion>
  );
};

export default AnchorMenuAccordion;
