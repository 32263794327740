import { BellRing, Check } from "lucide-react";
import { Icons } from "../../utils/icons";
import { cn } from "../../lib/utils";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Switch } from "../../components/ui/switch";

// New Release (June 12, 2024) - Improved Client List
// Hotfix (June 16, 2024)
// New Release (July 1, 2024) - Client Sidebar

const notifications = [
  {
    title: "New Release - Improved Client List",
    description: "June 12, 2024",
  },
  {
    title: "Hotfix",
    description: "June 16, 2024",
  },
  {
    title: "Client Sidebar",
    description: "July 1, 2024",
  },
];

type RecentNewsProps = React.ComponentProps<typeof Card>;

export default function RecentNews({ className, ...props }: RecentNewsProps) {
  return (
    <Card className={cn("w-1/3", className)} {...props}>
      <CardHeader>
        <CardTitle className="text-accent flex items-center gap-2">
          <Icons.newspaper className="w-12 h-12 text-accent" />
          Recent Updates
        </CardTitle>
        <CardDescription>You have 3 new items.</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4">
        <div>
          {notifications.map((notification, index) => (
            <div
              key={index}
              className="mb-4 grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0"
            >
              <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />
              <div className="space-y-1">
                <p className="text-sm font-medium leading-none">
                  {notification.title}
                </p>
                <p className="text-sm text-muted-foreground">
                  {notification.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* <div className=" flex items-center space-x-4 rounded-md border p-4">
          <BellRing />
          <div className="flex-1 space-y-1">
            <p className="text-sm font-medium leading-none">
              Push Notifications
            </p>
            <p className="text-sm text-muted-foreground">
              Send notifications to device.
            </p>
          </div>
          <Switch />
        </div> */}
      </CardContent>
      <CardFooter>
        {/* <Button className="w-full">
          <Check className="mr-2 h-4 w-4" /> Mark all as read
        </Button> */}
      </CardFooter>
    </Card>
  );
}
