import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "../../ui/dialog";
import { Button } from "../../ui/button";

interface WarningModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const WarningModal = ({ isOpen, onClose }: WarningModalProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Screen Resolution Warning</DialogTitle>
          <DialogDescription>
            This website is not available for screen resolutions lower than
            767px. Please use a device with a larger screen.
          </DialogDescription>
        </DialogHeader>
        <Button onClick={onClose}>Close</Button>
      </DialogContent>
    </Dialog>
  );
};

export default WarningModal;
